<div class="list-view">
    @if(loading) {
        <loading-spinner />
    }
    <ng-container *ngIf="commitment$ | async as commitment">
        <ng-container>
            <div class="page-header" [ngClass]="complianceRequirements.length <= 0 ? 'flex-between' : 'flex-end'">
                <div *ngIf="complianceRequirements.length <= 0 && !loading">
                    <p>There are currently no compliance requirements assosiated with this commitment.</p>
                </div>
                <div class="actions-bar">
                    <button
                        mat-flat-button
                        *ngIf="canEdit() && complianceRequirements.length != 0 && !reorderMode && !editMode"
                        color="accent"
                        type="button"
                        (click)="reorderModeButtonClicked()">
                        <mat-icon>reorder</mat-icon> Reorder
                    </button>
                    <button
                        mat-flat-button
                        *ngIf="canCreate() && !reorderMode && !loading"
                        color="primary"
                        type="button"
                        (click)="create()"
                        style="margin-left: 4px">
                        <mat-icon>add</mat-icon> Compliance Requirement
                    </button>

                    <button
                        mat-flat-button
                        *ngIf="reorderMode"
                        color="accent"
                        type="button"
                        (click)="cancelReorderButtonClicked()"
                        style="margin-left: 4px">
                        <mat-icon>cancel</mat-icon> Cancel
                    </button>

                    <button
                        mat-flat-button
                        *ngIf="reorderMode"
                        type="button"
                        color="primary"
                        (click)="saveReorderButtonClicked()"
                        style="margin-left: 4px">
                        <mat-icon>save</mat-icon> Save
                    </button>
                </div>
            </div>
            <div class="g-col-12">
                <div cdkDropList class="draggable-list" (cdkDropListDropped)="dropComplianceRequirement($event)" *ngIf="reorderMode">
                    <div class="draggable-box" *ngFor="let complianceRequirement of complianceRequirements" cdkDrag cdkDragPreviewContainer="parent">
                        <div class="draggable-custom-placeholder" *cdkDragPlaceholder></div>
                        <h2 style="color: #04608d; padding-top: 1rem">
                            <mat-icon style="padding-top: 8px">reorder</mat-icon> {{ complianceRequirement.Name }}
                        </h2>
                    </div>
                </div>

                <mat-accordion [multi]="true" *ngIf="!reorderMode">
                    <mat-expansion-panel
                        *ngFor="let complianceRequirement of complianceRequirements"
                        [expanded]="complianceRequirement.ComplianceRequirementID == activeComplianceRequirementID"
                        [disabled]="editMode && complianceRequirement.ComplianceRequirementID !== selectedComplianceRequirementIDForEdit">
                        <mat-expansion-panel-header>
                            <h2 style="color: #04608d; padding-top: 1rem">{{ complianceRequirement.Name }}</h2>
                        </mat-expansion-panel-header>

                        <compliance-requirement-form
                            #compReqForm
                            [commitment]="commitment"
                            [isSelected]="selectedComplianceRequirementIDForEdit"
                            [complianceRequirement]="complianceRequirement"
                            [allComplianceRequirementTags]="allComplianceRequirementTags$ | async"
                            [hasEditBtn]="canEdit()"
                            [hasDeleteBtn]="canDelete()"
                            [currentUser]="currentUser"
                            (formUpdated)="update($event)"
                            (formDeleted)="deleteComplianceReq($event)"
                            (formToEdit)="editComplianceReq($event)"
                            (cancelEditModeChange)="cancelEdit()"></compliance-requirement-form>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </ng-container>
    </ng-container>
    <beacon-back-to-top></beacon-back-to-top>
</div>
