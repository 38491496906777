import { CommonModule } from '@angular/common';
import { Component, computed, EventEmitter, input, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'file-drop-upload',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatButton, FontAwesomeModule],
  templateUrl: './file-drop-upload.component.html',
  styleUrl: './file-drop-upload.component.scss',
  
})
export class FileDropUploadComponent {
  @Input() multiple: boolean = false;
  @ViewChild('fileUploadField') fileUploadField: any;
  @Output() filesChanged = new EventEmitter<File[]>();
  faCloudArrowUp = faCloudArrowUp;

  /**
   * comma separated list of file types: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept
   */
  uploadFileAccepts = input<string>();
  uploadFileAcceptsArray = computed(() => this.uploadFileAccepts()?.split(',').map((x) => x.trim()) ?? []);
  public hoveringWithFiles : boolean = false;
  public value: File[];

  constructor() { }

  updateFiles(event: any): void {
    this.value = Array.from(event);
    this.filesChanged.emit(this.value);
  }

  onClickFileUpload(event: any): void {
    const fileUploadInput = this.fileUploadField.nativeElement;
    fileUploadInput.click();
  }

  dragOverHandler(event: any) {
    event.preventDefault();
  }

  dropHandler(event: any) {
    event.preventDefault();
    this.hoveringWithFiles = false;
    let files = [];
    if (event.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      [...event.dataTransfer.items].forEach((item, i) => {
        // If dropped items aren't files, reject them
        if (item.kind === "file") {
          const file = item.getAsFile();
          files.push(file);
        }
      });
    } else {
      // Use DataTransfer interface to access the file(s)
      [...event.dataTransfer.files].forEach((file, i) => {
        files.push(file);
      });
    }

    this.updateFiles(files);
  }
  
  dragEnterHandler(event: DragEvent) {
    event.preventDefault();
    this.hoveringWithFiles = true;
  }

  dragLeaveHandler($event: DragEvent) {
    $event.preventDefault();
    this.hoveringWithFiles = false;
  }
}
