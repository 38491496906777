import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ProjectUpsertDto } from "src/app/shared/generated/model/project-upsert-dto";
import { ProjectFormComponent } from "../project-form/project-form.component";
import { Subscription } from "rxjs";
import { ProjectService } from "src/app/shared/generated/api/project.service";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { ProjectFileService } from "src/app/shared/generated/api/project-file.service";
import { ProjectFileDto } from "src/app/shared/generated/model/project-file-dto";
import { arrForEach } from "@microsoft/applicationinsights-core-js";

@Component({
    selector: "project-create",
    templateUrl: "./project-create.component.html",
    styleUrls: ["./project-create.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ProjectFormComponent],
})
export class ProjectCreateComponent implements OnInit {
    @ViewChild("createNewProjectForm", { read: ProjectFormComponent }) form: ProjectFormComponent;
    public model: ProjectUpsertDto = new ProjectUpsertDto();
    public originalModel: any;
    private projectResponse: any;

    projectSubscription: Subscription;
    projectFileSubscription: Subscription

    constructor(private projectService: ProjectService, private alertService: AlertService, private projectFileService: ProjectFileService, private router: Router, private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.model = {
            Name: null,
            StartDate: null,
            EndDate: null,
            Description: null,
            Location: null,
        };

        this.originalModel = JSON.stringify(this.model);
    }

    ngOnDestroy() {
        this.cdr.detach();
        this.projectSubscription?.unsubscribe();
    }

    save(createNewProjectForm: NgForm): void {
        this.projectSubscription = this.projectService.projectsPost(this.form.projectUpsertDto).subscribe(
            (response) => {
                this.projectResponse = response;
                if(this.form.files && this.form.files.length > 0) {
                    this.projectFileSubscription = this.projectFileService.projectsProjectIDFilesPost(this.projectResponse.ProjectID, this.form.files).subscribe({
                        next: (response) => {
                            this.router.navigateByUrl(`/projects/${this.projectResponse.ProjectID}/summary-information`).then(() => {
                                this.alertService.pushAlert(new Alert("The project was successfully created.", AlertContext.Success), 5000);
                            });
                        },
                        error: (error) => {
                        },
                        complete: () => {
                        }
                    });
                } else {
                    this.router.navigateByUrl(`/projects/${this.projectResponse.ProjectID}/summary-information`).then(() => {
                        this.alertService.pushAlert(new Alert("The project was successfully created.", AlertContext.Success), 5000);
                    });
                }
            },
            (error) => {
                this.cdr.detectChanges();
            },            
        );
    }

    cancelEdit() {
        this.router.navigateByUrl("/projects");
    }

    canExit() {
        if (this.projectResponse) {
            return true;
        } else {
            return this.originalModel === JSON.stringify(this.form.projectUpsertDto);
        }
    }
}
