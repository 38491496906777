import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CustomPageService } from "src/app/shared/generated/api/custom-page.service";
import { CustomPageDto } from "src/app/shared/generated/model/custom-page-dto";
import { CustomPageUpsertDto } from "src/app/shared/generated/model/custom-page-upsert-dto";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { Subscription, forkJoin } from "rxjs";
import { RefreshCustomPagesService } from "src/app/shared/services/refresh-custom-pages/refresh-custom-pages.service";
import { CustomPageFormComponent } from "../custom-page-form/custom-page-form.component";
import { NgIf } from "@angular/common";

@Component({
    selector: "beacon-custom-page-edit-properties",
    templateUrl: "./custom-page-edit-properties.component.html",
    styleUrls: ["./custom-page-edit-properties.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, CustomPageFormComponent],
})
export class CustomPageEditPropertiesComponent implements OnInit, OnDestroy {
    @ViewChild("customPageForm", { read: CustomPageFormComponent }) form: CustomPageFormComponent;

    public isCreating: boolean;
    public customPage: CustomPageDto;
    public pageRolesIDs: any;
    public customPageUpsertDto: CustomPageUpsertDto;

    customPageResponse: Subscription;
    creatingSubscription: Subscription;
    getDataSubscription: Subscription;

    constructor(
        private cdr: ChangeDetectorRef,
        private customPageService: CustomPageService,
        private refreshCustomPagesService: RefreshCustomPagesService,
        private route: ActivatedRoute,
        private router: Router,
        private alertService: AlertService
    ) {}

    ngOnInit(): void {
        this.creatingSubscription = this.route.data.subscribe((result) => {
            this.isCreating = result.create;
            if (this.isCreating) {
                this.setForm();
            } else {
                this.getData();
            }
        });
    }

    ngOnDestroy(): void {
        this.cdr.detach();
        this.creatingSubscription?.unsubscribe();
        this.getDataSubscription?.unsubscribe();
        this.customPageResponse?.unsubscribe();
    }

    setForm() {
        this.customPageUpsertDto = new CustomPageUpsertDto({
            MenuItemID: this.customPage?.MenuItem?.MenuItemID || [],
            CustomPageDisplayName: this.customPage?.CustomPageDisplayName,
            CustomPageVanityUrl: this.customPage?.CustomPageVanityUrl,
            ViewableRoleIDs: this.pageRolesIDs || [],
            CustomPageContent: this.customPage?.CustomPageContent,
        });
        this.cdr.markForCheck();
    }

    getData() {
        const vanityUrl = this.route.snapshot.paramMap.get("vanity-url");

        this.getDataSubscription = forkJoin({
            customPage: this.customPageService.customPagesGetByURLCustomPageVanityURLGet(vanityUrl),
            pageRoles: this.customPageService.customPagesGetByURLCustomPageVanityURLRolesGet(vanityUrl),
        }).subscribe(({ customPage, pageRoles }) => {
            this.customPage = customPage;
            this.pageRolesIDs = pageRoles.map((pageRole) => pageRole.RoleID).sort();
            this.setForm();
        });
    }

    save(): void {
        if (this.isCreating) {
            this.customPageResponse = this.customPageService.customPagesPost(this.form.customPageUpsertDto).subscribe({
                next: (result) => {
                    this.alertService.pushAlert(
                        new Alert(`The custom page was successfully ${this.isCreating ? "created" : "updated"}.`, AlertContext.Success)
                    );
                },
                error: (error) => {
                    this.cdr.detectChanges();
                },
                complete: () => this.onSubmitSuccess(),
            });
        } else {
            this.customPageResponse = this.customPageService.customPagesCustomPageIDPut(this.customPage.CustomPageID, this.form.customPageUpsertDto).subscribe({
                next: (result) => {
                    this.alertService.pushAlert(
                        new Alert(`The custom page was successfully ${this.isCreating ? "created" : "updated"}.`, AlertContext.Success)
                    );
                },
                error: (error) => {
                    this.cdr.detectChanges();
                },
                complete: () => this.onSubmitSuccess(),
            });
        }
    }

    private onSubmitSuccess() {
        this.refreshCustomPagesService.triggerRefresh();
        this.router.navigateByUrl(`/custom-pages/${this.form.customPageUpsertDto.CustomPageVanityUrl}`);
    }

    cancelEdit() {
        this.router.navigateByUrl("/manage/custom-pages");
    }

    canExit() {
        if (this.customPageResponse) {
            return true;
        } else {
            return JSON.stringify(this.customPageUpsertDto) === JSON.stringify(this.form.customPageUpsertDto);
        }
    }
}
