import { Component, Input } from '@angular/core';

@Component({
  selector: 'pill',
  standalone: true,
  imports: [],
  templateUrl: './pill.component.html',
  styleUrl: './pill.component.scss'
})
export class PillComponent {
  @Input() backgroundColor: string = '#43608A';
  @Input() color: string = '#fff';

}
