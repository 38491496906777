import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { AgGridAngular } from "ag-grid-angular";
import { ColDef } from "ag-grid-community";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CurrentProjectService } from "src/app/services/current-project/current-project.service";
import { ProjectSourceService } from "src/app/shared/generated/api/project-source.service";
import { ProjectDto } from "src/app/shared/generated/model/project-dto";
import { SourceDto } from "src/app/shared/generated/model/source-dto";
import { DateColumnCreatorService } from "src/app/shared/services/date-column-creator/date-column-creator.service";
import { GridCommonService } from "src/app/shared/services/grid-common.service";
import { ClearGridFiltersButtonComponent } from "../../shared/components/clear-grid-filters-button/clear-grid-filters-button.component";
import { NgIf, AsyncPipe } from "@angular/common";
import { GridActionsComponent } from "src/app/shared/components/ag-grid/grid-actions/grid-actions.component";

@Component({
    selector: "project-related-sources",
    templateUrl: "./project-related-sources.component.html",
    styleUrls: ["./project-related-sources.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        ClearGridFiltersButtonComponent,
        AgGridAngular,
        AsyncPipe,
        GridActionsComponent
    ],
})
export class ProjectRelatedSourcesComponent implements OnInit {
    @ViewChild("sourcesGrid") sourcesGrid: AgGridAngular;

    public project$: Observable<ProjectDto>;
    public project;
    public projectSourceDocuments: Observable<SourceDto[]>;

    public sources: SourceDto[];

    public rowData = [];
    public columnDefs: ColDef[];
    

    public getSourceRequest: any;

    constructor(
        private currentProjectService: CurrentProjectService,
        private projectSourceService: ProjectSourceService,
        private dateColumnCreator: DateColumnCreatorService,
        private cdr: ChangeDetectorRef,
        private gridCommonService: GridCommonService
    ) {
        this.columnDefs = [
            {
                headerName: "Name",
                headerTooltip: "Source Document Name",
                valueGetter: function (params: any) {
                    return {
                        LinkValue: params.data.SourceID,
                        LinkDisplay: params.data.Name,
                        ChildRoute: "summary-information",
                    };
                },
                cellRendererSelector: () => this.gridCommonService.renderLink("/source-documents/"),
                filterValueGetter: function (params: any) {
                    return params.node.rowPinned ? null : params.data.Name;
                },
                comparator: function (linkA, linkB, nodeA, nodeB, isDescending) {
                    let valueA = linkA.LinkDisplay.toLowerCase();
                    let valueB = linkB.LinkDisplay.toLowerCase();

                    return valueA.localeCompare(valueB, undefined, {
                        numeric: true,
                        sensitivity: "base",
                    });
                },
                width: 400,
                sort: "asc",
            },
            this.dateColumnCreator.createDateColumnDef("Date of Lastest Amendment", "DateOfLatestAmendment", "M/dd/YYYY"),
            {
                headerName: "Reference Number",
                field: "ReferenceNumber",
                flex: 1,
                type: "rightAligned",
            },
            {
                headerName: "Agency",
                field: "ApprovingAgency",
                flex: 1,
                type: "rightAligned",
            },
        ];

    }

    ngOnInit(): void {
        this.project$ = this.currentProjectService.getCurrentProject().pipe(
            map((p: ProjectDto) => {
                this.project = p;
                return p;
            })
        );
    }

    onSourcesGridReady(gridEvent: any) {
        this.refreshData();
    }

    refreshData() {
        this.sourcesGrid.api.showLoadingOverlay();

        this.getSourceRequest = this.projectSourceService.projectsProjectIDSourcesGet(this.project.ProjectID).subscribe((results) => {
            this.sources = results;
            this.rowData = this.sources;

            this.sourcesGrid.api.hideOverlay();
            setTimeout(() => {
                this.setDefaultFilter();
            });

            this.cdr.markForCheck();
        });
    }

    setDefaultFilter() {
        this.sourcesGrid.api.onFilterChanged();
        this.cdr.markForCheck();
    }
}
