<div class="checklist-task-dialog">
<h1 mat-dialog-title>{{ data.checklistTask.ChecklistTaskID ? 'Edit Task' : 'Add Task' }}</h1>
<div mat-dialog-content>
  <form class="form" [formGroup]="checklistTaskFormGroup">

    <esa-material-ff name="TaskName" [editMode]="true" type="text" formControlName="TaskName" [required]="checklistTaskFormGroup.controls.TaskName.hasValidator(validators.required)" [customLabel]="nameCustomLabel">
      <ng-template #nameCustomLabel>
          <custom-form-label label="Task Name" ></custom-form-label>
      </ng-template>
    </esa-material-ff>

    <esa-material-ff name="Description" [editMode]="true" type="text" formControlName="Description" [required]="checklistTaskFormGroup.controls.Description.hasValidator(validators.required)" [customLabel]="DescriptionCustomLabel">
      <ng-template #DescriptionCustomLabel>
          <custom-form-label label="Description" ></custom-form-label>
      </ng-template>
    </esa-material-ff>

    <esa-material-ff name="AssignedTo" [editMode]="true" type="text" formControlName="AssignedTo" [required]="checklistTaskFormGroup.controls.AssignedTo.hasValidator(validators.required)" [customLabel]="AssignedToCustomLabel">
      <ng-template #AssignedToCustomLabel>
          <custom-form-label label="Assigned To" ></custom-form-label>
      </ng-template>
    </esa-material-ff>
    @if(currentUser$ | async){
      <esa-material-ff 
        name="OwnerUserID" 
        [editMode]="true" 
        type="dropdown" 
        formControlName="OwnerUserID" 
        [options]="users$ | async"
        [optionValue]="'UserID'"
        [optionLabel]="'FullName'"
        [required]="checklistTaskFormGroup.controls.OwnerUserID.hasValidator(validators.required)"
        [customLabel]="OwnerUserIDCustomLabel">
      <ng-template #OwnerUserIDCustomLabel>
          <custom-form-label label="Owner" ></custom-form-label>
      </ng-template>
    </esa-material-ff>
    }
    

    <div class="kv-pair">
      <esa-label label="Due Date"></esa-label>
      <mat-form-field class="value">
        <!-- <mat-label>Due Date</mat-label> -->
        <input matInput [matDatepicker]="picker" formControlName="DueDate" name="dueDate">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    
  </form>
</div>
<div mat-dialog-actions align="end">
  <span [matTooltip]="!checklistTaskFormGroup.valid ? 'You are missing required fields.' : ''">
    <button mat-flat-button 
      color="primary" 
      style="margin-right:1rem;" 
      [mat-dialog-close]="checklistTaskFormGroup.value"
      cdkFocusInitial 
      [disabled]="!checklistTaskFormGroup.valid">Save</button>
  </span>
  <button mat-button mat-dialog-close>Cancel</button>
</div>

<!-- <form class="form grid-12" [formGroup]="checklistFormGroup">
  <div class="g-col-12">
      <esa-material-ff [disabled]="isLoading" name="Name" [editMode]="editMode" type="text" formControlName="Name" [customLabel]="nameCustomLabel">
          <ng-template #nameCustomLabel>
              <custom-form-label label="Name" fieldDefinitionType="Name"></custom-form-label>
          </ng-template>
      </esa-material-ff>
      <esa-material-ff [disabled]="isLoading" name="Description" [editMode]="editMode" type="text" formControlName="Description" [customLabel]="descriptionCustomLabel">
          <ng-template #descriptionCustomLabel>
              <custom-form-label label="Description" fieldDefinitionType="Description"></custom-form-label>
          </ng-template>
      </esa-material-ff>
  </div>
</form> -->
