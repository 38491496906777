//  IMPORTANT:
//  This file is generated. Your changes will be lost.
//  Source Table: [dbo].[Permission]

export enum PermissionEnum {
  CustomPageRights = 1,
  CustomRichTextRights = 2,
  FieldDefinitionRights = 3,
  UserRights = 5,
  CommitmentRights = 6,
  RoleRights = 7,
  SourceDocumentRights = 8,
  ProjectRights = 9,
  ChecklistRights = 10,
}
