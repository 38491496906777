import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { TenantService } from "../../services/tenant/tenant-service.service";

@Injectable({
    providedIn: "root",
})
export class MonitoringDashboardModuleGuard  {
    constructor(private tenantService: TenantService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let tenant = this.tenantService.getCurrentTenant();
        if (tenant) {
            return this.checkTenant(tenant);
        } else {
            this.tenantService.currentTenant$.subscribe((tenant) => {
                if (tenant) {
                    return this.checkTenant(tenant);
                }
            });
        }
    }

    checkTenant(tenant: any) {
        let tenantHasModule = tenant && tenant.TenantModules.map((m) => m.Module.ModuleID).includes(this.tenantService.monitoringDashboardModuleID);
        if (tenantHasModule) {
            return true;
        }

        this.router.navigate(["/"]);
        return false;
    }
}
