import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class RefreshCustomPagesService {
    private refreshCustomPagesEvent = new BehaviorSubject<boolean>(null);
    public refreshCustomPagesEvent$ = this.refreshCustomPagesEvent.asObservable();

    constructor() {}

    triggerRefresh() {
        this.refreshCustomPagesEvent.next(true);
    }

    resetRefresh() {
        this.refreshCustomPagesEvent.next(false);
    }
}
