import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Map, MapOptions, tileLayer, latLng, ZoomAnimEvent, geoJSON, marker, icon, Icon, LeafletEvent, LatLngBounds, FeatureGroup } from "leaflet";
import * as geojson from "geojson";
import * as L from "leaflet";
import "leaflet-draw";
import * as turf from "@turf/turf";
import { LeafletModule } from "@asymmetrik/ngx-leaflet";

@Component({
    // standalone: true,
    // imports: [LeafletModule],
    selector: "map",
    templateUrl: "./map.component.html",
    styleUrls: ["./map.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [LeafletModule],
})
export class MapComponent implements OnInit, OnChanges {
    @Output() map$: EventEmitter<Map> = new EventEmitter();
    @Output() zoom$: EventEmitter<number> = new EventEmitter();

    @Output() onFeatureSelected: EventEmitter<geojson.Feature> = new EventEmitter();
    @Output() onFeaturesFiltered: EventEmitter<any> = new EventEmitter();

    @Input() allObjectsWithFeature: any[];
    @Input() filteredObjectsWithFeature: any[];
    @Input() uniqueKey: string;
    @Input() featureKey: string;
    @Input() selectedObject: geojson.Feature;

    @Input() filterToExtent: boolean = false;

    @Input() displayFilterButtons: boolean = true;

    /*
        MK 4/17/2024 -- Debugging the map got tricky when there were multiple maps on the page, I added this to help debug the specific map you are working with.
                       Add a conditional breakpoint in the debugger to stop when this value is true.
    */
    @Input() debugBoolean: boolean = false;

    @Input() options: MapOptions = {
        layers: [
            tileLayer("http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}", {
                minZoom: 0,
                maxZoom: 64,
                subdomains: ["mt0", "mt1", "mt2", "mt3"],
            }),
        ],
        zoom: 16,
        maxZoom: 22,
        center: latLng(38.03131, -121.640787),
        scrollWheelZoom: false,
    };

    public features: geojson.Feature[];
    public map: Map;
    public zoom: number;

    private featureLayer: L.GeoJSON<any>;
    private currentSelectedLayer: L.Marker | null = null;

    private isUserEvent: boolean = false;

    private drawnItems: FeatureGroup = new FeatureGroup();
    private drawControl: L.Control.Draw;

    constructor(private cdr: ChangeDetectorRef) {
        L.drawLocal.draw.toolbar.buttons.polygon = "Filter to polygon.";
        L.drawLocal.draw.toolbar.buttons.rectangle = "Filter to rectangle.";
    }

    ngOnInit() {
        this.featureLayer = geoJSON(null, {
            pointToLayer: (feature, latlng) => {
                return marker(latlng, {
                    icon: icon({
                        ...Icon.Default.prototype.options,
                        iconUrl: "assets/marker-icon.png",
                        iconRetinaUrl: "assets/marker-icon-2x.png",
                        shadowUrl: "assets/marker-shadow.png",
                        className: "not-selected-feature",
                    }),
                });
            },
            onEachFeature: (feature, layer) => {
                layer.on({
                    click: (e) => {
                        this.selectedFeatureChanged(feature, false);
                        this.onFeatureSelected.emit(feature);
                    },
                });

                this.bindPopup(layer);
            },
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.allObjectsWithFeature && changes.allObjectsWithFeature.currentValue) {
            this.features = changes.allObjectsWithFeature.currentValue
                .filter((o) => {
                    return o[this.featureKey] !== null;
                })
                .map((o) => {
                    let feature = o[this.featureKey];
                    if (!feature.properties) {
                        feature.properties = {};
                    }

                    feature.properties[this.uniqueKey] = o[this.uniqueKey];
                    feature.properties["hidden"] = false;
                    return feature;
                });
        }

        if (changes.filteredObjectsWithFeature && changes.filteredObjectsWithFeature.currentValue && this.features) {
            this.features.forEach((feature) => {
                if (changes.filteredObjectsWithFeature.currentValue.find((o) => o[this.uniqueKey] === feature.properties[this.uniqueKey])) {
                    feature.properties["hidden"] = false;
                } else {
                    feature.properties["hidden"] = true;
                }
            });
        }

        if (changes.selectedObject && this.map) {
            this.selectedObjectedChanged(changes.selectedObject.currentValue);
        }

        if (changes.filterToExtent && changes.filterToExtent.currentValue) {
            this.filterToExtent = changes.filterToExtent.currentValue;
            if (this.filterToExtent && this.map) {
                this.filterToBounds(this.map.getBounds());
                this.removeDrawControls();
            } else if (this.map) {
                this.addDrawControls();
            }
        }

        this.checkIfFeaturesNeedRendering();
    }

    checkIfFeaturesNeedRendering() {
        if (this.map && this.features && this.features.length > 0) {
            this.renderFeatures(this.features);
        } else if (this.features && this.features.length > 0) {
            this.map$.subscribe((m) => {
                this.renderFeatures(this.features);
            });
        }
    }

    onMapReady(map: Map) {
        map.on(L.Draw.Event.CREATED, (event: L.DrawEvents.Created) => {
            const layer = event.layer;
            this.drawnItems.addLayer(layer);
            if (layer instanceof L.Rectangle) {
                const bounds = layer.getBounds();
                this.filterToBounds(bounds);
            } else if (layer instanceof L.Polygon) {
                let polygonGeoJSON = layer.toGeoJSON();
                let selectedFeatures = [];
                this.features.forEach((feature: any) => {
                    if (feature.type === "Point") {
                        const latlng = latLng(feature.coordinates[1], feature.coordinates[0]);
                        if (this.isLatLngInsidePolygon(latlng, polygonGeoJSON)) {
                            selectedFeatures.push(feature);
                        }
                    }
                });

                this.onFeaturesFiltered.emit(selectedFeatures);
            }
        });

        this.map = map;

        if (this.displayFilterButtons && !this.filterToExtent) {
            this.addDrawControls();
        }

        this.map$.emit(map);
        this.zoom = map.getZoom();
        this.zoom$.emit(this.zoom);

        this.cdr.markForCheck();
    }

    addDrawControls() {
        this.drawControl = new L.Control.Draw({
            edit: {
                featureGroup: this.drawnItems,
                edit: false,
                remove: false,
            },
            draw: {
                polyline: false,
                rectangle: <any>{ showArea: false },
                circle: false,
                circlemarker: false,
                marker: false,
                polygon: {
                    allowIntersection: false,
                    showArea: false,
                },
            },
        });

        this.map.addControl(this.drawControl);
    }

    removeDrawControls() {
        this.map.removeControl(this.drawControl);
    }

    onMapZoomEnd(e: LeafletEvent) {
        let newZoom = e.target.getZoom();
        if (newZoom != this.zoom) {
            this.zoom = e.target.getZoom();
            this.zoom$.emit(this.zoom);
        }
    }

    onMapMoveEnd(e: LeafletEvent) {
        if (this.isUserEvent && this.filterToExtent) {
            let bounds = this.map.getBounds();
            this.filterToBounds(bounds);
        }

        this.isUserEvent = true;
    }

    filterToBounds(bounds: LatLngBounds) {
        let allFeatures = this.features;
        const featuresInView = [];

        allFeatures.forEach((feature: any) => {
            if (feature.type === "Point") {
                const latlng = latLng(feature.coordinates[1], feature.coordinates[0]);
                if (bounds.contains(latlng)) {
                    featuresInView.push(feature);
                }
            }
        });

        this.onFeaturesFiltered.emit(featuresInView);
    }

    isLatLngInsidePolygon(latlng: L.LatLng, polygonGeoJSON: turf.Feature<turf.Polygon | turf.MultiPolygon>): boolean {
        const point = turf.point([latlng.lng, latlng.lat]);
        return turf.booleanPointInPolygon(point, polygonGeoJSON);
    }

    renderFeatures(features: any[]) {
        this.featureLayer.clearLayers();

        if (features.length) {
            features
                .filter((f) => {
                    return !f.properties.hidden;
                })
                .forEach((feature) => {
                    this.featureLayer.addData(feature);
                });

            this.featureLayer.addTo(this.map);

            if (this.selectedObject) {
                this.selectedObjectedChanged(this.selectedObject);
            }

            if (!this.filterToExtent) {
                this.isUserEvent = false;
                let bounds = this.featureLayer.getBounds();
                if (bounds.isValid()) {
                    if (bounds.getNorthEast().lat == bounds.getSouthWest().lat && bounds.getNorthEast().lng == bounds.getNorthWest().lng) {
                        let buffer = 0.1;
                        bounds = L.latLngBounds(
                            [bounds.getNorthEast().lat + buffer, bounds.getNorthEast().lng + buffer],
                            [bounds.getSouthWest().lat - buffer, bounds.getSouthWest().lng - buffer]
                        );
                    } else {
                        bounds = bounds.pad(0.5);
                    }

                    this.map.fitBounds(bounds, {
                        padding: [32, 32],
                        maxZoom: 64,
                        animate: false,
                    });
                }
            }
        }

        this.cdr.markForCheck();
    }

    selectedObjectedChanged(selectedObject: any) {
        let selectedFeature = selectedObject != null ? selectedObject[this.featureKey] : null;
        this.selectedFeatureChanged(selectedFeature);
    }

    selectedFeatureChanged(selectedFeature, openPopup = true) {
        if (selectedFeature == null) {
            this.featureLayer.eachLayer((layer: any) => {
                layer.setIcon(
                    icon({
                        ...Icon.Default.prototype.options,
                        iconUrl: "assets/marker-icon.png",
                        iconRetinaUrl: "assets/marker-icon-2x.png",
                        shadowUrl: "assets/marker-shadow.png",
                        className: "not-selected-feature",
                    })
                );
                layer.setZIndexOffset(0);
            });
        }

        this.featureLayer.eachLayer((layer: any) => {
            if (layer.feature.geometry.properties[this.uniqueKey] === selectedFeature?.properties[this.uniqueKey]) {
                // Reset the icon of the previously selected marker
                if (this.currentSelectedLayer) {
                    this.currentSelectedLayer.setIcon(
                        icon({
                            ...Icon.Default.prototype.options,
                            iconUrl: "assets/marker-icon.png",
                            iconRetinaUrl: "assets/marker-icon-2x.png",
                            shadowUrl: "assets/marker-shadow.png",
                            className: "not-selected-feature",
                        })
                    );

                    this.currentSelectedLayer.setZIndexOffset(0);
                }

                // Change the icon of the selected marker
                const marker = layer as L.Marker;
                marker.setIcon(
                    icon({
                        ...Icon.Default.prototype.options,
                        iconUrl: "assets/marker-icon.png",
                        iconRetinaUrl: "assets/marker-icon-2x.png",
                        shadowUrl: "assets/marker-shadow.png",
                        className: "selected-feature",
                    })
                );

                marker.setZIndexOffset(1000);
                this.currentSelectedLayer = marker;

                if (openPopup) {
                    marker.openPopup();
                }

                if (!this.filterToExtent) {
                    this.map.flyTo(layer.getLatLng(), this.zoom);
                }
            }
        });

        this.map.invalidateSize();
        this.cdr.detectChanges();
    }

    bindPopup(layer) {
        let feature = layer.feature;
        if (feature && feature.geometry && feature.geometry.properties && feature.geometry.properties.tooltip) {
            layer.bindPopup(feature.geometry.properties.tooltip, {});
        }
    }
}
