import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { MatButton } from "@angular/material/button";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from "@angular/material/dialog";
import { MatIcon } from "@angular/material/icon";
import { BypassHtmlPipe } from "../../pipes/bypassHtml.pipe";

@Component({
    selector: "select-from-full-text-dialog",
    templateUrl: "./select-from-full-text-dialog.component.html",
    styleUrls: ["./select-from-full-text-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatDialogTitle,
        MatDialogContent,
        MatDialogActions,
        MatDialogClose,
        MatIcon,
        MatButton,
        BypassHtmlPipe
    ],
})
export class SelectFromFullTextDialogComponent implements OnInit {
    selection: string;
    clipboardItem: ClipboardItem;

    constructor(public dialogRef: MatDialogRef<SelectFromFullTextDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private cdr: ChangeDetectorRef) {
    }

    ngOnInit(): void {}

    bindSelection(event) {
        document.execCommand("copy");

        navigator.clipboard.read().then((result) => {
            this.clipboardItem = result[0];
            this.clipboardItem.getType("text/html").then((blob) => {
                blob.text().then((text) => {
                    this.selection = text;
                    this.cdr.markForCheck();
                });
            });
        });
    }

}
