<div class="detail-view" *ngIf="role$ | async as role">
    <div class="page-header">
        <h1 class="page-title">Role Details - {{ role.RoleName }}</h1>
        <div class="actions-bar">
            <button mat-icon-button style="margin-left: 1rem" *ngIf="canEdit()" color="primary" (click)="enableEditMode()">
                <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button style="margin-left: 1rem" *ngIf="canDelete()" color="primary" (click)="deleteRole()">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </div>

    <role-form #updateRoleForm [role]="roleUpsertDto" [editMode]="editMode" (formSubmitted)="save()" (cancelEditModeChange)="cancelEdit()"></role-form>

    <beacon-back-to-top></beacon-back-to-top>
</div>
