<div class="site">
    <div class="header-wrapper">
        <ng-container *ngIf="commitment">
            <div class="commitment-nav">
                <div *ngIf="!hasReadOnly()" matTooltip="Save or cancel edits to access options." [matTooltipDisabled]="!editMode">
                    <button mat-raised-button #profileMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="profile" color="primary" [disabled]="editMode">
                        <mat-icon>expand_more</mat-icon>&nbsp;Options
                    </button>

                    <mat-menu #profile="matMenu" [overlapTrigger]="false">

                        <button mat-menu-item (click)="revertToDraft()" *ngIf="canRevertToDraft()">
                            <mat-icon>undo</mat-icon>
                            <span>Revert to Draft</span>
                        </button>

                        <button mat-menu-item (click)="approve()" *ngIf="canApprove()">
                            <mat-icon>task_alt</mat-icon>
                            <span>Approve</span>
                        </button>

                        <button mat-menu-item (click)="version()" *ngIf="canVersion()">
                            <mat-icon>difference</mat-icon>
                            <span>Version</span>
                        </button>

                        <button mat-menu-item (click)="delete()" *ngIf="canDelete()">
                            <mat-icon>delete</mat-icon>
                            <span>Delete</span>
                        </button>
                    </mat-menu>
                </div>
            </div>

            <div class="commitment-title">
                <h3 class="commitment-title-item">{{ commitment.CommitmentType.Name | uppercase }}</h3>
                <h1 class="commitment-title-item">{{ commitment.ClientCommitmentID }} v{{ commitment.VersionNumber }}</h1>
                <h4 class="commitment-title-item">{{ commitment.Title }}</h4>
                <p class="commitment-title-item">STATUS: {{ commitment.CommitmentVersionStatus.CommitmentVersionStatusDisplayName | uppercase }}</p>
            </div>
        </ng-container>
    </div>

    <sidebar-nav [sideNavItems]="commitmentSideNav"></sidebar-nav>
</div>
