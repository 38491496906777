import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";
import { CurrentProjectService } from "src/app/services/current-project/current-project.service";
import { EditViewEventService } from "src/app/services/edit-view-event.service";
import { ProjectService } from "src/app/shared/generated/api/project.service";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { ProjectDto } from "src/app/shared/generated/model/project-dto";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { SidebarNavComponent } from "../../shared/components/sidebar-nav/sidebar-nav.component";
import { MatIcon } from "@angular/material/icon";
import { MatMenuTrigger, MatMenu, MatMenuItem } from "@angular/material/menu";
import { MatButton } from "@angular/material/button";
import { MatTooltip } from "@angular/material/tooltip";
import { NgIf } from "@angular/common";
import { ConfirmService } from "src/app/services/confirm.service";

@Component({
    selector: "project-details",
    templateUrl: "./project-details.component.html",
    styleUrls: ["./project-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgIf,
        MatTooltip,
        MatButton,
        MatMenuTrigger,
        MatIcon,
        MatMenu,
        MatMenuItem,
        SidebarNavComponent,
    ],
})
export class ProjectDetailsComponent implements OnInit, OnDestroy {
    public project$: Observable<ProjectDto>;
    public project?: ProjectDto | undefined;
    public currentUser: UserDto;
    public editMode: boolean;
    projectSideNav: any[];

    editViewButtonClicked: Subscription;
    user: Subscription;
    deleteSubscription: Subscription;

    constructor(
        private projectService: ProjectService,
        private currentProjectService: CurrentProjectService,
        private authenticationService: AuthenticationService,
        private editViewEventService: EditViewEventService,
        private alertService: AlertService,
        private router: Router,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        private confirmService: ConfirmService
    ) {
        this.projectSideNav = [
            {
                Name: "Summary Information",
                Link: "summary-information",
            },
            {
                Name: "Related Source Documents",
                Link: "related-source-documents",
            },
            {
                Name: "Related Commitments",
                Link: "related-commitments",
            },
        ];
    }

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            let projectID = params.id;
            this.project = null;
            this.currentProjectService.setCurrentProject(null);

            this.projectService.projectsProjectIDGet(projectID).subscribe((result) => {
                this.project = result;
                this.currentProjectService.setCurrentProject(this.project);
                this.cdr.markForCheck();
            });
        });

        this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.cdr.markForCheck();
        });

        this.editViewButtonClicked = this.editViewEventService.listenForEditButtonClicked().subscribe((result) => {
            this.editMode = result;
            this.cdr.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.user?.unsubscribe();
        this.editViewButtonClicked?.unsubscribe();
        this.deleteSubscription?.unsubscribe();
    }

    canDelete() {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.ProjectRights, RightsEnum.Delete);
    }

    delete() {
        this.confirmService.confirm({color:'warn', header: `Delete Project`, text: `Are you sure you want to delete this Project? This action cannot be undone.` }).subscribe((result) => {
            if(!result) return;
            this.deleteSubscription = this.projectService.projectsProjectIDDelete(this.project.ProjectID).subscribe((result) => {
                this.alertService.pushAlert(new Alert("The Project was successfully deleted.", AlertContext.Success), 5000);
                this.router.navigate(["/projects"]);
            });
        });
    }
}
