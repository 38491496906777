<main class="main">
    <div class="page-header">
        <div class="actions-bar flex-between">
            <h1 class="page-title">Components</h1>
        </div>
        <div class="g-col-6">
            <p>View all components for {{ currentTenant?.Name }}.</p>
        </div>
    </div>

    <div class="table-responsive">
        <beacon-grid-actions [grid]="componentsGrid" downloadFileName="components">
            <button
                mat-flat-button
                *ngIf="canCreateComponent"
                color="primary"
                (click)="navigateToCreateComponentPage()">
                <mat-icon>add</mat-icon> Component
            </button>
        </beacon-grid-actions>
        <ag-grid-angular
            #componentsGrid
            style="width: 100%; height: 100%"
            class="ag-theme-material"
            [rowData]="rowData"
            [columnDefs]="columnDefs"
            
            [suppressMenuHide]="true"
            (gridReady)="onComponentsGridReady($event)">
        </ag-grid-angular>
    </div>
</main>

