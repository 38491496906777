<div class="detail-view">
    <div class="page-header">
        <div class="actions-bar flex-between">
            <h1 class="page-title">Roles</h1>
        </div>
        <div class="g-col-6">
            <p>A list of roles is shown in the grid below.</p>
        </div>
    </div>

    <div class="all-users">
        <div class="table-responsive" style="height: 800px">
            <beacon-grid-actions [grid]="roleGrid" downloadFileName="roles">
                <button mat-flat-button *ngIf="canCreateRole" color="primary" (click)="addRole()"><mat-icon>add</mat-icon> Role </button>
            </beacon-grid-actions>
            <ag-grid-angular
                #roleGrid
                style="width: 100%; height: 100%"
                class="ag-theme-material"
                [rowData]="rowData"
                [columnDefs]="columnDefs"
                
                [suppressMenuHide]="true"
                (gridReady)="onRoleGridReady($event)">
            </ag-grid-angular>
        </div>
    </div>
</div>
