import { Component, OnInit } from "@angular/core";

@Component({
    selector: "subscription-insufficient",
    templateUrl: "./subscription-insufficient.component.html",
    styleUrls: ["./subscription-insufficient.component.scss"],
    standalone: true,
})
export class SubscriptionInsufficientComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
