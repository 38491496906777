import { Component, OnInit } from "@angular/core";
import { CustomRichTextTypeEnum } from "src/app/shared/generated/enum/custom-rich-text-type-enum";
import { CustomRichTextComponent } from "../../shared/components/custom-rich-text/custom-rich-text.component";

@Component({
    selector: "beacon-help",
    templateUrl: "./help.component.html",
    styleUrls: ["./help.component.scss"],
    standalone: true,
    imports: [CustomRichTextComponent],
})
export class HelpComponent implements OnInit {
    public richTextTypeID: number = CustomRichTextTypeEnum.Help;

    constructor() {}

    ngOnInit() {}
}
