<div class="site">
    <div class="header-wrapper">
        <ng-container *ngIf="project">
            <div class="project-nav">
                <div matTooltip="Save or cancel edits to access options." [matTooltipDisabled]="!editMode">
                    <button mat-raised-button #profileMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" color="primary" [disabled]="editMode">
                        <mat-icon>expand_more</mat-icon>&nbsp;Options
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="delete()" *ngIf="canDelete()">
                            <mat-icon>delete</mat-icon>
                            <span>Delete</span>
                        </button>
                    </mat-menu>
                </div>
            </div>

            <div class="project-title">
                <h3 class="project-title-item">PROJECT</h3>
                <h1 class="project-title-item">{{ project?.Name }}</h1>
            </div>
        </ng-container>
    </div>

    <sidebar-nav [sideNavItems]="projectSideNav"></sidebar-nav>
</div>
