import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AuthenticationService } from "src/app/services/authentication.service";
import { CurrentSourceDocumentService } from "src/app/services/current-source-document/current-source-document.service";
import { EditViewEventService } from "src/app/services/edit-view-event.service";
import { SourceService } from "src/app/shared/generated/api/source.service";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { SourceDto } from "src/app/shared/generated/model/source-dto";
import { SourceUpsertDto } from "src/app/shared/generated/model/source-upsert-dto";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { SourceDocumentFormComponent } from "../source-document-form/source-document-form.component";
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { BackToTopComponent } from "../../shared/components/back-to-top/back-to-top.component";
import { NgIf, AsyncPipe } from "@angular/common";
import { EsaMaterialButtonComponent } from "esa-material-form-field";
import { MatButton, MatIconButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { SourceDocumentFileListComponent } from "../source-document-file-list/source-document-file-list.component";
import { SourceFileService } from "src/app/shared/generated/api/source-file.service";

@Component({
    selector: "source-document-summary-information",
    templateUrl: "./source-document-summary-information.component.html",
    styleUrls: ["./source-document-summary-information.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        SourceDocumentFormComponent,
        BackToTopComponent,
        AsyncPipe,
        MatButton,
        MatIcon,
        MatIconButton,
        SourceDocumentFileListComponent,
    ],
})
export class SourceDocumentSummaryInformationComponent implements OnInit, OnDestroy {
    @ViewChild("updateSourceDocumentForm", { read: SourceDocumentFormComponent }) form: SourceDocumentFormComponent;

    public sourceDocument$: Observable<any>;
    public sourceDocument: SourceDto;
    public currentUser: UserDto;

    public editMode = false;
    public sourceDocumentUpsertDto: SourceUpsertDto;

    user: Subscription;
    sourceDocumentPutSubscription: Subscription;
    sourceDocumentFileSubscription: Subscription;

    constructor(
        private sourceDocumentService: SourceService,
        private currentSourceService: CurrentSourceDocumentService,
        private authenticationService: AuthenticationService,
        private editViewEventService: EditViewEventService,
        private alertService: AlertService,
        private cdr: ChangeDetectorRef,
        private sourceDocumentFileService: SourceFileService
    ) {}

    ngOnInit(): void {
        this.sourceDocument$ = this.currentSourceService.getCurrentSourceDocument().pipe(
            map((data) => {
                this.sourceDocument = data;
                this.sourceDocumentUpsertDto = this.currentSourceService.createSourceUpsertDtoFromSourceDto(this.sourceDocument);
                this.cdr.markForCheck();
                return data;
            })
        );

        this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.cdr.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.user?.unsubscribe();
        this.sourceDocumentPutSubscription?.unsubscribe();
        this.sourceDocumentFileSubscription?.unsubscribe();
    }

    refreshSourceDocument(result: any) {
        this.sourceDocument = result;
        this.editMode = false;
        this.currentSourceService.setCurrentSourceDocument(this.sourceDocument);
        this.editViewEventService.editButtonClicked.next(this.editMode);
        this.cdr.detectChanges();
    }

    save() {
        this.sourceDocumentPutSubscription = this.sourceDocumentService.sourcesSourceIDPut(this.sourceDocument.SourceID, this.form.workingSourceUpsertDto).subscribe((result) => {
            if(this.form.files && this.form.files.length > 0) {
                this.sourceDocumentFileSubscription = this.sourceDocumentFileService.sourcesSourceIDFilesPost(result.SourceID, this.form.files).subscribe({
                    next: () => {
                        this.alertService.pushAlert(new Alert("The Source Document was successfully updated.", AlertContext.Success), 5000);
                        this.refreshSourceDocument(result);
                        return result;
                    },
                    error: () => {
                    },
                    complete: () => {
                    }
                });
            } else {
                this.alertService.pushAlert(new Alert("The Source Document was successfully updated.", AlertContext.Success), 5000);
                this.refreshSourceDocument(result);
            }
            return result;
        });
    }

    canEdit(): boolean {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.SourceDocumentRights, RightsEnum.Update) && !this.editMode;
    }

    enableEditMode() {
        this.editMode = true;
        this.editViewEventService.editButtonClicked.next(this.editMode);
        this.cdr.markForCheck();
    }

    cancelEdit() {
        this.editMode = false;
        this.editViewEventService.editButtonClicked.next(this.editMode);
        this.cdr.detectChanges();
    }

    canExit() {
        if (this.editMode) {
            return JSON.stringify(this.sourceDocumentUpsertDto) === JSON.stringify(this.form.workingSourceUpsertDto);
        } else {
            return true;
        }
    }
}
