<div class="detail-view" *ngIf="commitment$ | async as commitment">
    <div class="file-uploader" *ngIf="commitmentFiles$ | async as commitmentFiles">
        
    </div>
    
    <div class="table-responsive" style="height: 500px">
        <div style="display: flex; align-items: center;">
            <esa-label label="Files" style="margin-right: auto; margin-left: -10rem; margin-bottom: 13px"></esa-label>
            <beacon-grid-actions [grid]="commitmentFileGrid" downloadFileName="commitments" [downloadColIDsToExclude]="['0']"></beacon-grid-actions>
        </div>
        <div style="height: calc(100% - 40px); overflow-y: auto;">
            <ag-grid-angular
                #commitmentFileGrid
                style="width: 100%; height: 100%"
                class="ag-theme-material"
                [rowData]="rowData"
                [columnDefs]="columnDefs"                
                [suppressMenuHide]="true"
                (gridReady)="onCommitmentFileGridReady($event)"
                >
            </ag-grid-angular>
        </div>
    </div>

    <beacon-back-to-top></beacon-back-to-top>
</div>
