import { Component, ElementRef } from "@angular/core";
import { AgRendererComponent } from "ag-grid-angular";
import { RouterLink } from "@angular/router";
import { NgIf } from "@angular/common";

@Component({
    selector: "beacon-link-renderer",
    templateUrl: "./link-renderer.component.html",
    styleUrls: ["./link-renderer.component.scss"],
    standalone: true,
    imports: [NgIf, RouterLink],
})
export class LinkRendererComponent implements AgRendererComponent {
    params: any;
    routerLink: string[];
    constructor(private elRef:ElementRef) { }
    agInit(params: any): void {
        if (params.value === null) {
            params = { value: { LinkDisplay: "", LinkValue: "", ChildRoute: null, QueryParams: null }, inRouterLink: "" };
        } else {
            this.params = params;
            this.routerLink = [params.inRouterLink, params.value.LinkValue];
            if(params.value.ChildRoute) {
                this.routerLink.push(params.value.ChildRoute);
            }
        }

        const el = this.elRef.nativeElement;
        params.setTooltip(params.value.LinkDisplay, () => {
            return el.scrollWidth > el.clientWidth
        });
    }
    
    refresh(params: any): boolean {
        return false;
    }
}
