import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { EvidenceOfComplianceDto } from '../../generated/model/evidence-of-compliance-dto';
import { EvidenceOfComplianceService } from '../../generated/api/evidence-of-compliance.service';
import { AsyncPipe, NgFor } from '@angular/common';
import { FileDropUploadComponent } from '../file-drop-upload/file-drop-upload.component';
import { KvPairComponent } from '../kv-pair/kv-pair.component';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { FormGroupOf } from '../../forms/form-group-of';
import { EsaMaterialFormFieldComponent } from 'esa-material-form-field';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { AlertService } from '../../services/alert.service';
import { EvidenceOfComplianceFileService } from '../../generated/api/evidence-of-compliance-file.service';
import { EvidenceOfComplianceFileDto } from '../../generated/model/evidence-of-compliance-file-dto';
import { SimpleFileDisplayComponent } from '../simple-file-display/simple-file-display.component';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';
import { BeaconLoadingOverlayComponent } from '../beacon-loading-overlay/beacon-loading-overlay.component';
import { ConfirmService } from 'src/app/services/confirm.service';

@Component({
  selector: 'evidence-of-compliance-upsert',
  standalone: true,
  imports: [AsyncPipe, FileDropUploadComponent, BeaconLoadingOverlayComponent, LoadingSpinnerComponent, SimpleFileDisplayComponent, KvPairComponent, MatInput, MatFormField, NgFor, MatButton, MatIconButton, MatIcon, MatTooltip, EsaMaterialFormFieldComponent, FormsModule, ReactiveFormsModule],
  templateUrl: './evidence-of-compliance-upsert.component.html',
  styleUrl: './evidence-of-compliance-upsert.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EvidenceOfComplianceUpsertComponent {

  public isLoading: boolean = false;
  public isEditMode: boolean = false;
  public _evidenceOfComplianceID: string;
  public evidenceOfCompliance$: Observable<EvidenceOfComplianceDto>;
  public originalEvidenceOfCompliance: EvidenceOfComplianceDto;

  public formGroup : FormGroup; 

  @Input() set evidenceOfComplianceID(value: string) {
    if(value){
      this._evidenceOfComplianceID = value;
      this.evidenceOfCompliance$ = this.evidenceOfComplianceService.evidenceOfComplianceEvidenceOfComplianceIDGet(this._evidenceOfComplianceID)
      .pipe(
        tap(x => {
          this.originalEvidenceOfCompliance = JSON.parse(JSON.stringify(x));
          this.setForm(x);
        })
      );
    }
  }

  setForm(evidenceOfComplianceDto: EvidenceOfComplianceDto) {
    this.formGroup = this.fb.group({
      EvidenceOfComplianceID: [evidenceOfComplianceDto.EvidenceOfComplianceID],
      Notes: [evidenceOfComplianceDto.Notes ?? ''],
      EvidenceOfComplianceFiles: this.fb.control<EvidenceOfComplianceFileDto[]>(evidenceOfComplianceDto.EvidenceOfComplianceFiles),
      NewEvidenceOfComplianceFiles: this.fb.control<File[]>([]),
    })
  }

  constructor(
    private evidenceOfComplianceService: EvidenceOfComplianceService,
    private alertService: AlertService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private confirmService: ConfirmService
  ) { }

  fileInputChanged(fileList: any) {
    const files = Array.from<File>(fileList)

    this.formGroup.controls.NewEvidenceOfComplianceFiles.patchValue([...this.formGroup.controls.NewEvidenceOfComplianceFiles.value,...files]);
  }

  save() {
    var newFileNames = this.formGroup.controls.NewEvidenceOfComplianceFiles.value.map((f: File) => `"${f.name}"`);
    var existingFileNames = this.formGroup.controls.EvidenceOfComplianceFiles.value.map((f: EvidenceOfComplianceFileDto) => `"${f.Name}"`);
    var duplicateFileNames = newFileNames.filter((x) => {
      return existingFileNames.includes(x);
    });
    if(duplicateFileNames.length > 0) {
      this.confirmService.confirm({color:'warn', header: `Replace File${this.formGroup.controls.NewEvidenceOfComplianceFiles.value.length > 1 ? "s" : ""}`, text: `You are uploading ${duplicateFileNames.length} file(s) that already exist. Are you sure you want to override the following files? ${duplicateFileNames.join(", ")}` }).subscribe((result) => {
        if(!result) {
          return;
        }
        this.saveImpl();
      });
    } else {
      this.saveImpl();
    }
  }

  private saveImpl() {
    this.isLoading = true;
    this.cdr.markForCheck();
    var filesJson = this.formGroup.controls.EvidenceOfComplianceFiles.value.map((f: EvidenceOfComplianceFileDto) => JSON.stringify(f));
    this.evidenceOfComplianceService.evidenceOfComplianceEvidenceOfComplianceIDPut(this._evidenceOfComplianceID, this.formGroup.controls.Notes.value, filesJson,  this.formGroup.controls.NewEvidenceOfComplianceFiles.value).subscribe({
      next: (result) => {
        this.evidenceOfComplianceID = result.EvidenceOfComplianceID;
        this.formGroup.controls.NewEvidenceOfComplianceFiles.patchValue([]);
        this.isLoading = false;
        this.isEditMode = false;
      },
      error: (error) => {
        this.isLoading = false;
      },
      complete: () => {
        this.cdr.markForCheck();
      }
    });
  }

  deleteExistingFile(evidenceOfComplianceFile: EvidenceOfComplianceFileDto) {
    this.formGroup.controls.EvidenceOfComplianceFiles.patchValue(this.formGroup.controls.EvidenceOfComplianceFiles.value.filter((f: EvidenceOfComplianceFileDto) => f.EvidenceOfComplianceFileID !== evidenceOfComplianceFile.EvidenceOfComplianceFileID));
    this.cdr.markForCheck();
  }

  deleteNewFile(newfile: File) {
    var remainingFiles = this.formGroup.controls.NewEvidenceOfComplianceFiles.value.filter((f: File) => f !== newfile);
    this.fileInputChanged(remainingFiles);
  }

  toggleEditMode(enableEdit: boolean) {
    this.isEditMode = enableEdit;
    if(!enableEdit){
      this.formGroup.reset(this.originalEvidenceOfCompliance);
    }
  }

}
