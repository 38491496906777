import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { SourceService } from "src/app/shared/generated/api/source.service";
import { SourceFileService } from "src/app/shared/generated/api/source-file.service";
import { SourceUpsertDto } from "src/app/shared/generated/model/source-upsert-dto";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { SourceDocumentFormComponent } from "../source-document-form/source-document-form.component";
import { Subscription } from "rxjs";
import { arrForEach } from "@microsoft/applicationinsights-core-js";
import { SourceFileDto } from "src/app/shared/generated/model/source-file-dto";

@Component({
    selector: "source-document-create",
    templateUrl: "./source-document-create.component.html",
    styleUrls: ["./source-document-create.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SourceDocumentFormComponent],
})
export class SourceDocumentCreateComponent implements OnInit, OnDestroy {
    @ViewChild("createNewSourceDocumentForm", { read: SourceDocumentFormComponent }) form: SourceDocumentFormComponent;
    public model: SourceUpsertDto = new SourceUpsertDto();
    public originalModel: any;
    private sourceResponse: any;

    sourceSubscription: Subscription;
    sourceFileSubscription: Subscription;

    constructor(private sourceService: SourceService, private sourceFileService: SourceFileService, private alertService: AlertService, private router: Router, private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.model = {
            ProjectID: null,
            Name: null,
            DateOfLatestAmendment: null,
            ReferenceNumber: null,
            ApprovingAgency: null,
            AgencyContact: null,
            AgencySignatory: null,
            Description: null,
            AgreementTerms: null,
        };

        this.originalModel = JSON.stringify(this.model);
    }

    ngOnDestroy() {
        this.cdr.detach();
        this.sourceSubscription?.unsubscribe();
    }
    
    save(createNewSourceDocumentForm: NgForm): void {
        this.sourceSubscription = this.sourceService.sourcesPost(this.form.workingSourceUpsertDto).subscribe(
            (response) => {
                this.sourceResponse = response;

                if(this.form.files && this.form.files.length > 0) {
                    this.sourceFileSubscription = this.sourceFileService.sourcesSourceIDFilesPost(this.sourceResponse.SourceID, this.form.files).subscribe({
                        next: (response) => {
                            this.router.navigateByUrl(`/source-documents/${this.sourceResponse.SourceID}/summary-information`).then(() => {
                                this.alertService.pushAlert(new Alert("The source document was successfully created.", AlertContext.Success), 5000);
                            });
                        },
                        error: (error) => {
                        },
                        complete: () => {
                        }
                    });
                } else{
                    this.router.navigateByUrl(`/source-documents/${this.sourceResponse.SourceID}/summary-information`).then(() => {
                        this.alertService.pushAlert(new Alert("The source document was successfully created.", AlertContext.Success), 5000);
                    });
                }                
            },
            (error) => {
                this.cdr.detectChanges();
            },
        );
    };


    cancelEdit() {
        this.router.navigateByUrl("/source-documents");
    }

    canExit() {
        if (this.sourceResponse) {
            return true;
        } else {
            return this.originalModel === JSON.stringify(this.form.workingSourceUpsertDto);
        }
    }
}
