import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { Alert } from '../../models/alert';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { AlertService } from '../../services/alert.service';
import { AlertContext } from '../../models/enums/alert-context.enum';

@Component({
  selector: 'beacon-snackbar',
  standalone: true,
  imports: [MatButton, MatIcon, MatIconButton],
  templateUrl: './beacon-snackbar.component.html',
  styleUrl: './beacon-snackbar.component.scss'
})
export class BeaconSnackbarComponent implements OnDestroy {
  public AlertContext : typeof AlertContext = AlertContext;
  
  constructor(
    public snackBarRef: MatSnackBarRef<BeaconSnackbarComponent>,
    private alertService: AlertService,
    @Inject(MAT_SNACK_BAR_DATA) public data: IBeaconSnackbarData) {
  }
  
  ngOnDestroy(): void {
    this.alertService.removeAlert(this.data.alert);
  }

  dismiss() {
    this.snackBarRef.dismiss();
    
  }
}

export interface IBeaconSnackbarData {
  alert: Alert;
}