import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { ComponentFormComponent } from "../component-form/component-form.component";
import { ComponentUpsertDto } from "src/app/shared/generated/model/component-upsert-dto";
import { ComponentService } from "src/app/shared/generated/api/component.service";
import { Subscription } from "rxjs";

@Component({
    selector: "component-create",
    templateUrl: "./component-create.component.html",
    styleUrls: ["./component-create.component.scss"],
    standalone: true,
    imports: [ComponentFormComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComponentCreateComponent implements OnInit {
    @ViewChild("createNewComponentForm", { read: ComponentFormComponent }) form: ComponentFormComponent;
    public model: ComponentUpsertDto = new ComponentUpsertDto();
    public originalModel: any;
    private componentResponse: any;

    componentPostSubscription: Subscription;

    constructor(private componentService: ComponentService, private alertService: AlertService, private router: Router, private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.model = {
            ProjectID: null,
            ComponentStatusID: null,
            Name: null,
            StartDate: null,
            ExpectedEndDate: null,
            Description: null,
        };

        this.originalModel = JSON.stringify(this.model);
    }

    ngOnDestroy() {
        this.cdr.detach();
        this.componentPostSubscription?.unsubscribe();
    }

    save(createNewComponentForm: NgForm): void {
        this.componentPostSubscription = this.componentService.componentsPost(this.form.componentUpsertDto).subscribe(
            (response) => {
                this.componentResponse = response;
            },
            (error) => {
                this.cdr.detectChanges();
            },
            () => {
                this.router.navigateByUrl(`/components/${this.componentResponse.ComponentID}/summary-information`).then(() => {
                    this.alertService.pushAlert(new Alert("The component was successfully created.", AlertContext.Success), 5000);
                });
            }
        );
    }

    cancelEdit() {
        this.router.navigateByUrl("/components");
    }

    canExit() {
        if (this.componentResponse) {
            return true;
        } else {
            return this.originalModel === JSON.stringify(this.form.componentUpsertDto);
        }
    }
}
