import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { NgForm, FormsModule } from "@angular/forms";
import { CurrentProjectService } from "src/app/services/current-project/current-project.service";
import { TinymceEditorComponent } from "src/app/shared/components/tinymce-editor/tinymce-editor.component";
import { ProjectDto } from "src/app/shared/generated/model/project-dto";
import { ProjectUpsertDto } from "src/app/shared/generated/model/project-upsert-dto";
import { BypassHtmlPipe } from "../../shared/pipes/bypassHtml.pipe";
import { TinymceEditorComponent as TinymceEditorComponent_1 } from "../../shared/components/tinymce-editor/tinymce-editor.component";
import { NgClass, NgIf } from "@angular/common";
import { CustomFormLabelComponent } from "../../shared/components/custom-form-label/custom-form-label.component";
import { EsaMaterialFormFieldComponent, EsaMaterialButtonComponent, EsaLabelComponent } from "esa-material-form-field";
import { MatIcon } from "@angular/material/icon";
import { MatButton } from "@angular/material/button";
import { MatTooltip } from "@angular/material/tooltip";
import { FileDropUploadComponent } from "src/app/shared/components/file-drop-upload/file-drop-upload.component";
import { SimpleFileDisplayComponent } from "src/app/shared/components/simple-file-display/simple-file-display.component";
import { BeaconLoadingOverlayComponent } from "src/app/shared/components/beacon-loading-overlay/beacon-loading-overlay.component";
import { LoadingSpinnerComponent } from "src/app/shared/components/loading-spinner/loading-spinner.component";
import { ProjectFileListComponent } from "../project-file-list/project-file-list.component";

@Component({
    selector: "project-form",
    templateUrl: "./project-form.component.html",
    styleUrls: ["./project-form.component.scss"],
    standalone: true,
    imports: [
    FormsModule,
    EsaMaterialFormFieldComponent,
    CustomFormLabelComponent,
    NgClass,
    NgIf,
    TinymceEditorComponent_1,
    BypassHtmlPipe,
    MatButton,
    MatIcon,
    MatTooltip,
    FileDropUploadComponent,
    SimpleFileDisplayComponent,
    EsaLabelComponent,
    BeaconLoadingOverlayComponent,
    LoadingSpinnerComponent,
    ProjectFileListComponent
],
})
export class ProjectFormComponent implements OnInit {
    @ViewChild("projectForm", { read: NgForm }) form: NgForm;
    @ViewChild("tinyMceEditor") tinyMceEditor: TinymceEditorComponent;

    @Output() formSubmitted = new EventEmitter<any>();
    @Output() cancelEditModeChange = new EventEmitter<boolean>();

    @Input() project: ProjectDto;
    @Input() editMode: boolean;
    @Input() showFileUpload: boolean = false;

    public projectUpsertDto: ProjectUpsertDto;
    public files: File[] = [];
    public isLoading: boolean = false;

    constructor(private currentProjectService: CurrentProjectService) {}

    ngOnInit(): void {
        if (this.project) {
            this.setForm();
        }
    }

    setForm() {
        this.projectUpsertDto = this.currentProjectService.createProjectUpsertDto(this.project);
    }

    saveForm(form: NgForm) {
        this.isLoading = true;
        this.formSubmitted.emit(form);
        this.isLoading = false;
    }

    cancelEditMode() {
        this.setForm();
        this.cancelEditModeChange.emit(true);
    }

    uploadFilesChanged(files: File[]) {
        const existingFileNames = this.files.map((file) => file.name);
        if (existingFileNames.length > 0) 
        {
            const filesToAdd = files.filter((file) => !existingFileNames.includes(file.name));
            this.files.push(...filesToAdd);
        } 
        else 
        {
            this.files.push(...files);
        }
    }

    deleteNewFile(file: File) {
        // find the index of the file and remove it from the array
        const index = this.files.indexOf(file);
        this.files.splice(index, 1);
    }
}
