// IMPORTANT:
// This file is generated from "field-definition-type-enum.tt".
// Any changes to this file will be lost.

export enum FieldDefinitionTypeEnum {
  Name = 1,
  CommitmentID = 2,
  Title = 3,
  CommitmentType = 4,
  ResourceCategory = 5,
  Source = 6,
  FullCommitmentText = 9,
  ComplianceLead = 10,
  Phase = 11,
  ApplicableCommitmentText = 12,
  Seasonality = 13,
  WorkActivities = 14,
  LocationDescription = 15,
  VersionNumber = 16,
  DateApproved = 17,
  ApprovedBy = 18,
  SummaryOfChange = 19,
  DateOfLatestAmendment = 20,
  ReferenceNumber = 21,
  ApprovingAgency = 22,
  AgencyContact = 23,
  AgencySignatory = 24,
  Description = 25,
  GeneralGuidance = 26,
  OriginatorOfChange = 27,
  Tags = 28,
  ImplementationResponsibilities = 29,
  ComplianceRequirementType = 30,
  ComplianceLeads = 31,
  Scope = 32,
  Frequency = 33,
  CommitmentTechnicalLead = 34,
  AgreementTerms = 35,
}