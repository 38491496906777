import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { AuthenticationService } from "src/app/services/authentication.service";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { TenantModuleService } from "src/app/shared/services/tenant-module/tenant-module.service";
import { NgIf } from "@angular/common";

@Component({
    selector: "login-redirect",
    templateUrl: "./login-redirect.component.html",
    styleUrls: ["./login-redirect.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf],
})
export class LoginRedirectComponent implements OnInit {
    public currentUser: UserDto;

    constructor(
        private router: Router,
        private tenantModuleService: TenantModuleService,
        private localAuthService: AuthenticationService,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.tenantModuleService.currentModule$.pipe(filter((module) => module != null)).subscribe((module) => {
            setTimeout(() => {
                this.router.navigate(["/" + module.LandingPage]);
            });
        });

        this.localAuthService.currentUserSetObservable.pipe(filter((user) => user != null)).subscribe((user) => {
            this.currentUser = user;
            this.cdr.markForCheck();
        });
    }
}
