<div class="main">

    @if(isLoading) {
        <loading-spinner />
    }

    <ng-container *ngIf="customPage$ | async as customPage">
        <div class="page-header">
            <h1 class="page-title">{{ customPage.CustomPageDisplayName }}</h1>
        </div>

        <div class="edit-panel pb-3" *ngIf="!isLoading">
            <ng-container *ngIf="!isEditing">
                <div *ngIf="showEditButton()" class="customPageContent" style="position: relative; min-height: 60px">
                    <button mat-icon-button color="primary" class="hoverEditButton"type="button" (click)="enterEdit()"><mat-icon>edit</mat-icon></button>
                    <div *ngIf="!isEmptyContent" class="copy copy-2 custom-content-copy" [innerHtml]="customPage.CustomPageContent | bypassHtml"></div>

                    <div *ngIf="isEmptyContent" class="hoverEmptySpace"></div>
                </div>
                <div *ngIf="!showEditButton()" [innerHtml]="customPage.CustomPageContent | bypassHtml"></div>
            </ng-container>

            <div *ngIf="isEditing">
                <form #customPageForm="ngForm">
                    <tinymce-editor #tinyMceEditor name="Content" [(content)]="customPage.CustomPageContent" [isRequired]="true"></tinymce-editor>
                    <div class="actions-bar" style="margin-top: 1rem">
                        <button
                            mat-flat-button
                            color="primary"
                            (click)="saveEdit()"
                            style="margin-right: 1rem"
                            [disabled]="!customPageForm.form.valid">
                            <mat-icon>save</mat-icon> Save
                        </button>
                        <button mat-button type="button" (click)="cancelEdit()">
                            <mat-icon>cancel</mat-icon> Cancel
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </ng-container>
</div>
