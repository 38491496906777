<div class="evidence-of-compliance-upsert">
    <div class="evidence-of-compliance-upsert__header">
        <h3>Evidence of Compliance</h3>
        @if(isEditMode) {
            <button mat-icon-button color="primary" class="small-icon-button" (click)="toggleEditMode(false)" matTooltip="Cancel Editing"><mat-icon>close</mat-icon></button>
        } @else {
            <button mat-flat-button color="primary" (click)="toggleEditMode(true)" matTooltip="Edit/Add Evidence of Compliance"><mat-icon>edit</mat-icon> Add/Edit Evidence</button>
        }
    </div>
    @if(evidenceOfCompliance$ | async ; as evidenceOfCompliance) {
        @if(isEditMode) {
            <form [formGroup]="formGroup" (ngSubmit)="save()">
                <kv-pair key="Files" [value]="fileDropper">
                    <ng-template #fileDropper>
                        <file-drop-upload uploadFileAccepts=".pdf,.csv,.xlsx" [multiple]="true" (filesChanged)="fileInputChanged($event)"></file-drop-upload>
                        <ul class="file-max-height">
                            <li *ngFor="let file of formGroup.controls.NewEvidenceOfComplianceFiles.value">
                                <simple-file-display [isNotYetUploaded]="true" [fileName]="file.name" [displayDelete]="true" (deleteFile)="deleteNewFile(file)"></simple-file-display>
                            </li>
                            <li *ngFor="let file of formGroup.controls.EvidenceOfComplianceFiles.value">
                                <simple-file-display [fileName]="file.Name" [displayDelete]="true" (deleteFile)="deleteExistingFile(file)"></simple-file-display>
                            </li>
                        </ul>
                    </ng-template>
                </kv-pair>
                <kv-pair key="Notes" [value]="notesField">
                    <ng-template #notesField>
                        <mat-form-field>
                            <textarea matInput formControlName="Notes" placeholder="Notes"></textarea>
                        </mat-form-field>
                    </ng-template>
                </kv-pair>
                <div class="form-footer">
                    <button mat-button color="primary" tyle="button" [disabled]="formGroup.invalid || isLoading" (click)="toggleEditMode(false)"><mat-icon>close</mat-icon> Cancel</button>
                    <button mat-flat-button color="primary" [disabled]="formGroup.invalid || isLoading"><mat-icon>save</mat-icon> Save</button>
                </div>
                @if(isLoading) {
                    <beacon-loading-overlay>
                        Saving Evidence of Compliance...
                        <loading-spinner color="accent" [diameter]="50" />
                    </beacon-loading-overlay>
                }
            </form>
        } @else {
            @if(evidenceOfCompliance.EvidenceOfComplianceFiles.length <= 0){
                <kv-pair key="Files"/>
            } @else {
                <kv-pair key="Files" [value]="files">
                    <ng-template #files>
                        <ul class="file-max-height">
                            <li *ngFor="let file of evidenceOfCompliance.EvidenceOfComplianceFiles">
                                <simple-file-display [fileName]="file.Name" [downloadUrl]="'evidence-of-compliance-file/' + file.EvidenceOfComplianceFileID"></simple-file-display>
                            </li>
                        </ul>
                    </ng-template>
                </kv-pair>
            }
            <kv-pair key="Notes" [value]="evidenceOfCompliance.Notes" />
        }
    }
</div>
