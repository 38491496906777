<page-body>
    @if(checklistItem$ | async ; as checklistItem) {
        <div class="checklist-item-details">
            <div class="checklist-item-header">
                <h1><checklist-item-status [checklistItemStatus]="checklistItem.Status"></checklist-item-status> {{checklistItem.ComplianceRequirement.Name}} <pill>{{checklistItem.ComplianceRequirement.Commitment.ClientCommitmentID}}</pill></h1>
            </div>
            <div class="grid-12">
                <div class="checklist-item-body g-col-8">
                <kv-pair key="Compliance Requirement Name" [value]="complianceRequirementName" />
                <ng-template #complianceRequirementName>
                    <a target="_blank" [routerLink]="['/commitments', checklistItem.ComplianceRequirement.Commitment.CommitmentID, 'compliance-requirements']" [queryParams]="{'activeComplianceRequirementID':checklistItem.ComplianceRequirementID}">
                        {{checklistItem.ComplianceRequirement.Name}}
                    </a>
                </ng-template>
                <kv-pair key="Frequency" [value]="checklistItem.ComplianceRequirement.Frequency?.Name ?? 'N/A'" />
                <kv-pair key="Phase" [value]="checklistItem.ComplianceRequirement.Phase?.Name ?? 'N/A'" />
                <kv-pair key="Compliance Requirement Type" [value]="checklistItem.ComplianceRequirement.ComplianceRequirementType?.Name ?? 'N/A'" />
                <kv-pair key="Applicable Commitment Text" style="width:100%" [isHtmlString]="true" [value]="checklistItem.ComplianceRequirement.ApplicableCommitmentText ?? 'N/A'" />
                </div>
                <div class="checklist-item-sidebar g-col-4">
                    <div class="checklist-item-evidence">
                        <checklist-item-status-display-and-upsert (statusUpdated)="onStatusUpdated($event)" [checklistItemID]="checklistItem.ChecklistItemID" [componentID]="this.componentID"></checklist-item-status-display-and-upsert>
                    </div>
                    <div class="checklist-item-status-display-and-upsert">
                        <evidence-of-compliance-upsert [evidenceOfComplianceID]="checklistItem.EvidenceOfComplianceID"></evidence-of-compliance-upsert>
                    </div>                   
                </div> 
            </div>
        </div>
        <hr />
        <div>
            <h2>Tasks</h2>
            <app-checklist-task-grid [checklistItemID]="checklistItem.ChecklistItemID" [componentID]="this.componentID"></app-checklist-task-grid>
        </div>
    } 
    @else {
        <loading-spinner />
    }
</page-body>
