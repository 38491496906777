import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm, FormsModule } from '@angular/forms';
import { RoleUpsertDto } from 'src/app/shared/generated/model/role-upsert-dto';
import { Permission } from 'src/app/shared/models/permission';
import { NgClass, NgIf } from '@angular/common';
import { EsaMaterialFormFieldComponent, EsaMaterialButtonComponent } from 'esa-material-form-field';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
    selector: "role-form",
    templateUrl: "./role-form.component.html",
    styleUrls: ["./role-form.component.scss"],
    standalone: true,
    imports: [
        FormsModule,
        EsaMaterialFormFieldComponent,
        NgClass,
        NgIf,
        MatButton,
        MatIcon,
        MatTooltip
    ],
})
export class RoleFormComponent implements OnInit {
    @ViewChild("roleForm", { read: NgForm }) form: NgForm;

    @Output() formSubmitted = new EventEmitter<any>();
    @Output() cancelEditModeChange = new EventEmitter<boolean>();

    @Input() role: any;
    @Input() editMode: boolean;

    roleUpsertDto: RoleUpsertDto;
    public permissionsOptions = Permission.Options;

    constructor() {}

    ngOnInit(): void {
        if (this.role) {
            this.setForm();
        }
    }

    setForm() {
        this.roleUpsertDto = new RoleUpsertDto({
            RoleName: this.role.RoleName,
            RoleDescription: this.role.RoleDescription,
            Rights: {
                CustomPageRights: this.role.Rights.CustomPageRights || 0,
                CustomRichTextRights: this.role.Rights.CustomRichTextRights || 0,
                FieldDefinitionRights: this.role.Rights.FieldDefinitionRights || 0,
                UserRights: this.role.Rights.UserRights || 0,
                SourceDocumentRights: this.role.Rights.SourceDocumentRights || 0,
                CommitmentRights: this.role.Rights.CommitmentRights || 0,
                RoleRights: this.role.Rights.RoleRights || 0,
                ProjectRights: this.role.Rights.ProjectRights || 0,
            },
            Flags: {
                CanImpersonateUsers: this.role.Flags.CanImpersonateUsers || false,
                CanFinalizeCommitments: this.role.Flags.CanFinalizeCommitments || false,
                CanEditFinalizedCommitments: this.role.Flags.CanEditFinalizedCommitments || false,
            },
        });
    }

    saveForm(form: NgForm) {
        this.formSubmitted.emit(form);
    }

    cancelEditMode() {
        this.setForm();
        this.cancelEditModeChange.emit(true);
    }
}
