import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: "root",
})
export class EditViewEventService {
    editButtonClicked = new Subject();
    
    constructor() {}

    listenForEditButtonClicked():Observable<any> {
        return this.editButtonClicked.asObservable();
    }
}
