import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, QueryList, ViewChildren, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";
import { CurrentCommitmentService } from "src/app/services/current-commitment/current-commitment.service";
import { EditViewEventService } from "src/app/services/edit-view-event.service";
import { ComplianceRequirementService } from "src/app/shared/generated/api/compliance-requirement.service";
import { ComplianceRequirementTagService } from "src/app/shared/generated/api/compliance-requirement-tag.service";
import { CommitmentVersionStatusEnum } from "src/app/shared/generated/enum/commitment-version-status-enum";
import { FlagEnum } from "src/app/shared/generated/enum/flag-enum";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { CommitmentDto } from "src/app/shared/generated/model/commitment-dto";
import { ComplianceRequirementDto } from "src/app/shared/generated/model/compliance-requirement-dto";
import { ComplianceRequirementUpsertDto } from "src/app/shared/generated/model/compliance-requirement-upsert-dto";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { VComplianceRequirementTagDto } from "src/app/shared/generated/model/v-compliance-requirement-tag-dto";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { ComplianceRequirementFormComponent } from "./form/compliance-requirement-form.component";
import { map, tap } from "rxjs/operators";
import { CurrentComplianceRequirementService } from "src/app/services/current-compliance-requirement";
import { CdkDragDrop, moveItemInArray, CdkDropList, CdkDrag, CdkDragPlaceholder } from "@angular/cdk/drag-drop";
import { BackToTopComponent } from "../../shared/components/back-to-top/back-to-top.component";
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader } from "@angular/material/expansion";
import { MatIcon } from "@angular/material/icon";
import { NgIf, NgClass, NgFor, AsyncPipe } from "@angular/common";
import { MatButton, MatIconButton } from "@angular/material/button";
import { ConfirmService } from "src/app/services/confirm.service";
import { LoadingSpinnerComponent } from "src/app/shared/components/loading-spinner/loading-spinner.component";
import { EvidenceOfComplianceFileService } from "src/app/shared/generated/api/evidence-of-compliance-file.service";
import { firstValueFrom } from 'rxjs';

@Component({
    selector: "compliance-requirement-for-commitment-list",
    templateUrl: "./compliance-requirement-for-commitment-list.component.html",
    styleUrls: ["./compliance-requirement-for-commitment-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        CdkDropList,
        NgFor,
        CdkDrag,
        CdkDragPlaceholder,
        MatIcon,
        MatAccordion,
        MatExpansionPanel,
        MatExpansionPanelHeader,
        ComplianceRequirementFormComponent,
        BackToTopComponent,
        AsyncPipe,
        MatButton,
        MatIcon,
        MatIconButton,
        LoadingSpinnerComponent
    ],
})
export class ComplianceRequirementForCommitmentListComponent implements OnInit, OnDestroy {
    @ViewChildren("compReqForm", { read: ComplianceRequirementFormComponent }) comReqForms: QueryList<ComplianceRequirementFormComponent>;

    public commitment$: Observable<any>;
    public commitment: CommitmentDto;
    public currentUser: UserDto;

    public complianceRequirements: ComplianceRequirementDto[] = [];
    public origionalComplianceRequirements: ComplianceRequirementDto[] = [];
    public complianceRequirements$: Observable<any>;
    public loading: Boolean = true;
    public editMode: Boolean = false;
    public reorderMode: Boolean = false;
    public selectedComplianceRequirementIDForEdit = null;
    public selectedComplianceRequirement: ComplianceRequirementUpsertDto;
    public activeComplianceRequirementID: string;

    user: Subscription;
    param: Subscription;

    public allComplianceRequirementTags: VComplianceRequirementTagDto[];
    public allComplianceRequirementTags$: Observable<VComplianceRequirementTagDto[]>;

    constructor(
        private evidenceOfComplianceFilesService: EvidenceOfComplianceFileService,
        private complianceRequirementService: ComplianceRequirementService,
        private currentCommitmentService: CurrentCommitmentService,
        private vComplianceRequirementTagService: ComplianceRequirementTagService,
        private currentComplianceReqService: CurrentComplianceRequirementService,
        private authenticationService: AuthenticationService,
        private editViewEventService: EditViewEventService,
        private activatedRoute: ActivatedRoute,
        private alertService: AlertService,
        private cdr: ChangeDetectorRef,
        private router: Router,
        private confirmService: ConfirmService
    ) {}

    ngOnInit(): void {
        this.param = this.activatedRoute.queryParams.subscribe((results) => {
            if (results["activeComplianceRequirementID"]) {
                this.activeComplianceRequirementID = results["activeComplianceRequirementID"];
            }
        });

        this.commitment$ = this.currentCommitmentService.getCurrentCommitment().pipe(
            map((data) => {
                this.commitment = data;
                if (data !== null) {
                    this.refreshComplianceRequirements();
                }
                return data;
            })
        );

        this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.cdr.markForCheck();
        });

        this.allComplianceRequirementTags$ = this.vComplianceRequirementTagService.complianceRequirementTagsGet();
    }

    ngOnDestroy(): void {
        this.user?.unsubscribe();
        this.param?.unsubscribe();
    }

    refreshComplianceRequirements() {
        this.complianceRequirements$ = this.complianceRequirementService.commitmentsCommitmentIDComplianceRequirementsGet(this.commitment.CommitmentID);

        this.complianceRequirements$.subscribe((results) => {
            this.complianceRequirements = results;
            this.origionalComplianceRequirements = [...results]; // The spread is needed so its a different array in memory, otherwise reording the array will change the origional array
            this.loading = false;
            this.cdr.markForCheck();
        });
    }

    canCreate() {
        if (this.commitment.CommitmentVersionStatus.CommitmentVersionStatusID != CommitmentVersionStatusEnum.Draft && this.currentUser) {
            return this.authenticationService.hasFlag(this.currentUser, FlagEnum.CanEditFinalizedCommitments);
        }

        return (
            this.commitment.CommitmentVersionStatus.CommitmentVersionStatusID == CommitmentVersionStatusEnum.Draft &&
            this.authenticationService.hasPermission(this.currentUser, PermissionEnum.CommitmentRights, RightsEnum.Create) &&
            !this.editMode
        );
    }

    create() {
        this.router.navigate(["create"], { relativeTo: this.activatedRoute });
    }

    canEdit(): boolean {
        return this.currentCommitmentService.canEditCurrentCommitment(this.currentUser, this.commitment);
    }

    editComplianceReq(complianceRequirement: any) {
        this.editMode = true;
        this.selectedComplianceRequirement = this.currentComplianceReqService.createComplianceRequirementDto(complianceRequirement);
        this.selectedComplianceRequirementIDForEdit = complianceRequirement.ComplianceRequirementID;
        this.cdr.detectChanges();
    }

    cancelEdit() {
        this.refreshComplianceRequirements();
        this.selectedComplianceRequirementIDForEdit = null;
        this.editMode = false;
        this.editViewEventService.editButtonClicked.next(this.editMode);
        this.cdr.markForCheck();
    }

    update(complianceRequirementForm: any) {
        this.complianceRequirementService
            .commitmentsCommitmentIDComplianceRequirementsComplianceRequirementIDPut(
                this.commitment.CommitmentID,
                complianceRequirementForm.currentComplianceReqID,
                complianceRequirementForm.updatedForm
            )
            .subscribe((result) => {
                this.alertService.pushAlert(new Alert("The compliance requirement was successfully updated.", AlertContext.Success), 5000);
                this.activeComplianceRequirementID = result.ComplianceRequirementID;
                this.cancelEdit();
            });
    }

    canDelete() {
        if (this.commitment.CommitmentVersionStatus.CommitmentVersionStatusID != CommitmentVersionStatusEnum.Draft && this.currentUser) {
            return (
                this.authenticationService.hasFlag(this.currentUser, FlagEnum.CanEditFinalizedCommitments) &&
                this.authenticationService.hasPermission(this.currentUser, PermissionEnum.CommitmentRights, RightsEnum.Delete)
            );
        }

        return (
            this.commitment.CommitmentVersionStatus.CommitmentVersionStatusID == CommitmentVersionStatusEnum.Draft &&
            this.authenticationService.hasPermission(this.currentUser, PermissionEnum.CommitmentRights, RightsEnum.Delete)
        );
    }

    deleteComplianceReq(complianceRequirement: any) {
        this.evidenceOfComplianceFilesService.evidenceOfComplianceFileCommitmentIDComplianceRequirementIDGet(
            this.commitment.CommitmentID,
            complianceRequirement.ComplianceRequirementID
        ).subscribe((count) => {
            const confirmationText = count > 0
                ? `Are you sure you want to delete this Compliance Requirement? <br/> <strong>Deleting this Compliance Requirement will also DELETE ${count} EVIDENCE OF COMPLIANCE FILE(S).</strong> <br/> This action CANNOT BE UNDONE.`
                : `Are you sure you want to delete this Compliance Requirement? This action cannot be undone.`;

            this.confirmService.confirm({
                color: 'warn',
                header: `Delete Compliance Requirement`,
                text: confirmationText,
            }).subscribe((result) => {
                if (!result) return;
                this.complianceRequirementService
                    .commitmentsCommitmentIDComplianceRequirementsComplianceRequirementIDDelete(
                        this.commitment.CommitmentID,
                        complianceRequirement.ComplianceRequirementID
                    )
                    .subscribe({
                        next: (result) => {
                            this.alertService.pushAlert(new Alert("The compliance requirement was successfully deleted.", AlertContext.Success), 5000);
                            this.currentCommitmentService.setCurrentCommitment(this.commitment);
                            this.refreshComplianceRequirements();
                        },
                        error: (error) => {
                            this.alertService.pushAlert(new Alert("The compliance requirement could not be deleted.", AlertContext.Danger), 5000);
                        }
                    });
            });
        });
    }

    canExit() {
        const foundForm = this.comReqForms.find((f) => {
            return f.complianceRequirement.ComplianceRequirementID == this.selectedComplianceRequirementIDForEdit;
        });

        if (this.editMode) {
            return JSON.stringify(foundForm.model) === JSON.stringify(this.selectedComplianceRequirement);
        } else {
            return true;
        }
    }

    reorderModeButtonClicked() {
        this.editMode = false;
        this.reorderMode = true;
        this.cdr.markForCheck();
    }

    cancelReorderButtonClicked() {
        this.reorderMode = false;
        this.complianceRequirements = [...this.origionalComplianceRequirements]; // Reset the array to the origional array
        this.cdr.markForCheck();
    }

    dropComplianceRequirement(event: CdkDragDrop<ComplianceRequirementDto[]>) {
        moveItemInArray(this.complianceRequirements, event.previousIndex, event.currentIndex);
    }

    saveReorderButtonClicked() {
        this.complianceRequirementService
            .commitmentsCommitmentIDComplianceRequirementsUpdateDisplayIndexPut(this.commitment.CommitmentID, this.complianceRequirements)
            .subscribe((result) => {
                this.alertService.pushAlert(new Alert("The compliance requirements were successfully reordered.", AlertContext.Success), 5000);
                this.reorderMode = false;
                this.cdr.markForCheck();
            });
    }
}
