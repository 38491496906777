import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, FormsModule, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatListOption, MatSelectionList } from '@angular/material/list';
import { MatTooltip } from '@angular/material/tooltip';
import { ActivatedRoute } from '@angular/router';
import { EsaMaterialFormFieldComponent } from 'esa-material-form-field';
import { CurrentProjectService } from 'src/app/services/current-project/current-project.service';
import { CustomFormLabelComponent } from 'src/app/shared/components/custom-form-label/custom-form-label.component';
import { TinymceEditorComponent } from 'src/app/shared/components/tinymce-editor/tinymce-editor.component';
import { ProjectDto } from 'src/app/shared/generated/model/project-dto';
import { ProjectUpsertDto } from 'src/app/shared/generated/model/project-upsert-dto';
import { BypassHtmlPipe } from 'src/app/shared/pipes/bypassHtml.pipe';
import { ChecklistService } from 'src/app/shared/generated/api/checklist.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'component-checklist-form',
  standalone: true,
  templateUrl: './component-checklist-form.component.html',
  styleUrl: './component-checklist-form.component.scss',
  imports: [
    EsaMaterialFormFieldComponent,
    CustomFormLabelComponent,
    NgClass,
    NgIf,
    TinymceEditorComponent,
    BypassHtmlPipe,
    MatButton,
    MatIcon,
    MatTooltip,
    ReactiveFormsModule,
    MatSelectionList,
    MatListOption,
    FormsModule
  ]
})
export class ComponentChecklistFormComponent implements OnInit, OnDestroy {
  @Input() checklistID: string;
  @Input() componentID: string;
  @Output() formValueChange = new EventEmitter<FormGroup>();
  formValueChangeSubscription : Subscription = Subscription.EMPTY;
  @Input() editMode: boolean;
  public isLoading: boolean = false;

  public checklistFormGroup: FormGroup = this.fb.group({
    Name: ['', Validators.required],
    Description: [''],
    ComponentID: [''],
    ChecklistID: [null]
  })

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private checklistService: ChecklistService,) {}

  ngOnDestroy(): void {
    this.formValueChangeSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.checklistFormGroup.controls.ComponentID.patchValue(this.componentID);
    if(this.checklistID){
      this.checklistFormGroup.controls.ChecklistID.patchValue(this.checklistID);
    }
    
    
    if (this.checklistID && this.componentID) {
      this.loadChecklistDetails(this.componentID, this.checklistID);
    }

    this.formValueChangeSubscription = this.checklistFormGroup.valueChanges.subscribe(value => this.formValueChange.emit(this.checklistFormGroup));

  }

  loadChecklistDetails(componentID: string, checklistID: string) {
    this.isLoading = true;
    this.checklistService.componentsComponentIDChecklistChecklistIDGet(componentID, checklistID).subscribe({
      next: (checklist) => {
        this.checklistFormGroup.patchValue({
          Name: checklist.Name,
          Description: checklist.Description,
          ComponentID: checklist.ComponentID
        });
        this.isLoading = false;
      },
      error: (err) => {
        console.error('Error loading checklist details:', err);
        this.isLoading = false;
      }
    });
  }
  
}
