import { DOCUMENT, NgClass } from "@angular/common";
import { Component, HostListener, Inject } from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import { MatFabButton } from "@angular/material/button";

@Component({
    selector: "beacon-back-to-top",
    templateUrl: "./back-to-top.component.html",
    styleUrls: ["./back-to-top.component.scss"],
    standalone: true,
    imports: [
        MatFabButton,
        NgClass,
        MatIcon,
    ],
})
export class BackToTopComponent {
    windowScrolled: boolean;
    constructor(@Inject(DOCUMENT) private document: Document) {}
    @HostListener("window:scroll", [])
    onWindowScroll() {
        if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
            this.windowScrolled = true;
        } else if ((this.windowScrolled && window.pageYOffset) || document.documentElement.scrollTop || document.body.scrollTop < 10) {
            this.windowScrolled = false;
        }
    }

    scrollToTop() {
        (function smoothscroll() {
            var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
            if (currentScroll > 0) {
                window.requestAnimationFrame(smoothscroll);
                window.scrollTo(0, currentScroll - currentScroll / 8);
            }
        })();
    }
}
