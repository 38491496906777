<div class="custom-file-upload">
    <div id="drop-zone" [class.over]="hoveringWithFiles" (drop)="dropHandler($event);" (dragover)="dragEnterHandler($event)" (dragenter)="dragEnterHandler($event)" (dragleave)="dragLeaveHandler($event)">
        <div class="drop-text">
            <fa-icon [icon]="faCloudArrowUp"></fa-icon> <br/>
            Drag & Drop to Upload Files<br/> OR <br/>
            <button
                mat-stroked-button
                color="primary"
                type="button"
                class=""
                (click)="onClickFileUpload($event)">
                Browse Files
            </button>
        </div>
        @if(uploadFileAcceptsArray().length > 0) {
            <em class="supports">
                Supports
                @for(extension of uploadFileAcceptsArray(); track extension){
                    <strong>{{ extension }} </strong> 
                }
            </em>
        }
        
        
    </div>

    <input
        type="file"
        [multiple]="multiple"
        #fileUploadField
        (change)="updateFiles(fileUploadField.files)"
        [accept]="uploadFileAccepts()"
        />
</div>
