import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthenticationService } from "src/app/services/authentication.service";
import { RoleService } from "src/app/shared/generated/api/role.service";
import { UserService } from "src/app/shared/generated/api/user.service";
import { RoleDto } from "src/app/shared/generated/model/role-dto";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { UserInviteDto } from "src/app/shared/generated/model/user-invite-dto";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { AsyncPipe } from "@angular/common";
import { EsaMaterialFormFieldComponent } from "esa-material-form-field";
import { FormsModule, NgForm } from "@angular/forms";
import { MatButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { MatTooltip } from "@angular/material/tooltip";

@Component({
    selector: "user-invite",
    templateUrl: "./user-invite.component.html",
    styleUrls: ["./user-invite.component.scss"],
    standalone: true,
    imports: [
        FormsModule,
        EsaMaterialFormFieldComponent,
        MatButton,
        MatTooltip,
        MatIcon,
        AsyncPipe,
    ],
})
export class UserInviteComponent implements OnInit, OnDestroy {
    private currentUser: UserDto;

    public model: UserInviteDto;
    public roles: Array<RoleDto>;
    public isLoadingSubmit: boolean = false;

    currentUserSubscription: Subscription;
    inviteUserSubscription: Subscription;
    roles$: Observable<RoleDto[]>;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private roleService: RoleService,
        private cdr: ChangeDetectorRef,
        private alertService: AlertService
    ) {}

    ngOnInit() {
        this.currentUserSubscription = this.authenticationService.getCurrentUser().subscribe((currentUser) => {
            this.currentUser = currentUser;
            this.cdr.markForCheck();
        });

        this.model = new UserInviteDto();

        this.roles$ = this.roleService.rolesGet().pipe(
            map((data) => {
                const roles = data.sort((a: RoleDto, b: RoleDto) => {
                    if (a.RoleName > b.RoleName) return 1;
                    if (a.RoleName < b.RoleName) return -1;
                    return 0;
                });
                return roles;
            })
        );
    }

    ngOnDestroy() {
        this.cdr.detach();
        this.currentUserSubscription?.unsubscribe();
        this.inviteUserSubscription?.unsubscribe();
    }

    cancel() {
        this.router.navigateByUrl("manage/users");
    }

    onSubmit(inviteUserForm: NgForm): void {
        this.isLoadingSubmit = true;

        this.inviteUserSubscription = this.userService.usersInvitePost(this.model).subscribe(
            (newUser) => {
                this.isLoadingSubmit = false;
                this.router.navigateByUrl("/manage/users/" + newUser.UserID).then((x) => {
                    this.alertService.pushAlert(new Alert("The user was successfully invited.", AlertContext.Success));
                    this.cdr.markForCheck();
                });
            },
            (error) => {
                this.isLoadingSubmit = false;
                this.cdr.detectChanges();
            }
        );
    }
}
