import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { AgGridAngular } from "ag-grid-angular";
import { ColDef } from "ag-grid-community";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CurrentProjectService } from "src/app/services/current-project/current-project.service";
import { CustomDropdownFilterComponent } from "src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component";
import { ProjectCommitmentService } from "src/app/shared/generated/api/project-commitment.service";
import { ProjectDto, CommitmentGridDto } from "src/app/shared/generated/model/models";
import { GridCommonService } from "src/app/shared/services/grid-common.service";
import { ClearGridFiltersButtonComponent } from "../../shared/components/clear-grid-filters-button/clear-grid-filters-button.component";
import { NgIf, AsyncPipe } from "@angular/common";
import { GridActionsComponent } from "src/app/shared/components/ag-grid/grid-actions/grid-actions.component";

@Component({
    selector: "project-related-commitments",
    templateUrl: "./project-related-commitments.component.html",
    styleUrls: ["./project-related-commitments.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        ClearGridFiltersButtonComponent,
        AgGridAngular,
        AsyncPipe,
        GridActionsComponent
    ],
})
export class ProjectRelatedCommitmentsComponent implements OnInit {
    @ViewChild("commitmentsGrid") commitmentsGrid: AgGridAngular;

    public project$: Observable<ProjectDto>;
    public project;
    public projectCommitments$: Observable<CommitmentGridDto[]>;

    public commitments: CommitmentGridDto[];

    public rowData = [];
    public columnDefs: ColDef[];
    

    public getCommitmentsRequest: any;

    constructor(
        private currentProjectService: CurrentProjectService,
        private projectCommitmentService: ProjectCommitmentService,
        private cdr: ChangeDetectorRef,
        private gridCommonService: GridCommonService
    ) {
        this.columnDefs = [
            {
                headerName: "ID",
                headerTooltip: "Commitment ID",
                valueGetter: function (params: any) {
                    return {
                        LinkValue: params.data.CommitmentID,
                        LinkDisplay: params.data.ClientCommitmentID,
                        ChildRoute: "summary-information",
                    };
                },
                cellRendererSelector: () => this.gridCommonService.renderLink("/commitments/"),
                filterValueGetter: function (params: any) {
                    return params.node.rowPinned ? null : params.data.ClientCommitmentID;
                },
                comparator: function (linkA, linkB, nodeA, nodeB, isDescending) {
                    let valueA = linkA.LinkDisplay.toLowerCase();
                    let valueB = linkB.LinkDisplay.toLowerCase();

                    return valueA.localeCompare(valueB, undefined, {
                        numeric: true,
                        sensitivity: "base",
                    });
                },
                type: "rightAligned",
                width: 170,
                sort: "asc",
            },
            {
                headerName: "Title",
                field: "Title",
                tooltipField: "Title",
                flex: 4,
            },
            {
                headerName: "Type",
                field: "CommitmentTypeName",
                tooltipField: "CommitmentTypeName",
                flex: 3,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "CommitmentTypeName",
                },
            },
            {
                headerName: "Resource Category",
                headerTooltip: "Resource Category",
                field: "ResourceCategoryName",
                tooltipField: "ResourceCategoryName",
                flex: 3,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "ResourceCategoryName",
                },
            },
            {
                headerName: "Status",
                headerTooltip: "Version Status",
                field: "CommitmentVersionStatusCommitmentVersionStatusDisplayName",
                flex: 1.5,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "CommitmentVersionStatusCommitmentVersionStatusDisplayName",
                },
            },
            {
                headerName: "Version",
                headerTooltip: "Version",
                field: "VersionNumber",
                filter: "agNumberColumnFilter",
                type: "numericColumn",
                flex: 1.5,
            },
            {
                headerName: "Latest Version?",
                headerTooltip: "Is Latest Version?",
                field: "IsLatestVersionYesNo",
                flex: 2,
            },
        ];

    }

    ngOnInit(): void {
        this.project$ = this.currentProjectService.getCurrentProject().pipe(
            map((p: ProjectDto) => {
                this.project = p;
                return p;
            })
        );
    }

    onCommitmentsGridReady(gridEvent: any) {
        this.refreshData();
    }

    refreshData() {
        this.commitmentsGrid.api.showLoadingOverlay();

        this.getCommitmentsRequest = this.projectCommitmentService.projectsProjectIDCommitmentsSimpleGet(this.project.ProjectID).subscribe((results) => {
            this.commitments = results;
            this.rowData = this.commitments;

            this.commitmentsGrid.api.hideOverlay();

            setTimeout(() => {
                this.setDefaultFilter();
            });

            this.cdr.markForCheck();
        });
    }

    setDefaultFilter() {
        this.commitmentsGrid.api.setFilterModel({
            
            IsLatestVersionYesNo: {
                filterType: "text",
                type: "equals",
                filter: "Yes",
            },
        });

        this.commitmentsGrid.api.onFilterChanged();
        this.cdr.markForCheck();
    }
}
