import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent } from "@angular/material/dialog";
import { NgIf, DatePipe, AsyncPipe } from "@angular/common";
import { TenantDto } from "src/app/shared/generated/model/tenant-dto";
import { Observable } from "rxjs";
import { SystemInfoService } from "src/app/shared/generated/api/system-info.service";
import { SystemInfoDto } from "src/app/shared/generated/model/system-info-dto";
import { TenantService } from "src/app/shared/services/tenant/tenant-service.service";

@Component({
    selector: "system-info-dialog",
    templateUrl: "./system-info-dialog.component.html",
    styleUrls: ["./system-info-dialog.component.scss"],
    standalone: true,
    imports: [
        MatDialogContent,
        NgIf,
        DatePipe,
        AsyncPipe
    ],
})
export class SystemInfoDialogComponent implements OnInit {
    public currentYear: number = new Date().getFullYear();
    public systemInfoDto$: Observable<SystemInfoDto>;
    public currentTenant$: Observable<TenantDto>;
    constructor(
        public dialogRef: MatDialogRef<SystemInfoDialogComponent>,
        private systemInfoService: SystemInfoService,
        private tenantService: TenantService
    ) {}

    ngOnInit(): void {
        this.systemInfoDto$ = this.systemInfoService.getSystemInfo();
        this.currentTenant$ = this.tenantService.currentTenant$.asObservable()
    }
}
