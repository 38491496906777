/**
 * Beacon.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type CRSType = 0 | 1 | 2;

export const CRSType = {
    NUMBER_0: 0 as CRSType,
    NUMBER_1: 1 as CRSType,
    NUMBER_2: 2 as CRSType
};
