import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { ChecklistService } from '../../generated/api/checklist.service';
import { AsyncPipe, NgFor } from '@angular/common';
import { FileDropUploadComponent } from '../file-drop-upload/file-drop-upload.component';
import { KvPairComponent } from '../kv-pair/kv-pair.component';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { FormGroupOf } from '../../forms/form-group-of';
import { EsaMaterialFormFieldComponent } from 'esa-material-form-field';
import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { MatInput, MatInputModule } from '@angular/material/input';
import { SimpleFileDisplayComponent } from '../simple-file-display/simple-file-display.component';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';
import { BeaconLoadingOverlayComponent } from '../beacon-loading-overlay/beacon-loading-overlay.component';
import { ConfirmService } from 'src/app/services/confirm.service';
import { ChecklistItemDto } from '../../generated/model/checklist-item-dto';
import { ChecklistItemStatusDto } from '../../generated/model/checklist-item-status-dto';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'checklist-item-status-display-and-upsert',
  standalone: true,
  imports: [AsyncPipe, BeaconLoadingOverlayComponent, MatInputModule, MatFormFieldModule, MatSelect, MatOption, LoadingSpinnerComponent, SimpleFileDisplayComponent, KvPairComponent, MatInput, MatFormField, NgFor, MatButton, MatIconButton, MatIcon, MatTooltip, EsaMaterialFormFieldComponent, FormsModule, ReactiveFormsModule],
  templateUrl: './checklist-item-status-display-and-upsert.component.html',
  styleUrl: './checklist-item-status-display-and-upsert.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChecklistItemStatusDisplayAndUpsertComponent implements OnInit {
  @Output() statusUpdated = new EventEmitter<ChecklistItemDto>();

  toggleEditMode(enableEdit: boolean) {
    this.isEditMode = enableEdit;
    if(!enableEdit){
      this.setForm(this.originalChecklistItem);
    }
  }

  public isLoading: boolean = false;
  public isEditMode: boolean = false;
  public _checklistItemID: string;
  public _componentID: string;
  public checklistItemStatuses$: Observable<ChecklistItemStatusDto[]>;
  public checklistItem$: Observable<ChecklistItemDto>;
  public originalChecklistItem: ChecklistItemDto;


  public formGroup = this.fb.group({
    ChecklistItemID: [''],
    ChecklistItemStatusID: [0],
  })

  @Input() set componentID(value: string) {
    if(value){
      this._componentID = value;      
    }
  }

  @Input() set checklistItemID(value: string) {
    if(value){
      this._checklistItemID = value;
    }
  }

  setForm(checklistItemDto: ChecklistItemDto) {
    this.formGroup.controls.ChecklistItemID.patchValue(checklistItemDto.ChecklistItemID);
    this.formGroup.controls.ChecklistItemStatusID.patchValue(checklistItemDto.Status.ChecklistItemStatusID);
  }

  constructor(
    private checklistService: ChecklistService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private confirmService: ConfirmService
  ) { }

  ngOnInit(): void {
    this.checklistItemStatuses$ = this.checklistService.checklistItemStatusesGet();
    this.checklistItem$ = this.checklistService.componentsComponentIDChecklistItemsChecklistItemIDGet(this._componentID, this._checklistItemID)
    .pipe(
      tap(x => {
        this.originalChecklistItem = JSON.parse(JSON.stringify(x));
        this.setForm(x);
      })
    );
  }

  save() {
    this.isLoading = true;
    this.cdr.markForCheck();
    this.checklistService.componentsComponentIDChecklistItemsChecklistItemIDPut(this._componentID, this._checklistItemID, Number(this.formGroup.controls.ChecklistItemStatusID.value)).subscribe({
      next: (result) => {
        this.checklistItemID = result.ChecklistItemID;
        this.formGroup.controls.ChecklistItemStatusID.patchValue(result.ChecklistItemStatusID);
        this.isLoading = false;
        this.isEditMode = false;

        this.checklistItem$ = this.checklistService.componentsComponentIDChecklistItemsChecklistItemIDGet(this._componentID, this._checklistItemID)
        .pipe(
          tap(x => {
            this.originalChecklistItem = JSON.parse(JSON.stringify(x));
            this.setForm(x);
            this.statusUpdated.emit(x);
          })
        );
      },
      error: (error) => {
        this.isLoading = false;
      },
      complete: () => {
        this.cdr.markForCheck();
      }
    });
  }
}
