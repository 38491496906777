import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { SourceUpsertDto } from "src/app/shared/generated/model/models";
import { SourceDto } from "src/app/shared/generated/model/source-dto";

@Injectable({
    providedIn: "root",
})
export class CurrentSourceDocumentService {
    currentSourceDocument$ = new BehaviorSubject<SourceDto>(null);

    constructor() {}

    setCurrentSourceDocument(sourceDocument: SourceDto): void {
        this.currentSourceDocument$.next(sourceDocument);
    }

    getCurrentSourceDocument(): Observable<SourceDto> {
        return this.currentSourceDocument$.asObservable();
    }

    createSourceUpsertDtoFromSourceDto(source: SourceDto|SourceUpsertDto): SourceUpsertDto {
        let sourceAsAny = source as any;
        let projectID = sourceAsAny?.ProjectID ?? sourceAsAny?.Project?.ProjectID;

        const sourceDto = new SourceUpsertDto({
            ProjectID: projectID ?? null,
            Name: source?.Name,
            DateOfLatestAmendment: source?.DateOfLatestAmendment ? new Date(source.DateOfLatestAmendment) : null,
            ReferenceNumber: source?.ReferenceNumber,
            ApprovingAgency: source?.ApprovingAgency,
            AgencyContact: source?.AgencyContact,
            AgencySignatory: source?.AgencySignatory,
            Description: source?.Description,
            AgreementTerms: source?.AgreementTerms,
        });

        return sourceDto;
    }
}
