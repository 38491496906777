<div class="dashboard">
    <aside class="sidebar">
        <ul class="sidebar-nav">
            <li class="sidebar-item">
                <a [routerLink]="['users']" routerLinkActive="active" class="sidebar-link">Users</a>
            </li>
            <li class="sidebar-item">
                <a [routerLink]="['roles']" routerLinkActive="active" class="sidebar-link">Roles</a>
            </li>
            <li class="sidebar-item">
                <a [routerLink]="['labels-and-definitions']" routerLinkActive="active" class="sidebar-link">Labels & Definitions</a>
            </li>
            <li class="sidebar-item">
                <a [routerLink]="['custom-pages']" routerLinkActive="active" class="sidebar-link">Custom Pages</a>
            </li>
        </ul>
    </aside>
    <!-- Note: removing the icons for now, there is a bigger side nav decision coming now and this way it matches the current side nav in the details pages. 5/10 JS -->

    <div class="main">
        <router-outlet #manageOutlet="outlet"></router-outlet>
    </div>
</div>
