import { Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { EsaMaterialFormFieldComponent } from 'esa-material-form-field';
import { ChecklistDto, ChecklistItemDto } from 'src/app/shared/generated/model/models';
import { PillComponent } from '../../pill/pill.component';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'selected-checklist-items-assign-dialog',
  standalone: true,
  imports: [MatDialogModule,FormsModule, ReactiveFormsModule, MatButtonModule, MatIcon, EsaMaterialFormFieldComponent, PillComponent, MatTooltip],
  templateUrl: './selected-checklist-items-assign-dialog.component.html',
  styleUrl: './selected-checklist-items-assign-dialog.component.scss'
})
export class SelectedChecklistItemsAssignDialogComponent implements OnInit {
  public static UnassignedOption = 'Unassigned';
  checklistFormGroup: FormGroup = new FormGroup({
    ChecklistID: new FormControl(null)
  });

  public options: ChecklistDto[];
  
  readonly dialogRef = inject(MatDialogRef<SelectedChecklistItemsAssignDialogComponent>);
  readonly data = inject<SelectedChecklistItemsAssignDialogData>(MAT_DIALOG_DATA);

  ngOnInit() {
    this.options = [...this.data.Checklists, { ChecklistID: SelectedChecklistItemsAssignDialogComponent.UnassignedOption, Name: 'Unassigned' }];
  }

  save() {
    this.dialogRef.close(this.checklistFormGroup.controls.ChecklistID.value);
  }
}

export interface SelectedChecklistItemsAssignDialogData {
  SelectedChecklistItems: ChecklistItemDto[];
  Checklists: ChecklistDto[];
}