<div class="detail-view">
    <div class="page-header">
        <div class="actions-bar flex-between">
            <h1 class="page-title">Custom Pages</h1>
        </div>
        <div class="copy copy-2">
            <custom-rich-text [customRichTextTypeID]="richTextTypeID"></custom-rich-text>
        </div>
    </div>

    <div class="table-responsive" style="height: 500px">
        <beacon-grid-actions [grid]="pageGrid" downloadFileName="pages" [downloadColIDsToExclude]="['0','5']">
            <button mat-flat-button *ngIf="canCreate" color="primary" aria-label="Add Custom Page" (click)="addPage()">
                <mat-icon>add</mat-icon> Custom Page
            </button>
        </beacon-grid-actions>
        <ag-grid-angular
            #pageGrid
            style="width: 100%; height: 100%"
            class="ag-theme-material"
            [rowData]="rowData"
            [columnDefs]="columnDefs"
            
            [suppressMenuHide]="true"
            (firstDataRendered)="onFirstDataRendered($event)"
            (gridReady)="onGridReady($event)">
        </ag-grid-angular>
    </div>
</div>
