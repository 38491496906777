import { Injectable } from "@angular/core";
import { LinkRendererComponent } from "../components/ag-grid/link-renderer/link-renderer.component";

@Injectable({
    providedIn: "root",
})
export class GridCommonService {

    renderLink(path: string) {
        return {
            component: LinkRendererComponent,
            params: { inRouterLink: `${path}` },
        };
    }
}