<form class="form grid-12" #sourceDocumentForm="ngForm">
    <div class="g-col-6">
        <esa-material-ff name="Name" [editMode]="editMode" [required]="true" type="text" [(ngModel)]="workingSourceUpsertDto.Name" [customLabel]="nameCustomLabel">
        </esa-material-ff>
        <ng-template #nameCustomLabel>
            <custom-form-label label="Name" fieldDefinitionType="Name"></custom-form-label>
        </ng-template>

        <esa-material-ff
            name="Project"
            [editMode]="editMode"
            [required]="true"
            type="dropdown"
            [options]="projects$ | async"
            optionLabel="Name"
            optionValue="ProjectID"
            [(ngModel)]="workingSourceUpsertDto.ProjectID"
            [customLabel]="projectCustomLabel"
            [customDisplay]="projectCustomDisplay">
        </esa-material-ff>
        <ng-template #projectCustomLabel>
            <custom-form-label label="Project"></custom-form-label>
        </ng-template>
        <ng-template #projectCustomDisplay>
            <a [routerLink]="['/projects/', inputSource?.Project?.ProjectID, 'summary-information']" *ngIf="inputSource && inputSource.Project">
                <p>{{ inputSource?.Project?.Name }}</p>
            </a>
        </ng-template>

        <esa-material-ff
            name="DateOfLatestAmendment"
            [editMode]="editMode"
            [required]="true"
            type="date"
            [(ngModel)]="workingSourceUpsertDto.DateOfLatestAmendment"
            [customLabel]="latestAmendmentCustomLabel">
        </esa-material-ff>
        <ng-template #latestAmendmentCustomLabel>
            <custom-form-label label="Date of Latest Amendment" fieldDefinitionType="DateOfLatestAmendment"></custom-form-label>
        </ng-template>

        <esa-material-ff
            name="ReferenceNumber"
            [editMode]="editMode"
            [required]="false"
            type="text"
            [(ngModel)]="workingSourceUpsertDto.ReferenceNumber"
            [customLabel]="refNumCustomLabel">
        </esa-material-ff>
        <ng-template #refNumCustomLabel>
            <custom-form-label label="Reference Number" fieldDefinitionType="ReferenceNumber"></custom-form-label>
        </ng-template>
    </div>

    <div class="g-col-6">
        <esa-material-ff
            name="ApprovingAgency"
            [editMode]="editMode"
            [required]="false"
            type="text"
            [(ngModel)]="workingSourceUpsertDto.ApprovingAgency"
            [customLabel]="approveCustomLabel">
        </esa-material-ff>
        <ng-template #approveCustomLabel>
            <custom-form-label label="Approving Agency" fieldDefinitionType="ApprovingAgency"></custom-form-label>
        </ng-template>

        <esa-material-ff
            name="AgencyContact"
            [editMode]="editMode"
            [required]="false"
            type="text"
            [(ngModel)]="workingSourceUpsertDto.AgencyContact"
            [customLabel]="contactCustomLabel">
        </esa-material-ff>
        <ng-template #contactCustomLabel>
            <custom-form-label label="Agency Contact" fieldDefinitionType="AgencyContact"></custom-form-label>
        </ng-template>

        <esa-material-ff
            name="AgencySignatory"
            [editMode]="editMode"
            [required]="false"
            type="text"
            [(ngModel)]="workingSourceUpsertDto.AgencySignatory"
            [customLabel]="approveAgencyCustomLabel">
        </esa-material-ff>
        <ng-template #approveAgencyCustomLabel>
            <custom-form-label label="Agency Signatory" fieldDefinitionType="AgencySignatory"></custom-form-label>
        </ng-template>
    </div>

    <div class="g-col-12">
        <div class="kv-pair" >
            <custom-form-label label="Description" fieldDefinitionType="Description"></custom-form-label>
            <div class="value" *ngIf="editMode">
                <div class="grid-12">
                    <div class="g-col-12">
                        <tinymce-editor #tinyMceEditor name="Description" [(content)]="workingSourceUpsertDto.Description"></tinymce-editor>
                    </div>
                </div>
            </div>
            <div
                [innerHTML]="workingSourceUpsertDto.Description === null ? '<p>-</p>' : (workingSourceUpsertDto.Description | bypassHtml)"
                class="value-display"
                *ngIf="!editMode"></div>
        </div>
    </div>

    <div class="g-col-12">
        <div class="kv-pair" >
            <custom-form-label label="Agreement Terms" fieldDefinitionType="AgreementTerms"></custom-form-label>
            <div class="value" *ngIf="editMode">
                <div class="grid-12">
                    <div class="g-col-12">
                        <tinymce-editor #tinyMceEditor name="AgreementTerms" [(content)]="workingSourceUpsertDto.AgreementTerms"></tinymce-editor>
                    </div>
                </div>
            </div>
            <div
                [innerHTML]="workingSourceUpsertDto.AgreementTerms === null ? '<p>-</p>' : (workingSourceUpsertDto.AgreementTerms | bypassHtml)"
                class="value-display"
                *ngIf="!editMode"></div>
        </div>
    </div>

    @if(editMode && showFileUpload) {
        <div class="g-col-12 mt-4">
            <div class="kv-pair">
                <esa-label label="Files"></esa-label>
                <div class="value file-upload">
                    <file-drop-upload [multiple]="true" (filesChanged)="uploadFilesChanged($event)" />
                    @if(files.length > 0) {
                        <div class="file-upload__files mt-3">
                            @for(file of files; track file) {
                                <simple-file-display [isNotYetUploaded]="true" [fileName]="file.name" [displayDelete]="true" (deleteFile)="deleteNewFile(file)"></simple-file-display>
                            }
                        </div>
                    }
                </div>
            </div>
            @if(isLoading) {
                <beacon-loading-overlay>
                    Saving Evidence of Compliance...
                    <loading-spinner color="accent" [diameter]="50" />
                </beacon-loading-overlay>
            }
        </div>
    } @else {
        <div class="g-col-12">    
            <source-document-file-list ></source-document-file-list>
        </div>
    }

    <div class="g-col-12 actions-bar" *ngIf="editMode" style="margin-top: 1rem">
        <span [matTooltip]="!sourceDocumentForm.form.valid ? 'You are missing requried fields.' : ''">
            <button
                mat-flat-button
                color="primary"
                style="margin-right: 1rem"
                (click)="saveForm(form)"
                [disabled]="!sourceDocumentForm.form.valid">
                <mat-icon>save</mat-icon> Save
            </button>
        </span>
        <button mat-button color="primary" (click)="cancelEditMode()"><mat-icon>cancel</mat-icon> Cancel</button>
    </div>
</form>
