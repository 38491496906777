import { Component, Input, OnInit } from "@angular/core";
import { FieldDefinitionComponent } from "../field-definition/field-definition.component";
import { NgIf } from "@angular/common";

@Component({
    selector: "custom-form-label",
    templateUrl: "./custom-form-label.component.html",
    styleUrls: ["./custom-form-label.component.scss"],
    standalone: true,
    imports: [NgIf, FieldDefinitionComponent],
})
export class CustomFormLabelComponent implements OnInit {
    @Input() label!: string;
    @Input() fieldDefinitionType!: string;
    @Input() popperOptions!: any;

    constructor() {}

    ngOnInit(): void {}
}
