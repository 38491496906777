import { NgIf, AsyncPipe } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { FormGroup, FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialogContent, MatDialogActions, MatDialogClose, MatDialogTitle, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RouterLink } from '@angular/router';
import { EsaValueDisplayComponent, EsaMaterialFormFieldComponent } from 'esa-material-form-field';
import { BypassHtmlPipe } from 'src/app/shared/pipes/bypassHtml.pipe';
import { CustomFormLabelComponent } from '../../custom-form-label/custom-form-label.component';
import { ChecklistDto, ChecklistUpsertDto } from 'src/app/shared/generated/model/models';
import { ComponentChecklistFormComponent } from 'src/app/pages/component-checklist-form/component-checklist-form.component';
import { ChecklistService } from 'src/app/shared/generated/api/checklist.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'checklist-upsert-dialog',
  standalone: true,
  imports: [ComponentChecklistFormComponent, MatTooltip, MatDialogContent, MatIcon, MatDialogActions, MatDialogClose, FormsModule, MatDialogTitle, NgIf, AsyncPipe, MatButton, EsaValueDisplayComponent, EsaMaterialFormFieldComponent, CustomFormLabelComponent, RouterLink, BypassHtmlPipe],
  templateUrl: './checklist-upsert-dialog.component.html',
  styleUrl: './checklist-upsert-dialog.component.scss'
})
export class ChecklistUpsertDialogComponent implements OnInit {
  readonly dialogRef = inject(MatDialogRef<ChecklistUpsertDialogComponent>);
  readonly data = inject<ChecklistUpsertDialogData>(MAT_DIALOG_DATA);
  public isLoading = false;
  public model: FormGroup<any>;
  public createMode = true;

  constructor(private checklistService: ChecklistService, private alertService: AlertService) {}

  ngOnInit(): void {
    if(this.data.ChecklistID) {
      this.createMode = false;
    }
  }

  save() {
    this.isLoading = true;
    let formDto = this.model.value as ChecklistUpsertDto;
    this.checklistService.componentsComponentIDChecklistsPut(this.data.ComponentID, formDto).subscribe({
      next: (response) => {
        this.alertService.pushAlert( new Alert(`Checklist ${formDto.Name} created successfully`, AlertContext.Success));
        this.dialogRef.close({Created: this.createMode, Checklist: response} as ChecklistUpsertDialogResponse);
        this.isLoading = false;
      },
      error: (err) => {
        console.error(err);
        this.isLoading = false;
      }
    });
    
  }

  cancelEdit() {
    this.dialogRef.close();
  }

  valueChanged(event: FormGroup<any>) {
    this.model = event;
  }
}

export interface ChecklistUpsertDialogResponse {
  Created: boolean;
  Checklist: ChecklistDto;
}

export interface ChecklistUpsertDialogData {
  ComponentID: string;
  ChecklistID: string;
}