<div class="detail-view" *ngIf="component$ | async as componentForView">
    <div class="actions-bar">
        <button mat-icon-button *ngIf="canEdit()" color="primary" (click)="enableEditMode()"><mat-icon>edit</mat-icon> </button>
    </div>

    <component-form
        #updateComponentForm
        [component]="componentForView"
        [editMode]="editMode"
        (formSubmitted)="save()"
        (cancelEditModeChange)="cancelEdit()"></component-form>

    <beacon-back-to-top></beacon-back-to-top>
</div>
