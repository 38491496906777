
@if(isLoading) {
    <loading-spinner [strokeWidth]="1" [diameter]="20" />
}
<div *ngIf="!isLoading" style="margin-top: 1rem">
    <ng-container *ngIf="!isEditing">
        <div *ngIf="showEditButton()" class="customRichTextContent" style="position: relative; min-height: 60px">
            <div class="hoverEditButton">
                <div style="transform: translate3d(-10px, 20px, 0)">
                    <button color="primary" mat-button (click)="enterEdit()"><mat-icon>edit</mat-icon></button>
                </div>
            </div>
            <div *ngIf="!isEmptyContent" [innerHtml]="customRichTextContent"></div>

            <div *ngIf="isEmptyContent" class="hoverEmptySpace"></div>
        </div>
        <div *ngIf="!showEditButton()" [innerHtml]="customRichTextContent"></div>
    </ng-container>

    <div *ngIf="isEditing">
        <form>
            <tinymce-editor #tinyMceEditor name="editor" [(content)]="editedContent"></tinymce-editor>
        </form>

        <div class="actions-bar" style="margin-top: 1rem">
            <button mat-flat-button color="primary" (click)="saveEdit()" style="margin-right: 1rem">
                <mat-icon>save</mat-icon> Save
            </button>
            <button mat-button color="primary" (click)="cancelEdit()">
                <mat-icon>cancel</mat-icon> Cancel
            </button>
        </div>
    </div>
</div>
