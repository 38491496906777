import { Pipe, PipeTransform } from '@angular/core';
import { ChecklistItemDto } from '../generated/model/checklist-item-dto';
import { ComplianceRequirementDto } from '../generated/model/compliance-requirement-dto';
import { ScopeDto } from '../generated/model/scope-dto';
import { FrequencyDto } from '../generated/model/frequency-dto';
import { ComplianceRequirementTypeDto } from '../generated/model/compliance-requirement-type-dto';

@Pipe({
  name: 'checklistItemVisible',
  standalone: true
})
export class ChecklistItemFilterVisiblePipe implements PipeTransform {

  transform(value: ChecklistItemDto, args: {
    phase: string[];
    scope: string[];
    frequency: string[];
    complianceRequirementType: string[];
    resourceCategory: string[];
    status: number[];
    search: string;
  }): boolean {
    if(args.search) {
      let joinedString = '';
      joinedString += value.ComplianceRequirement.Name + '|';
      joinedString += value.ComplianceRequirement.Scope?.Name ?? '' + '|';
      joinedString += value.ComplianceRequirement.Frequency?.Name ?? '' + '|';
      joinedString += value.ComplianceRequirement.ComplianceRequirementType?.Name ?? '' + '|';
      joinedString += value.ComplianceRequirement.Phase?.Name ?? '' + '|';
      joinedString += value.ComplianceRequirement.Commitment?.ResourceCategory?.Name ?? '' + '|';
      joinedString += value.Status?.Name ?? '' + '|';
      joinedString += value.ComplianceRequirement.Commitment?.ClientCommitmentID ?? '' + '|';
      
      if(!joinedString.toLowerCase().includes(args.search.toLowerCase())) {
        return false;
      }
    }

    if(args.scope.length > 0 && !args.scope.includes(value.ComplianceRequirement.Scope?.ScopeID)) {
      return false;
    }
    if(args.frequency.length > 0 && !args.frequency.includes(value.ComplianceRequirement.Frequency?.FrequencyID)) {
      return false;
    }
    if(args.complianceRequirementType.length > 0 && !args.complianceRequirementType.includes(value.ComplianceRequirement.ComplianceRequirementType?.ComplianceRequirementTypeID)) {
      return false;
    }
    if(args.phase.length > 0 && !args.phase.includes(value.ComplianceRequirement.Phase?.PhaseID)) {
      return false;
    }
    if(args.resourceCategory.length > 0 && !args.resourceCategory.includes(value.ComplianceRequirement.Commitment?.ResourceCategory?.ResourceCategoryID)) {
      return false;
    }
    if(args.status.length > 0 && !args.status.includes(value.Status?.ChecklistItemStatusID)) {
      return false;
    }

    return true;
  }

}
