import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild, Input, OnDestroy } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { CurrentCommitmentService } from "src/app/services/current-commitment/current-commitment.service";
import { EditViewEventService } from "src/app/services/edit-view-event.service";
import { CommitmentService } from "src/app/shared/generated/api/commitment.service";
import { CommitmentDto } from "src/app/shared/generated/model/commitment-dto";
import { CommitmentUpsertDto } from "src/app/shared/generated/model/commitment-upsert-dto";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { CommitmentFormComponent } from "../commitment-form/commitment-form.component";
import { AuthenticationService } from "src/app/services/authentication.service";
import { BackToTopComponent } from "../../shared/components/back-to-top/back-to-top.component";
import { EsaMaterialButtonComponent } from "esa-material-form-field";
import { NgIf, AsyncPipe } from "@angular/common";
import { MatButton, MatIconButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { CommitmentFileService } from "src/app/shared/generated/api/commitment-file.service";

@Component({
    selector: "commitment-summary-information",
    templateUrl: "./commitment-summary-information.component.html",
    styleUrls: ["./commitment-summary-information.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        CommitmentFormComponent,
        MatButton,
        MatIcon,
        MatIconButton,
        BackToTopComponent,
        AsyncPipe,
    ],
})
export class CommitmentSummaryInformationComponent implements OnInit, OnDestroy {
    @ViewChild("updateCommitmentForm", { read: CommitmentFormComponent }) form: CommitmentFormComponent;

    public commitment$: Observable<any>;
    
    public commitment: CommitmentDto;
    public currentUser: UserDto;
    public editMode = false;
    public commitmentUpsertDto: CommitmentUpsertDto;

    user: Subscription;
    commitmentFileSubscription: Subscription;
    commitmentPutSubscription: Subscription;

    constructor(
        private commitmentService: CommitmentService,
        private currentCommitmentService: CurrentCommitmentService,
        private authenticationService: AuthenticationService,
        private editViewEventService: EditViewEventService,
        private alertService: AlertService,
        private cdr: ChangeDetectorRef,
        private commitmentFileService: CommitmentFileService,
    ) {}

    ngOnInit(): void {
        this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.cdr.markForCheck();
        });

        this.commitment$ = this.currentCommitmentService.getCurrentCommitment().pipe(
            map((data) => {
                this.commitment = data;
                this.commitmentUpsertDto = this.currentCommitmentService.createCommitmentDto(this.commitment);
                this.cdr.markForCheck();
                return data;
            })
        );
    }

    ngOnDestroy(): void {
        this.user?.unsubscribe();
        this.commitmentFileSubscription?.unsubscribe();
    }

    refreshCommitment(result: any) {
        this.commitment = result;
        this.editMode = false;
        this.currentCommitmentService.setCurrentCommitment(this.commitment);
        this.editViewEventService.editButtonClicked.next(this.editMode);
        this.cdr.detectChanges();
    }

    // save() {
    //     this.commitment$ = this.commitmentService.commitmentsCommitmentIDPut(this.commitment.CommitmentID, this.form.commitmentUpsertDto).pipe(
    //         map((result) => {
    //             this.alertService.pushAlert(new Alert("The commitment was successfully updated.", AlertContext.Success), 5000);
    //             this.refreshCommitment(result);
    //             return result;
    //         })
    //     );
    // }

    save() {
        this.commitmentPutSubscription = this.commitmentService.commitmentsCommitmentIDPut(this.commitment.CommitmentID, this.form.commitmentUpsertDto).subscribe((result) => {
            if(this.form.files && this.form.files.length > 0) {
                this.commitmentFileSubscription = this.commitmentFileService.commitmentsCommitmentIDFilesPost(result.CommitmentID, this.form.files).subscribe({
                    next: () => {
                        this.alertService.pushAlert(new Alert("The Commitment was successfully updated.", AlertContext.Success), 5000);
                        this.refreshCommitment(result);
                        return result;
                    },
                    error: () => {
                    },
                    complete: () => {
                    }
                });
            } else {
                this.alertService.pushAlert(new Alert("The Commitment was successfully updated.", AlertContext.Success), 5000);
                this.refreshCommitment(result);
            }
            return result;
        });
    }

    canEdit(): boolean {
        return this.currentCommitmentService.canEditCurrentCommitment(this?.currentUser, this.commitment) && !this.editMode;
    }

    enableEditMode() {
        this.editMode = true;
        this.editViewEventService.editButtonClicked.next(this.editMode);
        this.cdr.markForCheck();
    }

    cancelEdit() {
        this.editMode = false;
        this.editViewEventService.editButtonClicked.next(this.editMode);
        this.cdr.markForCheck();
    }

    canExit() {
        if (this.editMode) {
            return JSON.stringify(this.commitmentUpsertDto) === JSON.stringify(this.form.commitmentUpsertDto);
        } else {
            return true;
        }
    }
}
