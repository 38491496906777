export class Permission {
    public static Options: any[] = [
        {
            Name: "None",
            Value: {
                CanRead: false,
                CanUpdate: false,
                CanCreate: false,
                CanDelete: false,
            },
            MaskValue: 0,
        },
        {
            Name: "Read Only",
            Value: {
                CanRead: true,
                CanUpdate: false,
                CanCreate: false,
                CanDelete: false,
            },
            MaskValue: 1,
        },
        {
            Name: "Read and Update",
            Value: {
                CanRead: true,
                CanUpdate: true,
                CanCreate: false,
                CanDelete: false,
            },
            MaskValue: 3,
        },
        {
            Name: "Read, Update and Create",
            Value: {
                CanRead: true,
                CanUpdate: true,
                CanCreate: true,
                CanDelete: false,
            },
            MaskValue: 7,
        },
        {
            Name: "Read, Update and Delete",
            Value: {
                CanRead: true,
                CanUpdate: true,
                CanCreate: false,
                CanDelete: true,
            },
            MaskValue: 11,
        },
        {
            Name: "Read, Update, Create and Delete",
            Value: {
                CanRead: true,
                CanUpdate: true,
                CanCreate: true,
                CanDelete: true,
            },
            MaskValue: 15,
        },
    ];
}
