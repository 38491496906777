import { AsyncPipe, NgClass, NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormsModule, NgForm, ReactiveFormsModule } from "@angular/forms";
import { MatButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { MatTooltip } from "@angular/material/tooltip";
import { RouterLink } from "@angular/router";
import { EsaMaterialFormFieldComponent } from "esa-material-form-field";
import { Observable } from "rxjs";
import { CurrentComponentService } from "src/app/services/current-component/current-component.service";
import { CustomFormLabelComponent } from "src/app/shared/components/custom-form-label/custom-form-label.component";
import { TinymceEditorComponent } from "src/app/shared/components/tinymce-editor/tinymce-editor.component";
import { ProjectService } from "src/app/shared/generated/api/project.service";
import { ComponentDto } from "src/app/shared/generated/model/component-dto";
import { ComponentUpsertDto } from "src/app/shared/generated/model/component-upsert-dto";
import { ProjectDto } from "src/app/shared/generated/model/project-dto";
import { BypassHtmlPipe } from "src/app/shared/pipes/bypassHtml.pipe";

@Component({
    selector: "component-form",
    templateUrl: "./component-form.component.html",
    styleUrls: ["./component-form.component.scss"],
    standalone: true,
    imports: [CustomFormLabelComponent, NgClass, EsaMaterialFormFieldComponent, FormsModule, NgIf, AsyncPipe, MatButton, MatIcon, TinymceEditorComponent, BypassHtmlPipe, RouterLink, MatTooltip],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComponentFormComponent implements OnInit {
    @ViewChild("componentForm", { read: NgForm }) form: NgForm;

    @Output() formSubmitted = new EventEmitter<any>();
    @Output() cancelEditModeChange = new EventEmitter<boolean>();

    @Input() component: ComponentDto;
    @Input() editMode: boolean;

    public projects$: Observable<ProjectDto[]>;

    //MK 5/9/2024 -- I don't expect these to change frequently, so I am just hardcoding them here instead of creating endpoints.
    public componentStatuses = [
        { ComponentStatusID: 5, Name: "Not Started" },
        { ComponentStatusID: 1, Name: "Active" },
        { ComponentStatusID: 3, Name: "Paused" },
        { ComponentStatusID: 2, Name: "Complete" },
        { ComponentStatusID: 4, Name: "Cancelled" },
    ];

    componentUpsertDto: ComponentUpsertDto;

    constructor(private projectService: ProjectService, private currentComponentService: CurrentComponentService) {}

    ngOnInit(): void {
        if (this.component) {
            this.setForm();
        }

        this.projects$ = this.projectService.projectsGet();
    }

    setForm() {
        this.componentUpsertDto = this.currentComponentService.createComponentDto(this.component);
    }

    saveForm(form: NgForm) {
        this.formSubmitted.emit(form);
    }

    cancelEditMode() {
        this.setForm();
        this.cancelEditModeChange.emit(true);
    }
}
