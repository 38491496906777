import { Component, EventEmitter, OnInit, Output, Input, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { NgForm, FormsModule } from "@angular/forms";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { CurrentSourceDocumentService } from "src/app/services/current-source-document/current-source-document.service";
import { TinymceEditorComponent } from "src/app/shared/components/tinymce-editor/tinymce-editor.component";
import { ProjectService } from "src/app/shared/generated/api/project.service";
import { ProjectDto, SourceUpsertDto } from "src/app/shared/generated/model/models";
import { BypassHtmlPipe } from "../../shared/pipes/bypassHtml.pipe";
import { TinymceEditorComponent as TinymceEditorComponent_1 } from "../../shared/components/tinymce-editor/tinymce-editor.component";
import { RouterLink } from "@angular/router";
import { NgIf, NgClass, AsyncPipe } from "@angular/common";
import { CustomFormLabelComponent } from "../../shared/components/custom-form-label/custom-form-label.component";
import { EsaMaterialFormFieldComponent, EsaMaterialButtonComponent, EsaLabelComponent } from "esa-material-form-field";
import { MatButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { MatTooltip } from "@angular/material/tooltip";
import { FileDropUploadComponent } from "../../shared/components/file-drop-upload/file-drop-upload.component";
import { SimpleFileDisplayComponent } from "src/app/shared/components/simple-file-display/simple-file-display.component";
import { BeaconLoadingOverlayComponent } from "src/app/shared/components/beacon-loading-overlay/beacon-loading-overlay.component";
import { LoadingSpinnerComponent } from "src/app/shared/components/loading-spinner/loading-spinner.component";
import { SourceDocumentFileListComponent } from "../source-document-file-list/source-document-file-list.component";

@Component({
    selector: "source-document-form",
    templateUrl: "./source-document-form.component.html",
    styleUrls: ["./source-document-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
    FormsModule,
    EsaMaterialFormFieldComponent,
    CustomFormLabelComponent,
    NgIf,
    RouterLink,
    NgClass,
    TinymceEditorComponent_1,
    AsyncPipe,
    BypassHtmlPipe,
    MatButton,
    MatIcon,
    MatTooltip,
    FileDropUploadComponent,
    SimpleFileDisplayComponent,
    EsaLabelComponent,
    BeaconLoadingOverlayComponent,
    LoadingSpinnerComponent,
    SourceDocumentFileListComponent,
],
})
export class SourceDocumentFormComponent implements OnInit {


    @ViewChild("sourceDocumentForm", { read: NgForm }) form: NgForm;
    @ViewChild("tinyMceEditor") tinyMceEditor: TinymceEditorComponent;

    @Output() formSubmitted = new EventEmitter<any>();
    @Output() cancelEditModeChange = new EventEmitter<boolean>();

    @Input() inputSource: any; //MK 4/1/2024: This can either be a SourceUpsertDto or a SourceDto.
    @Input() editMode: boolean;
    @Input() showFileUpload: boolean = false;

    public workingSourceUpsertDto: SourceUpsertDto;
    public files: File[] = [];

    public projects$: Observable<ProjectDto[]>;
    public isLoading: boolean = false;

    constructor(private currentSourceService: CurrentSourceDocumentService, private projectService: ProjectService, private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.projects$ = this.projectService.projectsGet().pipe(
            tap((projects: ProjectDto[]) => {
                if (projects.length == 1) {
                    this.inputSource.ProjectID = projects[0].ProjectID;
                    this.workingSourceUpsertDto = this.currentSourceService.createSourceUpsertDtoFromSourceDto(this.inputSource);
                }
            })
        );

        this.setForm();
    }

    setForm() {
        this.workingSourceUpsertDto = this.currentSourceService.createSourceUpsertDtoFromSourceDto(this.inputSource);
    }

    saveForm(form: NgForm) {
        this.isLoading = true;
        this.formSubmitted.emit(form);
        this.isLoading = false;
    }

    cancelEditMode() {
        this.setForm();
        this.cancelEditModeChange.emit(true);
    }

    uploadFilesChanged(files: File[]) {
        const existingFileNames = this.files.map((file) => file.name);
        if (existingFileNames.length > 0) 
        {
            const filesToAdd = files.filter((file) => !existingFileNames.includes(file.name));
            this.files.push(...filesToAdd);
        } 
        else 
        {
            this.files.push(...files);
        }
    }

    deleteNewFile(file: File) {
        // find the index of the file and remove it from the array
        const index = this.files.indexOf(file);
        this.files.splice(index, 1);
    }
}
