<div class="site">
    <div class="header-wrapper">
        <ng-container *ngIf="source">
            <div class="source-document-nav">
                <div matTooltip="Save or cancel edits to access options." [matTooltipDisabled]="!editMode">
                    <button mat-raised-button #profileMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" color="primary" [disabled]="editMode">
                        <mat-icon>expand_more</mat-icon>&nbsp;Options
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="delete()" *ngIf="canDelete()">
                            <mat-icon>delete</mat-icon>
                            <span>Delete</span>
                        </button>
                    </mat-menu>
                </div>
            </div>

            <div class="source-document-title">
                <h3 class="source-document-title-item">SOURCE DOCUMENT</h3>
                <h1 class="source-document-title-item">{{ source?.Name }}</h1>
                <h4 class="source-document-title-item">{{ source?.DateOfLatestAmendment | date : "shortDate" }}</h4>
            </div>
        </ng-container>
    </div>

    <sidebar-nav [sideNavItems]="sourceSideNav"></sidebar-nav>
</div>
