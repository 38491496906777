import { Injectable } from "@angular/core";
import { TenantDto } from "../../generated/model/tenant-dto";
import { BehaviorSubject } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { ArrayHelper } from "./array-helper";
import { RouteHelpers } from "../router-helper/router-helper";

@Injectable({
    providedIn: "root",
})
export class TenantService {
    public excludedServiceNames: string[] = ["SystemInfoService", "UserClaimsService"];

    public commitmentLibraryModuleID = 2;
    public monitoringDashboardModuleID = 3;
    public scheduleModuleID = 4;
    public complianceTrackingModuleID = 5;

    public currentTenant$ = new BehaviorSubject<TenantDto>(null);
    private currentTenant: TenantDto;

    public allowedTenants$ = new BehaviorSubject<TenantDto[]>([]);
    private allowedTenants: TenantDto[] = [];
    private onFirstTenantSet = true;

    constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

    public getCurrentTenant(): TenantDto {
        return this.currentTenant;
    }

    public setCurrentTenant(tenant: TenantDto) {
        let needToSetTenant = this.currentTenant == null || this.currentTenant.TenantID != tenant.TenantID;
        if (needToSetTenant) {
            this.currentTenant = tenant;
            this.currentTenant$.next(tenant);

            localStorage.setItem("currentTenantID", tenant.TenantID.toString());
            if (!this.onFirstTenantSet) {
                this.navigateAfterTenantSwitch();
            } else {
                this.onFirstTenantSet = false;
            }
        }
    }

    public navigateAfterTenantSwitch() {
        const currentRoute = RouteHelpers.getCurrentRouteFromActivatedRouteSnapshot(this.activatedRoute.snapshot);

        const paramKeys = Object.keys(currentRoute.params);
        // Redirect to home if the current route has "params" (e.g.: Id's of entities that are specific to the previous tenant)
        if (paramKeys.length > 0) {
            this.router.navigate(["/"]);
            return;
        }
        const pathFromRoute = currentRoute.pathFromRoot.flatMap(r => r.url.map(segment => segment.path));
        this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
            this.router.navigate(pathFromRoute, { queryParams: currentRoute.queryParams });
        });
    }

    public getAllowedTenants(): TenantDto[] {
        return this.allowedTenants;
    }

    public setAllowedTenants(tenants: TenantDto[]) {
        let needToSetTenants = !ArrayHelper.areArraysEqual(this.allowedTenants, tenants, (a, b) => a.TenantID === b.TenantID);
        if (needToSetTenants) {
            this.allowedTenants = tenants;
            this.allowedTenants$.next(tenants);

            if (tenants.length == 1) {
                this.setCurrentTenant(tenants[0]);
            } else {
                let currentTenantID = localStorage.getItem("currentTenantID");
                if (currentTenantID) {
                    let currentTenant = tenants.find((t) => t.TenantID == parseInt(currentTenantID));
                    if (currentTenant) {
                        this.setCurrentTenant(currentTenant);
                    }
                } else {
                    this.setCurrentTenant(tenants[0]);
                }
            }
        }
    }

    public getTenantHostName(basePath: string) {
        if (this.currentTenant && this.currentTenant.SubDomain) {
            let schemalessBasePath = basePath.replace("https://", "").replace("http://", "");
            return `https://${this.currentTenant.SubDomain}.${schemalessBasePath}`;
        } else {
            return basePath;
        }
    }
}
