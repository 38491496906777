import { LogLevel, Configuration, BrowserCacheLocation } from "@azure/msal-browser";

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
export const b2cPolicies = {
    names: {
        signUpSignIn: "B2C_1_BeaconFlow",
        editProfile: "B2C_1_BeaconEditProfileFlow",
    },
    authorities: {
        signUpSignIn: {
            authority: "https://identitystoresitka.b2clogin.com/identitystoresitka.onmicrosoft.com/B2C_1_BeaconFlow",
        },
        editProfile: {
            authority: "https://identitystoresitka.b2clogin.com/identitystoresitka.onmicrosoft.com/B2C_1_BeaconEditProfileFlow",
        },
    },
    authorityDomain: "identitystoresitka.b2clogin.com",
    apiScopes: ["https://identitystoresitka.onmicrosoft.com/2230c2fb-b7ce-43e8-b687-b6038468089e/User.Access"],
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */

export function loggerCallback(logLevel: LogLevel, message: string) {
    // console.log(message);
}

export const msalConfig: Configuration = {
    auth: {
        //One ClientID per applicaiton, create this in the Azure B2C Page and update her for new apps.
        clientId: "ccad460d-99ea-40d7-9d49-ffc420b513ba", // This is the ONLY mandatory field that you need to supply.
        authority: b2cPolicies.authorities.signUpSignIn.authority, // Defaults to "https://login.microsoftonline.com/common"
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: "/login-redirect", // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
        postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback,
            logLevel: LogLevel.Verbose,
            piiLoggingEnabled: false,
        },
    },
};

/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "login_hint" property.
 */
export const silentRequest = {
    scopes: ["openid", "profile"],
    loginHint: "example@domain.net",
};
