import { Component, OnInit, ViewContainerRef, OnDestroy } from "@angular/core";
import { NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterOutlet } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { BusyService } from "src/app/shared/services";
import { Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { HeaderNavComponent } from "../../../shared/components/header-nav/header-nav.component";

@Component({
    selector: "app-home-index",
    templateUrl: "./home-index.component.html",
    styleUrls: ["./home-index.component.scss"],
    standalone: true,
    imports: [HeaderNavComponent, RouterOutlet],
})
export class HomeIndexComponent implements OnInit, OnDestroy {
    routerSubscription: Subscription;

    constructor(
        private router: Router,
        private busyService: BusyService,
        private titleService: Title,
        public viewRef: ViewContainerRef
    ) {}

    ngOnInit(): void {
        this.routerSubscription = this.router.events.subscribe((event: any) => {
            if (event instanceof RouteConfigLoadStart) {
                // lazy loaded route started
                this.busyService.setBusy(true);
            } else if (event instanceof RouteConfigLoadEnd) {
                // lazy loaded route ended
                this.busyService.setBusy(false);
            } else if (event instanceof NavigationEnd) {
                window.scrollTo(0, 0);
            }
        });

        this.titleService.setTitle(environment.platformLongName);
    }

    ngOnDestroy(): void {
        this.routerSubscription?.unsubscribe();
    }
}
