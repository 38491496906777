import { Component, OnInit } from "@angular/core";
import { TestSchedulingService } from "src/app/shared/generated/api/test-scheduling.service";

@Component({
    selector: "test-scheduling-page",
    templateUrl: "./test-scheduling-page.component.html",
    styleUrls: ["./test-scheduling-page.component.scss"],
    standalone: true,
})
export class TestSchedulingPageComponent implements OnInit {
    serverMessage: string = "loading...";
    constructor(private testSchedulingService: TestSchedulingService) {
        this.testSchedulingService.testSchedulingEndpointGet().subscribe((result) => {  
            this.serverMessage = result.ServerMessage;
        });
    }

    ngOnInit(): void {}
}
