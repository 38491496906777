<header class="site-header">
    <div class="top-nav">
        <div class="top-nav-left">
            <div class="top-nav-left-bottom-wrapper">
                <ul>
                    <li class="nav-item" *ngIf="(currentUser$ | async | withPermission: {permission: PermissionEnum.UserRights, rights: RightsEnum.Read}) || (currentUser$ | async | withPermission: {permission: PermissionEnum.FieldDefinitionRights, rights: RightsEnum.Read}) ">
                        <a routerLink="/manage/users" class="nav-link" role="button" routerLinkActive="active"> Admin </a>
                    </li>
                </ul>
    
                <ng-container *ngIf="allowedTenants$ | async as allowedTenants">
                    <div class="nav-item dropdown" *ngIf="currentTenant$ | async as currentTenant">
                        <a class="nav-link dropdown-toggle" [matMenuTriggerFor]="menu" >
                            {{ currentTenant.Name }}
                        </a>
                        <mat-menu #menu>
                            <button mat-menu-item
                                *ngFor="let tenant of allowedTenants"
                                href="javascript:void(0);"
                                (click)="changeTenant(tenant)"
                                [ngClass]="{ active: tenant.TenantID == currentTenant?.TenantID }"
                                >
                                {{ tenant.Name }}
                            </button>
                            
                        </mat-menu>
                    </div>
                </ng-container>
            </div>
            

            <ul class="module-list" *ngIf="allowedModules$ | async as allowedModules">
                <li
                    *ngFor="let allowedModule of allowedModules"
                    [ngClass]="{ active: allowedModule.Module.ModuleID == (currentModule$ | async)?.Module?.ModuleID }"
                   >
                    <a class="nav-link" role="button" [routerLink]="['/', allowedModule.LandingPage]" routerLinkActive="allowedModule.Module.ModuleID == currentModule?.Module?.ModuleID">
                        {{ allowedModule.Module.Name }}
                    </a>
                </li>
            </ul>
        </div>

        <div class="top-nav-middle">
            <div *ngIf="environment.staging || environment.dev" class="qa-warning">
                <mat-icon>warning</mat-icon>
                {{ environment.staging ? "QA Site: Testing Environment!" : "Development Site: Testing Environment!" }}
            </div>
        </div>

        <div class="top-nav-right" >
            <ul class="site-nav__links site-nav__utilities" #testRef>
                <li class="nav-item" routerLinkActive="active" *ngIf="!(currentUser$ | async)">
                    <a href="javascript:void(0);" (click)="login()" routerLinkActive="active" class="nav-link">Sign In</a>
                </li>

                <div class="nav-item dropdown welcomeUser" routerLinkActive="active" *ngIf="currentUser$ | async as currentUser">
                    <a class="nav-link dropdown-toggle" [matMenuTriggerFor]="viewMenu" >
                        <span class="username">
                            {{ ((currentUser$ | async) | userIsImpersonated) ? "Impersonating" : "Welcome" }} {{ currentUser?.FirstName + " " + currentUser?.LastName }}
                        </span>
                    </a>
                    <mat-menu #viewMenu>
                        <button mat-menu-item
                            routerLink="/help">
                            Get Help
                        </button>
                        <button mat-menu-item
                            (click)="editProfile()">
                            Edit Profile
                        </button>
                        <button mat-menu-item *ngIf="currentTenant$ | async as currentTenant"
                            (click)="viewSystemInfo(currentTenant)">
                            View System Info
                        </button>
                        <button mat-menu-item
                            (click)="logout()">
                            {{ ((currentUser$ | async) | userIsImpersonated) ? "Stop Impersonating" : "Sign Out" }}
                        </button>
                    </mat-menu>
                </div>
            </ul>
        </div>
    </div>

    <nav class="site-nav">
        <a routerLink="" routerLinkActive="active" class="site-logo nav-link" *ngIf="(currentUser$ | async)">
            <mat-icon>home</mat-icon>
        </a>

        <button class="hamburger-btn" [dropdownToggle]="mobileMenuToggle" type="button" aria-controls="hamburger-btn" aria-expanded="false">
            <span class="sr-only">Open main menu</span>
            <svg aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path
                    fill-rule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clip-rule="evenodd"></path>
            </svg>
        </button>

        <div class="site-nav__wrapper" #mobileMenuToggle>
            <a routerLink="/commitments" routerLinkActive="active" class="site-logo-mobile"> </a>
            <ul class="site-nav__links site-nav__main" *ngIf="customPages$ | async as customPages">
                
                <ng-container *ngIf="currentUser$ | async | withPermission: {permission: PermissionEnum.CustomPageRights, rights: RightsEnum.Read}">
                    <ng-container *ngIf="customPages.viewPages.length > 0">
                        <div class="nav-item dropdown" routerLinkActive="active">
                            <a class="nav-link dropdown-toggle" [matMenuTriggerFor]="viewMenu" >
                                View
                            </a>
                            <mat-menu #viewMenu>
                                <button mat-menu-item
                                    *ngFor="let viewPage of customPages.viewPages" routerLink="/custom-pages/{{ viewPage.CustomPageVanityUrl }}">
                                    {{ viewPage.CustomPageDisplayName }}
                                </button>
                                
                            </mat-menu>
                        </div>
                    </ng-container>
    
                    <ng-container *ngIf="customPages.learnMorePages.length > 0">
                        <div class="nav-item dropdown" routerLinkActive="active">
                            <a class="nav-link dropdown-toggle" [matMenuTriggerFor]="learnMoreMenu" >
                                Learn More
                            </a>
                            <mat-menu #learnMoreMenu>
                                <button mat-menu-item
                                    *ngFor="let learnMorePage of customPages.learnMorePages"
                                    routerLink="/custom-pages/{{ learnMorePage.CustomPageVanityUrl }}"
                                    >
                                    {{ learnMorePage.CustomPageDisplayName }}
                                </button>
                            </mat-menu>
                        </div>
                    </ng-container>
                </ng-container>
                


                <ng-container *ngIf="(currentModule$ | async)?.Module.ModuleID == ModuleEnum.CommitmentLibrary">
                    <li class="nav-item" *ngIf="currentUser$ | async | withPermission: {permission: PermissionEnum.ProjectRights, rights: RightsEnum.Read}">
                        <a routerLink="/projects" class="nav-link" role="button" routerLinkActive="active">Projects</a>
                    </li>
                    <li class="nav-item" *ngIf="currentUser$ | async | withPermission: {permission: PermissionEnum.SourceDocumentRights, rights: RightsEnum.Read}">
                        <a routerLink="/source-documents" class="nav-link" role="button" routerLinkActive="active">Source Documents</a>
                    </li>
                    <li class="nav-item" *ngIf="currentUser$ | async | withPermission: {permission: PermissionEnum.CommitmentRights, rights: RightsEnum.Read}">
                        <a routerLink="/commitments" class="nav-link" role="button" routerLinkActive="active">Commitments</a>
                    </li>
                    <li class="nav-item" *ngIf="currentUser$ | async | withPermission: {permission: PermissionEnum.CommitmentRights, rights: RightsEnum.Read}">
                        <a routerLink="/compliance-requirements" class="nav-link" role="button" routerLinkActive="active">Compliance Requirements</a>
                    </li>
                </ng-container>

                <ng-container *ngIf="(currentModule$ | async)?.Module.ModuleID == ModuleEnum.MonitoringDashboard">
                    <li class="nav-item">
                        <a routerLink="/daily-monitoring-reports" class="nav-link" role="button" routerLinkActive="active">Daily Monitoring Reports</a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/trackables-explorer" class="nav-link" role="button" routerLinkActive="active">Trackables</a>
                    </li>
                </ng-container>

                <ng-container *ngIf="(currentModule$ | async)?.Module.ModuleID == ModuleEnum.Scheduling">
                    <li class="nav-item">
                        <a routerLink="/test-scheduling" class="nav-link" role="button" routerLinkActive="active">Test Scheudling</a>
                    </li>
                </ng-container>

                <ng-container *ngIf="(currentModule$ | async)?.Module.ModuleID == ModuleEnum.ComplianceTracking">
                    <li class="nav-item" *ngIf="currentUser$ | async | withPermission: {permission: PermissionEnum.ProjectRights, rights: RightsEnum.Read}">
                        <a routerLink="/projects" class="nav-link" role="button" routerLinkActive="active">Projects</a>
                    </li>
                    <li class="nav-item" *ngIf="currentUser$ | async | withPermission: {permission: PermissionEnum.ProjectRights, rights: RightsEnum.Read}">
                        <a routerLink="/components" class="nav-link" role="button" routerLinkActive="active">Components</a>
                    </li>
                </ng-container>
            </ul>
        </div>
    </nav>
</header>
