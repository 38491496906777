<div class="explorer-wrapper">
    <div class="page-header">
        <h1 class="page-title">FILTER TRACKABLES</h1>
    </div>

    <div class="grid-12" style="gap: 1rem">
        <div class="g-col-2">
            <mat-label>Title</mat-label>
            <esa-material-ff
                name="Title"
                [editMode]="true"
                type="text"
                [(ngModel)]="titleFilter"
                (ngModelChange)="onTitleFilterChange($event)"
                placeholder="Search">
            </esa-material-ff>
        </div>

        <div class="g-col-2">
            <mat-label>Category</mat-label>
            <esa-material-ff
                name="Category"
                [editMode]="true"
                placeholder="Filter by Category"
                [options]="categories"
                type="dropdown"
                [(ngModel)]="categoryFilter"
                (ngModelChange)="onCategoryFilterChanged($event)"
                [dropdownMultiple]="true">
            </esa-material-ff>
        </div>

        <div class="g-col-3">
            <mat-label>Last updated date range</mat-label>
            <mat-form-field appearance="outline" style="width: 100%">
                <mat-date-range-input
                    [(ngModel)]="lastUpdatedFilter"
                    (ngModelChange)="onLastUpdatedFilterChanged($event)"
                    [rangePicker]="lastUpdatedPicker"
                    name="lastUpdatedRange"
                    ngDefaultControl>
                    <input matStartDate placeholder="Start date" (dateChange)="onLastUpdatedFilterStartDateChanged($event)" #startDateInput />
                    <input matEndDate placeholder="End date" (dateChange)="onLastUpdatedFilterEndDateChanged($event)" #endDateInput />
                </mat-date-range-input>
                <mat-datepicker-toggle matIconSuffix [for]="lastUpdatedPicker"></mat-datepicker-toggle>
                <mat-date-range-picker #lastUpdatedPicker></mat-date-range-picker>
            </mat-form-field>
        </div>

        <div class="g-col-2">
            <div class="toggle-wrapper">
                <mat-label>Condition</mat-label>
                <div>
                    <mat-button-toggle-group
                        [value]="conditionFilter"
                        #conditionGroup="matButtonToggleGroup"
                        (change)="onConditionFilterChanged(conditionGroup.value)"
                        [hideMultipleSelectionIndicator]="true"
                        multiple>
                        <mat-button-toggle value="No Concern">No Concern</mat-button-toggle>
                        <mat-button-toggle value="Concern"><mat-icon style="color: #ffab00; margin-right: 10px">warning</mat-icon>Concern</mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
            </div>
        </div>

        <div class="g-col-2">
            <div class="toggle-wrapper">
                <mat-label>Resolved?</mat-label>
                <div>
                    <mat-button-toggle-group
                        [value]="resolvedFilter"
                        #resolvedGroup="matButtonToggleGroup"
                        (change)="onResolvedFilterChanged(resolvedGroup.value)"
                        [hideMultipleSelectionIndicator]="true"
                        multiple>
                        <mat-button-toggle value="yes"><mat-icon style="color: green; margin-right: 10px">check_circle</mat-icon>Yes</mat-button-toggle>
                        <mat-button-toggle value="no"><mat-icon style="color: red; margin-right: 10px">radio_button_unchecked</mat-icon>No</mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
            </div>
        </div>

        <div class="g-col-1" style="align-self: center">
            <button mat-flat-button color="primary" (click)="resetFilters(startDateInput, endDateInput)">CLEAR</button>
        </div>

        <div class="g-col-6" style="align-self: end">
            <p>
                {{ filteredTrackables?.length ?? 0 }} OF {{ trackables?.length ?? 0 }} TRACKABLES
                {{ filteredTrackables?.length == trackables?.length ? "" : "MATCHING CURRENT FILTERS" }}
            </p>
        </div>

        <div class="g-col-6">
            <div class="toggle-wrapper" style="flex-direction: row">
                <div>
                    <mat-label style="margin-right: 1rem">Filter map by extent</mat-label>
                    <mat-button-toggle-group
                        [value]="extentFilterToggle"
                        #extentFilterToggleGroup="matButtonToggleGroup"
                        [hideSingleSelectionIndicator]="true"
                        (change)="onExtentFilterToggleChanged(extentFilterToggleGroup.value)">
                        <mat-button-toggle [value]="false">Off</mat-button-toggle>
                        <mat-button-toggle [value]="true">On</mat-button-toggle>
                    </mat-button-toggle-group>

                    <button
                        mat-icon-button
                        *ngIf="mapSelectedTrackables && mapSelectedTrackables.length >= 0 && !extentFilterToggle"
                        color="primary"
                        (click)="clearMapSelectedTrackables()"
                        style="margin-left: 1rem">
                        <mat-icon>clear</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="grid-12" style="margin-top: 1rem;">
        <div class="g-col-6" style="height: 65vh; overflow: auto; padding-right: 1rem">
            @if(loading) {
                <loading-spinner />
            }
           
            <div class="trackables-wrapper" *ngIf="!loading">
                <div *ngIf="filteredTrackables && filteredTrackables.length == 0" class="no-matching-trackables">
                    No matching trackables for current filters.
                </div>
                <mat-card
                    *ngFor="let trackable of filteredTrackables; let i = index"
                    (click)="selectTrackable(trackable)"
                    [class.selected]="selectedTrackable && trackable['_record_id'] == selectedTrackable['_record_id']">
                    <mat-card-header style="align-items: center; padding-bottom: 1rem;">
                        <div style="padding-right: 1rem">
                            <mat-icon *ngIf="trackable['resolved'] === 'no'" style="color: red">radio_button_unchecked</mat-icon>
                            <mat-icon *ngIf="trackable['resolved'] === 'yes'" style="color: green">check_circle</mat-icon>
                        </div>
                        <div style="width: 100%;">
                            <mat-card-title
                                matTooltipShowDelay="500"
                                matTooltipPosition="above"
                                matTooltip="{{ trackable['category'] }} - {{ trackable['location_of_observation'] }} - {{
                                    trackable['initial_date_of_trackable'] | date
                                }}"
                                ><mat-icon *ngIf="trackable['condition'] === 'Concern'" style="font-size: 16px; height: 16px; color: #ffab00">warning</mat-icon
                                >{{ trackable["category"] }} - {{ trackable["location_of_observation"] }} - {{ trackable["initial_date_of_trackable"] | date }}
                            </mat-card-title>
                            <mat-card-subtitle style="margin-bottom: 8px">{{ trackable["_title"] }}</mat-card-subtitle>
                            <mat-card-subtitle>Last Updated: {{ trackable["_updated_at"] | date }}</mat-card-subtitle>
                        </div>
                        <!-- <div style="z-index: 5">
                            <a [routerLink]="['trackable-details', trackable._record_id]">Details</a>
                        </div> -->
                    </mat-card-header>
                </mat-card>
            </div>
        </div>

        <div class="g-col-6 map-wrapper" style="height: 65vh;">
            <map
                [allObjectsWithFeature]="trackables"
                [filteredObjectsWithFeature]="filteredTrackables"
                [featureKey]="'_geometry'"
                [uniqueKey]="'_record_id'"
                [selectedObject]="selectedTrackable"
                [filterToExtent]="extentFilterToggle"
                (onFeatureSelected)="mapFeatureClicked($event)"
                (onFeaturesFiltered)="mapFilterApplied($event)"></map>
        </div>
    </div>
</div>

<!--    Keeping around for debugging purposes.     -->
<!-- <mat-card style="margin-top: 0.5rem">
        <mat-card-content>
            <pre>{{ selectedTrackable | json }}</pre>
        </mat-card-content>
    </mat-card> -->

<!-- keeping till trackables details page is built - 3/26 JS -->
<!-- <div class="details-wrapper" *ngIf="selectedTrackable">
        <mat-card class="details-card">
            <mat-card-title>
                <div class="details-title-wrapper">
                    <div style="flex: 1">
                        {{ selectedTrackable["_title"] }}
                    </div>
                    <div>
                        <esa-material-button type="secondary" icon="link" (click)="copyLink()"></esa-material-button>
                        <esa-material-button style="margin-left: 0.25rem" type="secondary" icon="close" (click)="deselectTrackable()"></esa-material-button>
                    </div>
                </div>
            </mat-card-title>
            <mat-card-content>
                <form class="form grid-12" #trackableForm="ngForm">
                    <div class="g-col-12">
                        <esa-material-ff name="Title" [editMode]="false" type="text" [(ngModel)]="selectedTrackable['_title']" label="Title"> </esa-material-ff>
                        <esa-material-ff name="Category" [editMode]="false" type="text" [(ngModel)]="selectedTrackable['category']" label="Category">
                        </esa-material-ff>
                        <esa-material-ff
                            name="InitialDate"
                            [editMode]="false"
                            type="date-time"
                            [(ngModel)]="selectedTrackable['initial_date_of_trackable']"
                            label="Initial Date of Trackable">
                        </esa-material-ff>
                        <esa-material-ff name="FollowUp" [editMode]="false" type="text" [(ngModel)]="selectedTrackable['follow_up_yes_no']" label="Follow up?">
                        </esa-material-ff>
                        <esa-material-ff
                            name="LastUpdated"
                            [editMode]="false"
                            type="date-time"
                            [(ngModel)]="selectedTrackable['_updated_at']"
                            label="Last Updated">
                        </esa-material-ff>
                        <esa-material-ff name="Status" [editMode]="false" type="text" [(ngModel)]="selectedTrackable['_status']" label="Status">
                        </esa-material-ff>
                        <esa-material-ff name="Resolved" [editMode]="false" type="text" [(ngModel)]="selectedTrackable['resolved']" label="Resolved">
                        </esa-material-ff>
                        <esa-material-ff
                            name="Location"
                            [editMode]="false"
                            type="text"
                            [(ngModel)]="selectedTrackable['location_of_observation']"
                            label="Location">
                        </esa-material-ff>
                        <esa-material-ff
                            name="FieldPerson"
                            [editMode]="false"
                            type="text"
                            [(ngModel)]="selectedTrackable['field_person']"
                            label="Field Person"
                            *ngIf="selectedTrackable['field_person'] && selectedTrackable['field_person'] != '[object Object]'">
                        </esa-material-ff>
                        <esa-material-ff name="Notes" [editMode]="false" type="textarea" [(ngModel)]="selectedTrackable['notes_internal_obs']" label="Notes">
                        </esa-material-ff>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>

        <mat-card style="margin-top: 0.5rem" class="grid-card">
            <mat-card-content>
                <mat-tab-group>
                    <mat-tab label="Observation Entries">
                        <observation-entries-list [(trackable)]="selectedTrackable"></observation-entries-list>
                    </mat-tab>
                    <mat-tab label="Communications">
                        <communication-logs-list [(trackable)]="selectedTrackable"></communication-logs-list>
                    </mat-tab>
                </mat-tab-group>
            </mat-card-content>
        </mat-card>
    </div> -->
