import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class DailyMonitoringReportDetailsEventingServiceService {
    public mapClicked$ = new BehaviorSubject<any>(null);

    constructor() {}

    public mapClicked(data: any): void {
        this.mapClicked$.next(data);
    }
}
