import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ComponentDto, ComponentUpsertDto } from "src/app/shared/generated/model/models";

@Injectable({
    providedIn: "root",
})
export class CurrentComponentService {
    currentComponent$ = new BehaviorSubject<ComponentDto>(null);

    constructor() {}

    setCurrentComponent(commitment: ComponentDto): void {
        this.currentComponent$.next(commitment);
    }

    getCurrentComponent(): Observable<ComponentDto> {
        return this.currentComponent$.asObservable();
    }

    createComponentDto(component: ComponentDto) {
        let commimentUpsertDto = new ComponentUpsertDto({
            ProjectID: component?.ProjectRoutingData?.ProjectID,
            ComponentStatusID: component?.Status?.ComponentStatusID,
            Name: component?.Name,
            Description: component?.Description,
            StartDate: component?.StartDate,
            ExpectedEndDate: component?.ExpectedEndDate,
        });

        return commimentUpsertDto;
    }
}
