import { Component, OnInit, Input, ViewChild, EventEmitter, Output } from "@angular/core";
import { NgForm, FormsModule } from "@angular/forms";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CurrentCommitmentService } from "src/app/services/current-commitment/current-commitment.service";
import { CommitmentTypeService } from "src/app/shared/generated/api/commitment-type.service";
import { ResourceCategoryService } from "src/app/shared/generated/api/resource-category.service";
import { SourceService } from "src/app/shared/generated/api/source.service";
import { UserService } from "src/app/shared/generated/api/user.service";
import { CommitmentDto } from "src/app/shared/generated/model/commitment-dto";
import { CommitmentTypeDto } from "src/app/shared/generated/model/commitment-type-dto";
import { CommitmentUpsertDto } from "src/app/shared/generated/model/commitment-upsert-dto";
import { ResourceCategoryDto } from "src/app/shared/generated/model/resource-category-dto";
import { SourceDto } from "src/app/shared/generated/model/source-dto";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { TinymceEditorComponent } from "src/app/shared/components/tinymce-editor/tinymce-editor.component";
import { BypassHtmlPipe } from "../../shared/pipes/bypassHtml.pipe";
import { TinymceEditorComponent as TinymceEditorComponent_1 } from "../../shared/components/tinymce-editor/tinymce-editor.component";
import { NgClass, NgIf, AsyncPipe, JsonPipe } from "@angular/common";
import { RouterLink } from "@angular/router";
import { CustomFormLabelComponent } from "../../shared/components/custom-form-label/custom-form-label.component";
import { EsaMaterialFormFieldComponent, EsaMaterialButtonComponent, EsaLabelComponent } from "esa-material-form-field";
import { MatButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { MatTooltip } from "@angular/material/tooltip";
import { FileDropUploadComponent } from "src/app/shared/components/file-drop-upload/file-drop-upload.component";
import { SimpleFileDisplayComponent } from "src/app/shared/components/simple-file-display/simple-file-display.component";
import { BeaconLoadingOverlayComponent } from "src/app/shared/components/beacon-loading-overlay/beacon-loading-overlay.component";
import { LoadingSpinnerComponent } from "src/app/shared/components/loading-spinner/loading-spinner.component";
import { CommitmentFileListComponent } from "../commitment-file-list/commitment-file-list.component";

@Component({
    selector: "commitment-form",
    templateUrl: "./commitment-form.component.html",
    styleUrls: ["./commitment-form.component.scss"],
    standalone: true,
    imports: [
        FormsModule,
        EsaMaterialFormFieldComponent,
        CustomFormLabelComponent,
        RouterLink,
        NgClass,
        NgIf,
        TinymceEditorComponent_1,
        EsaMaterialButtonComponent,
        AsyncPipe,
        BypassHtmlPipe,
        MatButton,
        MatIcon,
        MatTooltip,
        JsonPipe,
        FileDropUploadComponent,
        SimpleFileDisplayComponent,
        EsaLabelComponent,
        BeaconLoadingOverlayComponent,
        LoadingSpinnerComponent,
        CommitmentFileListComponent,
    ],
})
export class CommitmentFormComponent implements OnInit {
    @ViewChild("commitmentForm", { read: NgForm }) form: NgForm;
    @ViewChild("tinyMceEditor") tinyMceEditor: TinymceEditorComponent;

    @Output() formSubmitted = new EventEmitter<any>();
    @Output() cancelEditModeChange = new EventEmitter<boolean>();

    @Input() commitment: CommitmentDto;
    @Input() editMode: boolean;
    @Input() showFileUpload: boolean = false;

    commitmentUpsertDto: CommitmentUpsertDto;

    public commitmentTypes$: Observable<CommitmentTypeDto[]>;
    public sources$: Observable<SourceDto[]>;
    public resourceCategories$: Observable<ResourceCategoryDto[]>;
    public users$: Observable<UserDto[]>;
    public isLoading: boolean = false
    public files: File[] = [];

    constructor(
        private commitmentTypeService: CommitmentTypeService,
        private resourceCategoryService: ResourceCategoryService,
        private sourceService: SourceService,
        private userService: UserService,
        private currentCommitmentService: CurrentCommitmentService
    ) {}

    ngOnInit(): void {
        this.sources$ = this.sourceService.sourcesGet();
        this.commitmentTypes$ = this.commitmentTypeService.commitmentTypesGet();
        this.resourceCategories$ = this.resourceCategoryService.resourceCategoriesGet();
        this.users$ = this.userService.usersGet().pipe(map((data: any) => data.filter((user: any) => !user.IsClientUser)));
        this.setForm();
    }

    setForm() {
        this.commitmentUpsertDto = this.currentCommitmentService.createCommitmentDto(this.commitment);
    }

    saveForm(form: NgForm) {
        this.isLoading = true;
        this.formSubmitted.emit(form);
        this.isLoading = false;
    }

    cancelEditMode() {
        this.setForm();
        this.cancelEditModeChange.emit(true);
    }

    uploadFilesChanged(files: File[]) {
        const existingFileNames = this.files.map((file) => file.name);
        if (existingFileNames.length > 0) 
        {
            const filesToAdd = files.filter((file) => !existingFileNames.includes(file.name));
            this.files.push(...filesToAdd);
        } 
        else 
        {
            this.files.push(...files);
        }
    }

    deleteNewFile(file: File) {
        // find the index of the file and remove it from the array
        const index = this.files.indexOf(file);
        this.files.splice(index, 1);
    }
}
