import { Component, OnInit, ChangeDetectorRef, ViewChild } from "@angular/core";
import { FieldDefinitionService } from "src/app/shared/generated/api/field-definition.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { ColDef } from "ag-grid-community";
import { CustomRichTextTypeEnum } from "src/app/shared/generated/enum/custom-rich-text-type-enum";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { AgGridAngular } from "ag-grid-angular";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { Subscription } from "rxjs";
import { GridCommonService } from "src/app/shared/services/grid-common.service";
import { ClearGridFiltersButtonComponent } from "../../shared/components/clear-grid-filters-button/clear-grid-filters-button.component";
import { GridActionsComponent } from "src/app/shared/components/ag-grid/grid-actions/grid-actions.component";

@Component({
    selector: "beacon-field-definition-list",
    templateUrl: "./field-definition-list.component.html",
    styleUrls: ["./field-definition-list.component.scss"],
    standalone: true,
    imports: [ClearGridFiltersButtonComponent, AgGridAngular, GridActionsComponent],
})
export class FieldDefinitionListComponent implements OnInit {
    @ViewChild("fieldDefinitionsGrid") fieldDefinitionsGrid: AgGridAngular;

    private currentUser: UserDto;
    public richTextTypeID: number = CustomRichTextTypeEnum.LabelsAndDefinitionsList;
    public rowData = [];
    public columnDefs: ColDef[];
    

    userSubscription: Subscription;
    fieldDefSubscription: Subscription;

    constructor(
        private gridCommonService: GridCommonService,
        private fieldDefinitionService: FieldDefinitionService,
        private authenticationService: AuthenticationService,
        private cdr: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        this.userSubscription = this.authenticationService.getCurrentUser().subscribe((currentUser) => {
            this.currentUser = currentUser;
            this.columnDefs = [];
            if (this.authenticationService.hasPermission(this.currentUser, PermissionEnum.FieldDefinitionRights, RightsEnum.Update)) {
                this.columnDefs.push({
                    headerName: "Label",
                    valueGetter: function (params: any) {
                        return {
                            LinkValue: params.data.FieldDefinitionType.FieldDefinitionTypeID,
                            LinkDisplay: params.data.FieldDefinitionType.FieldDefinitionTypeDisplayName,
                        };
                    },
                    cellRendererSelector: () => this.gridCommonService.renderLink("./"),
                    filterValueGetter: function (params: any) {
                        return params.data.FieldDefinitionType.FieldDefinitionTypeDisplayName;
                    },
                    comparator: function (id1: any, id2: any) {
                        let link1 = id1.LinkDisplay;
                        let link2 = id2.LinkDisplay;
                        if (link1 < link2) {
                            return -1;
                        }
                        if (link1 > link2) {
                            return 1;
                        }
                        return 0;
                    },
                    width: 200,
                    autoHeight: true,
                });
            } else {
                this.columnDefs.push({
                    headerName: "Label",
                    field: "FieldDefinitionType.FieldDefinitionTypeDisplayName",
                    cellRenderer: function (params: any) {
                        return params.data.FieldDefinitionType.FieldDefinitionTypeDisplayName
                            ? params.data.FieldDefinitionType.FieldDefinitionTypeDisplayName
                            : "";
                    },
                    autoHeight: true,
                    width: 200,
                    cellStyle: { "white-space": "normal" },
                });
            }

            this.columnDefs.push({
                headerName: "Definition",
                field: "FieldDefinitionValue",
                cellRenderer: function (params: any) {
                    return `<p style="margin-top: 1rem;">${params.data.FieldDefinitionValue}</p>`;
                },
                autoHeight: true,
                flex: 1,
                cellStyle: { "white-space": "normal" },
                tooltipValueGetter: (params) => null
            });
            this.cdr.markForCheck();
        });
    }

    onGridReady(gridEvent: any) {
        this.fieldDefinitionsGrid?.api.showLoadingOverlay();

        this.fieldDefSubscription = this.fieldDefinitionService.fieldDefinitionsGet().subscribe((fieldDefinitions) => {
            this.rowData = fieldDefinitions;
            this.fieldDefinitionsGrid.api.hideOverlay();
            this.cdr.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.cdr.detach();
        this.userSubscription?.unsubscribe();
        this.fieldDefSubscription?.unsubscribe();
    }
}
