import { Pipe, PipeTransform, inject } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserDto } from '../../generated/model/user-dto';

@Pipe({
  name: 'userIsImpersonated',
  standalone: true
})
export class UserIsImpersonatedPipe implements PipeTransform {
  authenticationService = inject(AuthenticationService);
  transform(user: UserDto): boolean {
    if(!user) return false;
    return this.authenticationService.isCurrentUserBeingImpersonated(user);
  }

}
