<ng-container>
    <h2 mat-dialog-title>
        Assign Checklist Items
    </h2>
    <mat-dialog-content>
        <p>You have selected {{data.SelectedChecklistItems.length}} checklist items to assign. </p>
        <ul>
            @for (item of data.SelectedChecklistItems; track $index) {
                <li>
                    {{item.ComplianceRequirement.Name}}
                    <pill matTooltip="Commitment ID">
                        {{item.ComplianceRequirement.Commitment.ClientCommitmentID}}
                    </pill>
                </li>
            }
        </ul>
        <p>Please select a checklist to assign them to.</p>
        <form action="" [formGroup]="checklistFormGroup">
            <esa-material-ff
                name="Checklist"
                [editMode]="true"
                type="dropdown"
                [options]="options"
                placeholder="Select a checklist"
                optionLabel="Name"
                optionValue="ChecklistID"
                label="Checklist"
                formControlName="ChecklistID">
            </esa-material-ff>
        </form>
        
       
    </mat-dialog-content>
</ng-container>
<mat-dialog-actions align="end">
    <span>
        <button
            mat-flat-button
            style="margin-right: 1rem"
            color="primary"
            type="submit"
            (click)="save()"
            [disabled]="!checklistFormGroup?.valid">
            <mat-icon>save</mat-icon> Save
        </button>
    </span>
    <button mat-button type="button" color="primary" mat-dialog-close><mat-icon>cancel</mat-icon> Cancel</button>
</mat-dialog-actions>