<div class="detail-view">
    <div class="page-header pb-3">
        <h1 class="page-title">{{ isCreating ? "New" : "Edit" }} Custom Page Properties</h1>

        <div class="g-col-6" style="display: flex; flex-direction: column; justify-content: center">
            <p>
                This page allows you to {{ isCreating ? "create" : "edit" }} custom page properties. Select a menu category for the custom page to reside in and
                which roles will be able to view the page. Administrators have viewable rights on all custom pages by default.
            </p>
        </div>
    </div>

    <ng-container *ngIf="customPageUpsertDto">
        <custom-page-form #customPageForm [customPage]="customPageUpsertDto" [editMode]="true" (formSubmitted)="save()" (cancelEditModeChange)="cancelEdit()"></custom-page-form>
    </ng-container>
</div>
