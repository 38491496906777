import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, QueryList, ViewChildren } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TrackableService } from "src/app/shared/generated/api/trackable.service";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { MapComponent } from "../../shared/components/map/map.component";
import { MatTooltip } from "@angular/material/tooltip";
import { MatCard, MatCardHeader, MatCardTitle, MatCardSubtitle } from "@angular/material/card";
import { NgIf, NgFor, DatePipe } from "@angular/common";
import { MatIcon } from "@angular/material/icon";
import { MatButtonToggleGroup, MatButtonToggle } from "@angular/material/button-toggle";
import { MatInput } from "@angular/material/input";
import { MatDateRangeInput, MatStartDate, MatEndDate, MatDatepickerToggle, MatDateRangePicker } from "@angular/material/datepicker";
import { FormsModule } from "@angular/forms";
import { EsaMaterialFormFieldComponent, EsaMaterialButtonComponent } from "esa-material-form-field";
import { MatLabel, MatFormField, MatSuffix } from "@angular/material/form-field";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { MatButton, MatIconButton } from "@angular/material/button";
import { LoadingSpinnerComponent } from "src/app/shared/components/loading-spinner/loading-spinner.component";

@Component({
    selector: "trackables-explorer",
    templateUrl: "./trackables-explorer.component.html",
    styleUrls: ["./trackables-explorer.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatLabel,
        EsaMaterialFormFieldComponent,
        FormsModule,
        MatFormField,
        MatDateRangeInput,
        MatInput,
        MatStartDate,
        MatEndDate,
        MatDatepickerToggle,
        MatSuffix,
        MatDateRangePicker,
        MatButtonToggleGroup,
        MatButtonToggle,
        MatIcon,
        NgIf,
        MatProgressSpinner,
        NgFor,
        MatCard,
        MatCardHeader,
        MatCardTitle,
        MatTooltip,
        MatCardSubtitle,
        MapComponent,
        DatePipe,
        MatButton,
        MatIconButton,
        LoadingSpinnerComponent
    ],
})
export class TrackablesExplorerComponent implements OnInit, AfterViewInit {
    @ViewChildren("card") cards: QueryList<ElementRef>;

    public loading: Boolean = true;
    public trackables: any[];

    public filteredTrackables: any[] = [];

    public titleFilter: string = null;
    public resolvedFilter: string[] = ["no"];
    public conditionFilter: string[] = ["Concern"];
    public categoryFilter: string[] = [];

    public extentFilterToggle: boolean = false;

    public lastUpdatedFilter: any = null;
    public lastUpdatedFilterStartDate: any = null;
    public lastUpdatedFilterEndDate: any = null;

    public categories: string[];

    public selectedTrackable;
    public mapSelectedTrackables: any[] = null;

    constructor(
        private trackableService: TrackableService,
        private alertService: AlertService,
        private router: Router,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        this.trackableService.trackablesGet().subscribe(
            (trackables) => {
                this.loading = false;
                this.trackables = trackables;

                let categories = this.trackables
                    .map((t) => {
                        return t["category"];
                    })
                    .sort((a, b) => {
                        return a.localeCompare(b);
                    });

                this.categories = [...new Set(categories)];
                this.applyFilters();

                this.route.queryParams.subscribe((params) => {
                    if (params.trackable) {
                        let trackable = this.trackables.find((t) => {
                            return t["_record_id"] == params.trackable;
                        });

                        if (trackable) {
                            this.selectTrackable(trackable);
                        } else {
                            this.router.navigate([], { relativeTo: this.route, queryParams: {}, replaceUrl: true, skipLocationChange: false });
                        }
                    }
                });

                this.cdr.markForCheck();
            },
            (error) => {
                this.loading = false;
                this.cdr.markForCheck();
            }
        );
    }

    onTitleFilterChange(value: string) {
        this.titleFilter = value;
        this.applyFilters();
    }

    onResolvedFilterChanged(value: string[]) {
        this.resolvedFilter = value;
        this.applyFilters();
    }

    onConditionFilterChanged(value: string[]) {
        this.conditionFilter = value;
        this.applyFilters();
    }

    onCategoryFilterChanged(value: string[]) {
        this.categoryFilter = value;
        this.applyFilters();
    }

    onLastUpdatedFilterChanged(event) {
        this.lastUpdatedFilter = event;
        this.applyFilters();
    }

    onLastUpdatedFilterStartDateChanged(event) {
        if (event && event.value && event.value._isValid) {
            this.lastUpdatedFilterStartDate = event;
        } else {
            this.lastUpdatedFilterStartDate = null;
        }

        this.applyFilters();
    }

    onLastUpdatedFilterEndDateChanged(event) {
        if (event && event.value && event.value._isValid) {
            this.lastUpdatedFilterEndDate = event;
        } else {
            this.lastUpdatedFilterEndDate = null;
        }

        this.applyFilters();
    }

    onExtentFilterToggleChanged(event) {
        this.extentFilterToggle = event;
        this.applyFilters();
    }

    applyFilters() {
        if (this.trackables) {
            this.filteredTrackables = this.trackables
                .filter((t) => {
                    if (this.titleFilter) {
                        return t["_title"]?.toLowerCase()?.includes(this.titleFilter?.toLowerCase()) ?? false;
                    }
                    return true;
                })
                .filter((t) => {
                    if (this.resolvedFilter) {
                        return this.resolvedFilter.includes(t["resolved"]);
                    }
                    return true;
                })
                .filter((t) => {
                    if (this.conditionFilter) {
                        return this.conditionFilter.includes(t["condition"]);
                    }
                    return true;
                })
                .filter((t) => {
                    if (this.categoryFilter && this.categoryFilter.length) {
                        return this.categoryFilter.includes(t["category"]);
                    }
                    return true;
                })
                .filter((t) => {
                    if (this.lastUpdatedFilterStartDate && this.lastUpdatedFilterEndDate) {
                        let startDate = this.lastUpdatedFilterStartDate.value.toDate();
                        let endDate = this.lastUpdatedFilterEndDate.value.toDate();
                        endDate.setHours(23, 59, 59, 999); // End of Day so that we get all records for the end date.

                        let updatedDate = new Date(t["_updated_at"]);
                        return updatedDate >= startDate && updatedDate <= endDate;
                    }
                    return true;
                })
                .filter((t) => {
                    if (this.mapSelectedTrackables && this.mapSelectedTrackables.length >= 0) {
                        return this.mapSelectedTrackables.map((f) => f.properties["_record_id"]).includes(t["_record_id"]);
                    }
                    return true;
                })
                .sort((a, b) => {
                    return b["_updated_at"].localeCompare(a["_updated_at"]);
                });

            this.cdr.detectChanges();
        }
    }

    resetFilters(startInput, endInput) {
        this.titleFilter = null;
        this.resolvedFilter = ["no"];
        this.conditionFilter = ["Concern"];
        this.categoryFilter = [];
        this.mapSelectedTrackables = null;
        this.extentFilterToggle = false;
        this.clearDates(startInput, endInput);
        this.deselectTrackable();
    }

    selectTrackable(trackable: any) {
        this.selectedTrackable = trackable;
        const queryParams = { trackable: trackable["_record_id"] };
        this.router.navigate([], { relativeTo: this.route, queryParams: queryParams, replaceUrl: true, skipLocationChange: false });
        this.cdr.markForCheck();
    }

    clearMapSelectedTrackables() {
        this.mapSelectedTrackables = null;
        this.applyFilters();
        this.cdr.markForCheck();
    }

    clearDates(startInput, endInput) {
        this.clearInput(startInput);
        this.clearInput(endInput);
        this.lastUpdatedFilterStartDate = null;
        this.lastUpdatedFilterEndDate = null;
        this.applyFilters();
        this.cdr.detectChanges();
    }

    clearInput(inputElement: HTMLInputElement) {
        inputElement.value = "";
    }

    mapFeatureClicked(selectedFeature) {
        let trackable = this.filteredTrackables.find((t) => {
            return t["_record_id"] == selectedFeature.properties._record_id;
        });

        this.selectTrackable(trackable);
    }

    mapFilterApplied(features) {
        this.mapSelectedTrackables = features;
        this.applyFilters();
    }

    copyLink() {
        let url = window.location.href;
        navigator.clipboard.writeText(url).then(() => {
            this.alertService.pushAlert(new Alert("Link copied to clipboard", AlertContext.Success, true, "link-copied"), 1000 * 3);
        });
    }

    deselectTrackable() {
        this.selectedTrackable = null;
        this.router.navigate([], { relativeTo: this.route, queryParams: {}, replaceUrl: true, skipLocationChange: false });
        this.cdr.markForCheck();
    }
}
