import { Component, Input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { PillComponent } from '../pill/pill.component';
import { PillboxComponent } from '../pillbox/pillbox.component';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { ChecklistItemStatusDto } from '../../generated/model/checklist-item-status-dto';
import { ChecklistItemStatusEnum } from '../../generated/model/checklist-item-status-enum';
import { ChecklistItemStatusComponent } from '../checklist-item-status/checklist-item-status.component';

@Component({
  selector: 'checklist-item',
  standalone: true,
  imports: [PillComponent, MatTooltip, PillboxComponent, CommonModule, RouterLink, ChecklistItemStatusComponent],
  templateUrl: './checklist-item.component.html',
  styleUrl: './checklist-item.component.scss'
})
export class ChecklistItemComponent {
  @Input() clientCommitmentID: string;
  @Input() complianceRequirementName: string;
  @Input() phase: string;
  @Input() scope: string;
  @Input() frequency: string;
  @Input() complianceRequirementType: string;
  @Input() resourceCategory: string;
  @Input() checklistItemID: string;
  @Input() checklistID: string;
  @Input() checklistItemStatus: ChecklistItemStatusDto;
  @Input() linkToChecklistItem: boolean = true;
}
