import { Routes } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { CommitmentCreateComponent } from "./pages/commitment-create/commitment-create.component";
import { CommitmentDetailsComponent } from "./pages/commitment-details/commitment-details.component";
import { CommitmentFileListComponent } from "./pages/commitment-file-list/commitment-file-list.component";
import { CommitmentListComponent } from "./pages/commitment-list/commitment-list.component";
import { CommitmentRelationshipListComponent } from "./pages/commitment-relationship-list/commitment-relationship-list.component";
import { CommitmentRevisionHistoryComponent } from "./pages/commitment-revision-history/commitment-revision-history.component";
import { CommitmentSummaryInformationComponent } from "./pages/commitment-summary-information/commitment-summary-information.component";
import { CommitmentVersionMetadataComponent } from "./pages/commitment-version-metadata/commitment-version-metadata.component";
import { ComplianceRequirementCreateComponent } from "./pages/compliance-requirement-create/compliance-requirement-create.component";
import { ComplianceRequirementForCommitmentListComponent } from "./pages/compliance-requirement-for-commitment-list/compliance-requirement-for-commitment-list.component";
import { ComplianceRequirementListComponent } from "./pages/compliance-requirement-list/compliance-requirement-list.component";
import { ComplianceTriggersComponent } from "./pages/compliance-triggers/compliance-triggers.component";
import { CustomPageDetailComponent } from "./pages/custom-page-detail/custom-page-detail.component";
import { CustomPageEditPropertiesComponent } from "./pages/custom-page-edit-properties/custom-page-edit-properties.component";
import { CustomPageListComponent } from "./pages/custom-page-list/custom-page-list.component";
import { DailyMonitoringReportDetailsComponent } from "./pages/daily-monitoring-report-details/daily-monitoring-report-details.component";
import { DailyMonitoringReportListComponent } from "./pages/daily-monitoring-report-list/daily-monitoring-report-list.component";
import { DashboardManageComponent } from "./pages/dashboards/dashboard-manage/dashboard-manage.component";
import { FieldDefinitionEditComponent } from "./pages/field-definition-edit/field-definition-edit.component";
import { FieldDefinitionListComponent } from "./pages/field-definition-list/field-definition-list.component";
import { HelpComponent } from "./pages/help/help.component";
import { HomeIndexComponent } from "./pages/home/home-index/home-index.component";
import { LoginRedirectComponent } from "./pages/login-redirect/login-redirect.component";
import { ProjectCreateComponent } from "./pages/project-create/project-create.component";
import { ProjectDetailsComponent } from "./pages/project-details/project-details.component";
import { ProjectFileListComponent } from "./pages/project-file-list/project-file-list.component";
import { ProjectListComponent } from "./pages/project-list/project-list.component";
import { ProjectRelatedCommitmentsComponent } from "./pages/project-related-commitments/project-related-commitments.component";
import { ProjectRelatedSourcesComponent } from "./pages/project-related-sources/project-related-sources.component";
import { ProjectSummaryInformationComponent } from "./pages/project-summary-information/project-summary-information.component";
import { RoleCreateComponent } from "./pages/role-create/role-create.component";
import { RoleDetailComponent } from "./pages/role-detail/role-detail.component";
import { RoleListComponent } from "./pages/role-list/role-list.component";
import { SourceDocumentCreateComponent } from "./pages/source-document-create/source-document-create.component";
import { SourceDocumentDetailsComponent } from "./pages/source-document-details/source-document-details.component";
import { SourceDocumentFileListComponent } from "./pages/source-document-file-list/source-document-file-list.component";
import { SourceDocumentListComponent } from "./pages/source-document-list/source-document-list.component";
import { SourceDocumentRelatedCommitmentsComponent } from "./pages/source-document-related-commitments/source-document-related-commitments.component";
import { SourceDocumentSummaryInformationComponent } from "./pages/source-document-summary-information/source-document-summary-information.component";
import { TestSchedulingPageComponent } from "./pages/test-scheduling-page/test-scheduling-page.component";
import { TrackablesExplorerComponent } from "./pages/trackables-explorer/trackables-explorer.component";
import { UserDetailComponent } from "./pages/user-detail/user-detail.component";
import { UserEditComponent } from "./pages/user-edit/user-edit.component";
import { UserInviteComponent } from "./pages/user-invite/user-invite.component";
import { UserListComponent } from "./pages/user-list/user-list.component";
import { MonitoringDashboardModuleGuard } from "./shared/guards/monitoring-dashboard-module-guard/monitoring-dashboard-module.guard";
import { SchedulingModuleGuard } from "./shared/guards/scheduling-module-guard/scheduling-module.guard";
import { UnsavedChangesGuard } from "./shared/guards/unsaved-changes-guard";
import { SubscriptionInsufficientComponent, UnauthenticatedComponent, NotFoundComponent } from "./shared/pages";
import { ComponentListComponent } from "./pages/component-list/component-list.component";
import { ComplianceTrackingModuleGuardGuard } from "./shared/guards/compliance-tracking-module-guard/compliance-tracking-module-guard.guard";
import { ComponentCreateComponent } from "./pages/component-create/component-create.component";
import { ComponentDetailsComponent } from "./pages/component-details/component-details.component";
import { ComponentSummaryInformationComponent } from "./pages/component-summary-information/component-summary-information.component";
import { ComponentCommitmentListSelectorComponent } from "./pages/component-commitment-list-selector/component-commitment-list-selector.component";
import { CommitmentLibraryModuleGuard } from "./shared/guards/commitment-library-module-guard/commitment-library-module.guard";
import { ModuleEnum } from "./shared/services/tenant-module/tenant-module.service";
import { ComponentChecklistsManageComponent } from "./pages/component-checklists-manage/component-checklists-manage.component";
import { ChecklistItemDetailComponent } from "./pages/checklist-item-detail/checklist-item-detail.component";


export const routes: Routes = [
    {
        path: "",
        component: HomeIndexComponent,
        children: [
            {
                path: "",
                component: LoginRedirectComponent,
            },
            {
                path: "login-redirect",
                component: LoginRedirectComponent,
                canActivate: [MsalGuard],
            },
            {
                path: "manage",
                component: DashboardManageComponent,
                children: [
                    {
                        path: "",
                        redirectTo: "users",
                        pathMatch: "full"
                    },
                    { path: "users", component: UserListComponent, title: "Manage Users" },
                    { path: "users/invite", component: UserInviteComponent, title: "Invite User"},
                    { path: "users/:id", component: UserDetailComponent, title: "User Details"},
                    { path: "users/:id/edit", component: UserEditComponent, title: "Edit User"},
                    { path: "roles", component: RoleListComponent, title: "Manage Roles"},
                    { path: "roles/create", component: RoleCreateComponent, canDeactivate: [UnsavedChangesGuard], title: "Create Role"},
                    { path: "roles/:id", component: RoleDetailComponent, canDeactivate: [UnsavedChangesGuard], title: "Role Details"},
                    { path: "labels-and-definitions", component: FieldDefinitionListComponent, title: "Manage Labels and Definitions"},
                    { path: "labels-and-definitions/:id", component: FieldDefinitionEditComponent, canDeactivate: [UnsavedChangesGuard], title: "Edit Label or Definition"},
                    { path: "custom-pages", component: CustomPageListComponent, canActivate: [MsalGuard], title: "Manage Custom Pages"},
                    { path: "custom-pages/create", component: CustomPageEditPropertiesComponent, data: { create: true }, canDeactivate: [UnsavedChangesGuard], title: "Create Custom Page"},
                    {
                        path: "custom-pages/edit-properties/:vanity-url",
                        title: "Edit Custom Page Properties",
                        component: CustomPageEditPropertiesComponent,
                        data: { create: false },
                        canDeactivate: [UnsavedChangesGuard],
                    },
                ],
            },
            {
                path: "custom-pages/:vanity-url",
                component: CustomPageDetailComponent,
                canDeactivate: [UnsavedChangesGuard],
            },
            {
                path: "projects",
                title: "Projects",
                component: ProjectListComponent,
                data: { moduleIDs: [ModuleEnum.CommitmentLibrary, ModuleEnum.ComplianceTracking] },
                canActivate: [MsalGuard, CommitmentLibraryModuleGuard],
            },
            {
                path: "projects/create",
                title: "Create Project",
                component: ProjectCreateComponent,
                canActivate: [MsalGuard, CommitmentLibraryModuleGuard],
                data: { moduleIDs: [ModuleEnum.CommitmentLibrary, ModuleEnum.ComplianceTracking] },
                canDeactivate: [UnsavedChangesGuard],
            },
            {
                path: "projects/:id",
                component: ProjectDetailsComponent,
                canActivate: [MsalGuard, CommitmentLibraryModuleGuard],
                data: { moduleIDs: [ModuleEnum.CommitmentLibrary, ModuleEnum.ComplianceTracking] },
                children: [
                    {
                        path: "",
                        redirectTo: "summary-information",
                        pathMatch: "full"
                    },
                    {
                        path: "summary-information",
                        title: "Project Summary Information",
                        component: ProjectSummaryInformationComponent,
                        canActivate: [MsalGuard],
                        data: { moduleIDs: [ModuleEnum.CommitmentLibrary, ModuleEnum.ComplianceTracking] },
                        canDeactivate: [UnsavedChangesGuard],
                    },
                    {
                        path: "related-source-documents",
                        title: "Project Related Source Documents",
                        component: ProjectRelatedSourcesComponent,
                        canActivate: [MsalGuard],
                        data: { moduleIDs: [ModuleEnum.CommitmentLibrary, ModuleEnum.ComplianceTracking] },
                    },
                    {
                        path: "related-commitments",
                        title: "Project Related Commitments",
                        component: ProjectRelatedCommitmentsComponent,
                        canActivate: [MsalGuard],
                        data: { moduleIDs: [ModuleEnum.CommitmentLibrary, ModuleEnum.ComplianceTracking] },
                    },                    
                ],
            },
            {
                path: "source-documents",
                title: "Source Documents",
                component: SourceDocumentListComponent,
                canActivate: [MsalGuard, CommitmentLibraryModuleGuard],
                data: { moduleIDs: [ModuleEnum.CommitmentLibrary] },
            },
            {
                path: "source-documents/create",
                title: "Create Source Document",
                component: SourceDocumentCreateComponent,
                canActivate: [MsalGuard, CommitmentLibraryModuleGuard],
                canDeactivate: [UnsavedChangesGuard],
                data: { moduleIDs: [ModuleEnum.CommitmentLibrary] },
            },
            {
                path: "source-documents/:id",
                component: SourceDocumentDetailsComponent,
                canActivate: [MsalGuard, CommitmentLibraryModuleGuard],
                data: { moduleIDs: [ModuleEnum.CommitmentLibrary] },
                children: [
                    {
                        path: "summary-information",
                        component: SourceDocumentSummaryInformationComponent,
                        canActivate: [MsalGuard],
                        canDeactivate: [UnsavedChangesGuard],
                        data: { moduleIDs: [ModuleEnum.CommitmentLibrary] },
                    },
                    {
                        path: "related-commitments",
                        component: SourceDocumentRelatedCommitmentsComponent,
                        canActivate: [MsalGuard],
                        canDeactivate: [],
                        data: { moduleIDs: [ModuleEnum.CommitmentLibrary] },
                    },                    
                ],
            },
            {
                path: "commitments",
                title: "Commitments",
                component: CommitmentListComponent,
                canActivate: [MsalGuard, CommitmentLibraryModuleGuard],
                data: { moduleIDs: [ModuleEnum.CommitmentLibrary] },
            },
            {
                path: "commitments/create",
                title: "Create Commitment",
                component: CommitmentCreateComponent,
                canActivate: [MsalGuard, CommitmentLibraryModuleGuard],
                canDeactivate: [UnsavedChangesGuard],
                data: { moduleIDs: [ModuleEnum.CommitmentLibrary] },
            },
            {
                path: "commitments/:id",
                component: CommitmentDetailsComponent,
                canActivate: [MsalGuard, CommitmentLibraryModuleGuard],
                data: { moduleIDs: [ModuleEnum.CommitmentLibrary] },
                children: [
                    {
                        path: "",
                        redirectTo: "summary-information",
                        pathMatch: "full"
                    },
                    {
                        path: "summary-information",
                        title: "Commitment Summary Information",
                        component: CommitmentSummaryInformationComponent,
                        canActivate: [MsalGuard],
                        canDeactivate: [UnsavedChangesGuard],
                        data: { moduleIDs: [ModuleEnum.CommitmentLibrary] },
                    },
                    {
                        path: "compliance-requirements",
                        title: "Commitment Compliance Requirements",
                        component: ComplianceRequirementForCommitmentListComponent,
                        canActivate: [MsalGuard],
                        canDeactivate: [UnsavedChangesGuard],
                        data: { moduleIDs: [ModuleEnum.CommitmentLibrary] },
                    },
                    {
                        path: "compliance-requirements/create",
                        title: "Create Commitment Compliance Requirement",
                        component: ComplianceRequirementCreateComponent,
                        canActivate: [MsalGuard],
                        canDeactivate: [UnsavedChangesGuard],
                        data: { moduleIDs: [ModuleEnum.CommitmentLibrary] },
                    },
                    {
                        path: "compliance-triggers",
                        title: "Commitment Compliance Triggers",
                        component: ComplianceTriggersComponent,
                        canActivate: [MsalGuard],
                        canDeactivate: [UnsavedChangesGuard],
                        data: { moduleIDs: [ModuleEnum.CommitmentLibrary] },
                    },                    
                    {
                        path: "commitment-relationships",
                        title: "Commitment Relationships",
                        component: CommitmentRelationshipListComponent,
                        canActivate: [MsalGuard],
                        canDeactivate: [UnsavedChangesGuard],
                        data: { moduleIDs: [ModuleEnum.CommitmentLibrary] },
                    },
                    {
                        path: "version-metadata",
                        title: "Commitment Version Metadata",
                        component: CommitmentVersionMetadataComponent,
                        canActivate: [MsalGuard],
                        canDeactivate: [UnsavedChangesGuard],
                        data: { moduleIDs: [ModuleEnum.CommitmentLibrary] },
                    },
                    {
                        path: "revision-history",
                        title: "Commitment Revision History",
                        component: CommitmentRevisionHistoryComponent,
                        canActivate: [MsalGuard],
                        data: { moduleIDs: [ModuleEnum.CommitmentLibrary] },
                    },
                ],
            },
            {
                path: "compliance-requirements",
                title: "Compliance Requirements",
                component: ComplianceRequirementListComponent,
                canActivate: [MsalGuard, CommitmentLibraryModuleGuard],
                data: { moduleIDs: [ModuleEnum.CommitmentLibrary] },
            },
            {
                path: "components",
                title: "Components",
                component: ComponentListComponent,
                canActivate: [MsalGuard, ComplianceTrackingModuleGuardGuard],
                data: { moduleIDs: [ModuleEnum.ComplianceTracking] },
            },
            {
                path: "components/create",
                title: "Create Component",
                component: ComponentCreateComponent,
                canActivate: [MsalGuard, ComplianceTrackingModuleGuardGuard],
                canDeactivate: [UnsavedChangesGuard],
                data: { moduleIDs: [ModuleEnum.ComplianceTracking] },
            },
            {
                path: "components/:id",
                component: ComponentDetailsComponent,
                canActivate: [MsalGuard, ComplianceTrackingModuleGuardGuard],
                data: { moduleIDs: [ModuleEnum.ComplianceTracking] },
                children: [
                    {
                        path: "",
                        redirectTo: "summary-information",
                        pathMatch: "full"
                    },
                    {
                        path: "summary-information",
                        title: "Component Summary Information",
                        component: ComponentSummaryInformationComponent,
                        canActivate: [MsalGuard],
                        canDeactivate: [UnsavedChangesGuard],
                        data: { moduleIDs: [ModuleEnum.ComplianceTracking] },
                    },
                    {
                        path: "commitments",
                        title: "Component Commitments",
                        component: ComponentCommitmentListSelectorComponent,
                        canActivate: [MsalGuard],
                        canDeactivate: [UnsavedChangesGuard],
                        data: { moduleIDs: [ModuleEnum.ComplianceTracking] },
                    },
                    {
                        path: "checklists",
                        title: "Component Checklists",
                        component: ComponentChecklistsManageComponent,
                        canActivate: [MsalGuard],
                        canDeactivate: [UnsavedChangesGuard],
                        data: { moduleIDs: [ModuleEnum.ComplianceTracking] },
                    },
                    {
                        path: "checklists/checklist-items/:checklistItemID",
                        title: "Checklist Item",
                        component: ChecklistItemDetailComponent,
                        canActivate: [MsalGuard],
                        data: { moduleIDs: [ModuleEnum.ComplianceTracking] },
                    },
                ],
            },
            {
                path: "daily-monitoring-reports",
                title: "Daily Monitoring Reports",
                component: DailyMonitoringReportListComponent,
                canActivate: [MsalGuard, MonitoringDashboardModuleGuard],
                data: { moduleIDs: [ModuleEnum.MonitoringDashboard] },
            },
            {
                path: "daily-monitoring-reports/:id",
                title: "Daily Monitoring Report Details",
                component: DailyMonitoringReportDetailsComponent,
                canActivate: [MsalGuard, MonitoringDashboardModuleGuard],
                data: { moduleIDs: [ModuleEnum.MonitoringDashboard] },
            },
            {
                path: "trackables-explorer",
                title: "Trackables Explorer",
                component: TrackablesExplorerComponent,
                canActivate: [MsalGuard, MonitoringDashboardModuleGuard],
                data: { moduleIDs: [ModuleEnum.MonitoringDashboard] },
            },
            {
                path: "test-scheduling",
                title: "Test Scheduling",
                component: TestSchedulingPageComponent,
                canActivate: [MsalGuard, SchedulingModuleGuard],
                data: { moduleIDs: [ModuleEnum.Scheduling] },
            },
            {
                path: "help",
                title: "Help",
                component: HelpComponent,
            },
            {
                path: "subscription-insufficient",
                component: SubscriptionInsufficientComponent,
            },
            {
                path: "unauthenticated",
                component: UnauthenticatedComponent,
            },
            {
                path: "**",
                component: NotFoundComponent,
            },
        ],
    },
];
