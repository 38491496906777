import { Component } from '@angular/core';

@Component({
  selector: 'beacon-loading-overlay',
  standalone: true,
  imports: [],
  templateUrl: './beacon-loading-overlay.component.html',
  styleUrl: './beacon-loading-overlay.component.scss'
})
export class BeaconLoadingOverlayComponent {

}
