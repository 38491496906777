<div class="detail-view">
    <div class="edit-definition" *ngIf="fieldDefinition$ | async as fieldDefinition">
        <div class="page-header pb-2">
            <h1 class="page-title">Edit Definition for Label: {{ fieldDefinition.FieldDefinitionType.FieldDefinitionTypeDisplayName }}</h1>
        </div>

        <form>
            <tinymce-editor #tinyMceEditor name="FieldDefinitionValue" [(content)]="fieldDefinition.FieldDefinitionValue"></tinymce-editor>
        </form>

        <div class="actions-bar" style="margin-top: 1rem">
            <button mat-button color="primary" (click)="cancelEditMode()"><mat-icon>cancel</mat-icon> Cancel</button>
            <button
                mat-flat-button
                color="primary"
                [disabled]="isLoading"
                (click)="saveDefinition()"
                style="margin-left: 1rem">
                <mat-icon>save</mat-icon> Save
            </button>
        </div>
    </div>
</div>
