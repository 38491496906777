<div class="detail-view" *ngIf="user$ | async as u"> <!-- switched to "u" so it does not collided with typescript variable -->
    <div class="page-header pb-3">
        <h1 class="page-title">
            {{ u?.FullName || (u?.FirstName || u?.LastName ? u?.FirstName + " " + u?.LastName : null) }}
        </h1>
    </div>

    <form class="form grid-12" (ngSubmit)="onSubmit(editUserForm)" #editUserForm="ngForm">
        <div class="g-col-12">
            <esa-material-ff
                keyStyle="20%"
                name="RoleID"
                [editMode]="true"
                [required]="true"
                label="Role"
                type="dropdown"
                [options]="roles$ | async"
                optionLabel="RoleName"
                optionValue="RoleID"
                (ngModelChange)="checkReceiveSupportEmails()"
                [(ngModel)]="model.RoleID">
            </esa-material-ff>

            <div class="kv-pair" *ngIf="model.RoleID === adminRoleID">
                <custom-form-label label="Should Receive System Communications?"></custom-form-label>
                <div class="value">
                    <mat-radio-group
                        aria-label="Should Receive System Communications?"
                        [ngModelOptions]="{ standalone: true }"
                        [(ngModel)]="model.ReceiveSupportEmails"
                        required>
                        <mat-radio-button [value]="true">Yes</mat-radio-button>
                        <mat-radio-button [value]="false"> No</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </div>

        <div class="g-col-12 actions-bar" style="margin-top: 1rem">
            <span [matTooltip]="!editUserForm.form.valid ? 'You are missing requried fields.' : ''">
                <button
                    mat-flat-button
                    color="primary"
                    (click)="onSubmit(editUserForm)"
                    [disabled]="!editUserForm.form.valid || isLoadingSubmit"
                    style="margin-right: 1rem">
                    <mat-icon>save</mat-icon> Save
                </button>
            </span>
            <button mat-button color="primary" (click)="cancel()"><mat-icon>cancel</mat-icon> Cancel</button>
        </div>
    </form>
</div>
