import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AuthenticationService } from "src/app/services/authentication.service";
import { EditViewEventService } from "src/app/services/edit-view-event.service";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { ProjectFormComponent } from "../project-form/project-form.component";
import { ProjectFileListComponent } from "../project-file-list/project-file-list.component";
import { ProjectDto, ProjectUpsertDto } from "src/app/shared/generated/model/models";
import { CurrentProjectService } from "src/app/services/current-project/current-project.service";
import { ProjectService } from "src/app/shared/generated/api/project.service";
import { BackToTopComponent } from "../../shared/components/back-to-top/back-to-top.component";
import { EsaMaterialButtonComponent } from "esa-material-form-field";
import { NgIf, AsyncPipe } from "@angular/common";
import { MatButton, MatIconButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { ProjectFileService } from "src/app/shared/generated/api/project-file.service";
import { ProjectFileDto } from "src/app/shared/generated/model/project-file-dto";
import { arrForEach } from "@microsoft/applicationinsights-core-js";

@Component({
    selector: "project-summary-information",
    templateUrl: "./project-summary-information.component.html",
    styleUrls: ["./project-summary-information.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        ProjectFormComponent,
        ProjectFileListComponent,
        BackToTopComponent,
        MatButton,
        MatIcon,
        MatIconButton,
        AsyncPipe,
    ],
})
export class ProjectSummaryInformationComponent implements OnInit, OnDestroy {
    @ViewChild("updateProjectForm", { read: ProjectFormComponent }) form: ProjectFormComponent;

    public project$: Observable<ProjectDto>;
    public project: ProjectDto;
    public currentUser: UserDto;

    public editMode = false;
    public projectUpsertDto: ProjectUpsertDto;

    user: Subscription;
    projectPutSubscription: Subscription;
    projectFileSubscription: Subscription
    
    constructor(
        private projectService: ProjectService,
        private currentProjectService: CurrentProjectService,
        private authenticationService: AuthenticationService,
        private editViewEventService: EditViewEventService,
        private alertService: AlertService,
        private cdr: ChangeDetectorRef,
        private projectFileService: ProjectFileService,
    ) {}

    ngOnInit(): void {
        this.project$ = this.currentProjectService.getCurrentProject().pipe(
            map((data) => {
                this.project = data;
                this.projectUpsertDto = this.currentProjectService.createProjectUpsertDto(this.project);
                this.cdr.markForCheck();
                return data;
            })
        );

        this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.cdr.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.user?.unsubscribe();
        this.projectPutSubscription?.unsubscribe();
        this.projectFileSubscription?.unsubscribe();
    }

    refreshProject(result: any) {
        this.project = result;
        this.editMode = false;
        this.currentProjectService.setCurrentProject(this.project);
        this.editViewEventService.editButtonClicked.next(this.editMode);
        this.cdr.detectChanges();
    }

    save() {
        this.projectPutSubscription = this.projectService.projectsProjectIDPut(this.project.ProjectID, this.form.projectUpsertDto).subscribe((result) => {
            if(this.form.files && this.form.files.length > 0) {
                this.projectFileSubscription = this.projectFileService.projectsProjectIDFilesPost(result.ProjectID, this.form.files).subscribe({
                    next: () => {
                        this.alertService.pushAlert(new Alert("The Project was successfully updated.", AlertContext.Success), 5000);
                        this.refreshProject(result);
                        return result;
                    },
                    error: () => {
                    },
                    complete: () => {
                    }
                });
            } else {
                this.alertService.pushAlert(new Alert("The Project was successfully updated.", AlertContext.Success), 5000);
                this.refreshProject(result);
            }
            return result;
        });
    }

    canEdit(): boolean {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.ProjectRights, RightsEnum.Update) && !this.editMode;
    }

    enableEditMode() {
        this.editMode = true;
        this.editViewEventService.editButtonClicked.next(this.editMode);
        this.cdr.markForCheck();
    }

    cancelEdit() {
        this.editMode = false;
        this.editViewEventService.editButtonClicked.next(this.editMode);
        this.cdr.detectChanges();
    }

    canExit() {
        if (this.editMode) {
            return JSON.stringify(this.projectUpsertDto) === JSON.stringify(this.form.projectUpsertDto);
        } else {
            return true;
        }
    }
}
