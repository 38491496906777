import { Component, Input } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'loading-spinner',
  standalone: true,
  imports: [MatProgressSpinner],
  templateUrl: './loading-spinner.component.html',
  styleUrl: './loading-spinner.component.scss'
})
export class LoadingSpinnerComponent {
  @Input() height: string = 'auto';
  @Input() strokeWidth: number = 10;
  @Input() diameter: number = 100;
  @Input() color: string = 'primary';
}
