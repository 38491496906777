import { AsyncPipe, NgClass, NgFor, NgIf } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatButton, MatIconButton } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatList, MatListItem } from "@angular/material/list";
import { MatIcon } from "@angular/material/icon";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltip } from "@angular/material/tooltip";
import { AgGridAngular } from "ag-grid-angular";
import { ColDef, GetRowIdParams, RowSelectedEvent } from "ag-grid-community";
import { ReplaySubject, Subscription } from "rxjs";
import { Observable } from "rxjs/internal/Observable";
import { filter, map, switchMap, tap } from "rxjs/operators";
import { AuthenticationService } from "src/app/services/authentication.service";
import { CurrentComponentService } from "src/app/services/current-component/current-component.service";
import { LinkRendererComponent } from "src/app/shared/components/ag-grid/link-renderer/link-renderer.component";
import { ClearGridFiltersButtonComponent } from "src/app/shared/components/clear-grid-filters-button/clear-grid-filters-button.component";
import { CustomDropdownFilterComponent } from "src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component";
import { ComponentService } from "src/app/shared/generated/api/component.service";
import { ProjectCommitmentService } from "src/app/shared/generated/api/project-commitment.service";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { CommitmentGridDto } from "src/app/shared/generated/model/commitment-grid-dto";
import { ComponentDto } from "src/app/shared/generated/model/component-dto";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { MatDivider } from "@angular/material/divider";
import { GridActionsComponent } from "src/app/shared/components/ag-grid/grid-actions/grid-actions.component";
import { MaterialChipsRendererComponent } from "src/app/shared/components/ag-grid/material-chips-renderer/material-chips-renderer.component";
import { ButtonRendererComponent } from "src/app/shared/components/ag-grid/button-renderer/button-renderer.component";
import { MatDialog } from "@angular/material/dialog";
import { CommitmentSummaryDialogComponent } from "src/app/shared/components/dialogs/commitment-summary-dialog/commitment-summary-dialog.component";
import { ConfirmService } from "src/app/services/confirm.service";
import { ComponentCommitmentRationaleService } from "src/app/shared/generated/api/component-commitment-rationale.service";
import { ComponentCommitmentRationaleUpsertDto } from "src/app/shared/generated/model/component-commitment-rationale-upsert-dto";

@Component({
    selector: "component-commitment-list-selector",
    templateUrl: "./component-commitment-list-selector.component.html",
    styleUrls: ["./component-commitment-list-selector.component.scss"],
    standalone: true,
    imports: [AgGridAngular, NgIf, NgFor, AsyncPipe, NgClass, ClearGridFiltersButtonComponent, MatButton, MatIcon, MatIconButton, MatTooltip, MatCardModule, MatList, MatListItem, MatDivider, GridActionsComponent, MatIconModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComponentCommitmentListSelectorComponent implements OnInit, OnDestroy {
    @ViewChild("commitmentsGrid") commitmentsGrid: AgGridAngular;

    public commitmentsReplaySubject = new ReplaySubject<CommitmentGridDto[]>(1);
    public commitments$ = this.commitmentsReplaySubject.asObservable();

    public possibleCommitments: CommitmentGridDto[];

    public component: ComponentDto;

    public columnDefs: ColDef[];
    public rowData$: Observable<any[]>;
    public noRowsTemplate: string = `<span>No commitments selected. Click edit to start associating commitments.</span>`;

    private originalallySelectedCommitments: CommitmentGridDto[] = [];
    public selectedCommitments: CommitmentGridDto[] = [];

    public currentUser: UserDto;
    private userSubscription: Subscription;
    private componentPutSubscription: Subscription;
    private componentCommitmentRationalePutSubscription: Subscription;
    private currentComponentSubscription: Subscription = Subscription.EMPTY;

    public editMode: boolean = false;
    public commitmentsLoaded: boolean = false;

    public editedRationales: ComponentCommitmentRationaleUpsertDto[] = [];

    constructor(
        private currentComponentService: CurrentComponentService,
        private componentService: ComponentService,
        private projectCommitmentService: ProjectCommitmentService,
        private authenticationService: AuthenticationService,
        private componentCommitmentRationaleService: ComponentCommitmentRationaleService,
        private alertService: AlertService,
        private cdr: ChangeDetectorRef,
        private matDialog: MatDialog,
        private confirmService: ConfirmService
    ) {}

    ngOnInit(): void {
        this.columnDefs = [
            {
                colId: "SelectionColumn",
                headerName: "Selected",
                flex: 3.5,
                sort: "asc",
                headerCheckboxSelectionFilteredOnly: true,
                valueGetter: function (params: any) {
                    return !params.data.$IsSelected;
                },                
                cellRenderer: params => {
                    return !this.editMode ? `<mat-icon class="mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true">${params.data.$IsSelected ? 'check_box' : ''}</mat-icon>` : null;
                }                    
            },
            {
                field: "Rationale",
                headerName: "Rationale",
                flex: 3.5,
                singleClickEdit: true,
                editable: () => {
                    return this.editMode;
                },
                cellEditor: 'agLargeTextCellEditor',
                cellEditorPopup: true,
                cellEditorParams: {
                    maxLength: 10000
                },
                
                cellStyle: () => {
                    return this.editMode ? { 'border-left': '2px dashed #cce262', 'border-right': '2px dashed #cce262'} : null;
                } ,
                valueGetter: (params) => {
                    return params.data.ComponentCommitmentRationales?.find(x => x.ComponentID == this.component.ComponentID)?.Rationale;
                },
                valueSetter: (params) => {
                    var rationale = params.data.ComponentCommitmentRationales?.find(x => x.ComponentID == this.component.ComponentID);
                    if(!rationale) {
                        params.data.ComponentCommitmentRationales.push({ComponentID: this.component.ComponentID, Rationale: params.newValue});
                    } else {
                        rationale.Rationale = params.newValue;
                    }
                    return true;
                },
            },
            {
                colId: "ID",
                headerName: "ID",
                headerTooltip: "Commitment ID",
                valueGetter: function (params: any) {
                    return {
                        LinkValue: params.data.CommitmentID,
                        LinkDisplay: params.data.ClientCommitmentID,
                        ChildRoute: "summary-information",
                    };
                },
                cellRenderer: LinkRendererComponent,
                cellRendererParams: { inRouterLink: "/commitments/" },
                filterValueGetter: function (params: any) {
                    return params.node.rowPinned ? null : params.data.ClientCommitmentID;
                },
                comparator: function (linkA, linkB, nodeA, nodeB, isDescending) {
                    let valueA = linkA.LinkDisplay.toLowerCase();
                    let valueB = linkB.LinkDisplay.toLowerCase();

                    return valueA.localeCompare(valueB, undefined, {
                        numeric: true,
                        sensitivity: "base",
                    });
                },                
                type: "rightAligned",
                width: 130,
            },
            {
                colId: "Details",
                headerName: "Details",
                cellRenderer: ButtonRendererComponent,
                width:115,
                cellRendererParams: {
                    onClick: (params) => {
                        params.event.stopPropagation();
                        this.matDialog.open(CommitmentSummaryDialogComponent, { width:'800px', data : { CommitmentID: params.rowData.CommitmentID } })
                    },
                    icon: "info",
                },
            },
            {
                headerName: "Title",
                field: "Title",
                tooltipField: "Title",
                flex: 3.5,
            },
            {
                headerName: "Type",
                field: "CommitmentTypeName",
                tooltipField: "CommitmentTypeName",
                flex: 3,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "CommitmentTypeName",
                },
            },
            {
                headerName: "Source",
                headerTooltip: "Source Document Name",
                valueGetter: function (params: any) {
                    return {
                        LinkValue: params.data.SourceData.SourceID,
                        LinkDisplay: params.data.SourceData.Name,
                        ChildRoute: "summary-information",
                    };
                },
                cellRenderer: LinkRendererComponent,
                cellRendererParams: { inRouterLink: "/source-documents/" },
                filterValueGetter: function (params: any) {
                    return params.node.rowPinned ? null : params.data.SourceData.Name;
                },                
                flex: 3,
            },
            {
                headerName: "Resource Category",
                headerTooltip: "Resource Category",
                field: "ResourceCategoryName",
                tooltipField: "ResourceCategoryName",
                flex: 3.25,
            },
            {
                headerName: "Work Activities",
                headerTooltip: "Work Activities",
                cellRenderer: MaterialChipsRendererComponent,
                cellRendererParams: function (params: any) {
                    return {
                        Tags: params.data.WorkActivities,
                    };
                },
                valueGetter: function (params: any) {
                    if(params.data.WorkActivities != ""){
                        return {
                            DownloadDisplay: params.data.WorkActivities?.map(x => x.Name).join(", "),
                        };
                    }
                    else{
                        return {
                            DownloadDisplay: "",
                        };
                    }                    
                },
                filter: CustomDropdownFilterComponent,
                filterParams: {                    
                    columnContainsMultipleValues: true,
                },
                filterValueGetter: function (params: any) {
                    
                    return params.data.WorkActivities ? params.data.WorkActivities?.map(x => x.Name) : [];
                },
                flex: 4,
                autoHeight: true,
            },            
        ];

        this.userSubscription = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.cdr.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.currentComponentSubscription.unsubscribe();
        this.userSubscription?.unsubscribe();
        this.componentPutSubscription?.unsubscribe();
        this.commitmentsReplaySubject?.unsubscribe();
    }

    

    onCommitmentsGridReady(gridEvent: any) {
        this.commitmentsGrid.api.showLoadingOverlay();

        this.currentComponentSubscription = this.currentComponentService
            .getCurrentComponent()
            .pipe(
                filter((component: any) => component !== null), // Filter out null components
                map((component: ComponentDto) => {
                    this.component = component;
                    return component.ProjectRoutingData.ProjectID;
                }),
                switchMap((projectID: string) => this.projectCommitmentService.projectsProjectIDCommitmentsSimpleGet(projectID))
            )
            .subscribe((commitments: CommitmentGridDto[]) => {
                this.possibleCommitments = commitments;
                let selectedCommitmentIDs = this.component.CommitmentsRoutingData.map((commitment) => commitment.CommitmentID);
                this.selectedCommitments = commitments.filter((commitment) => selectedCommitmentIDs.includes(commitment.CommitmentID));
                this.originalallySelectedCommitments = [...this.selectedCommitments];

                if (this.possibleCommitments.length === 0) {
                    this.noRowsTemplate = `<span>No commitments available to select. Please associate commitments to a source that is associated to the project ${this.component.ProjectRoutingData.Name}.</span>`;
                    this.commitmentsGrid.api.showNoRowsOverlay();
                } else if (this.selectedCommitments.length === 0) {
                    this.noRowsTemplate = `<span>No commitments selected. Click edit to start associating commitments.</span>`; //Just make sure the no rows message is correct.
                    this.commitmentsGrid.api.showNoRowsOverlay();
                } else {
                    this.commitmentsGrid.api.hideOverlay();
                }
                this.possibleCommitments.forEach((commitment) => {
                    if(commitment.WorkActivities){
                        commitment.WorkActivities = JSON.parse(commitment.WorkActivities);
                    }
                    else{
                        commitment.WorkActivities = "";
                    }
                });

                this.commitmentsLoaded = true;
                this.cdr.markForCheck();
                this.commitmentsReplaySubject.next(commitments);
            });

        //TODO MK 5/17/2024 -- I tried to refactor this to use refreshRowDataNonEditMode, but it was causing issues and I ran out of time to fix it. If it becomes an issue, we can revisit this.
        this.rowData$ = this.commitments$.pipe(
            map((commitments: any[]) => {
                return commitments
                    .map((commitment) => {
                        let isSelected = this.selectedCommitments
                            .map((selectedCommitment) => selectedCommitment.CommitmentID)
                            .includes(commitment.CommitmentID);
                        commitment.$IsSelected = isSelected;
                        return commitment;
                    });
            }),
            tap((x) => {
                this.commitmentsGrid.api.hideOverlay();
                setTimeout(() => {
                    this.setDefaultFilter();
                    this.component.CommitmentsRoutingData.forEach((commitment) => {
                        const row = this.commitmentsGrid.api.getRowNode(commitment.CommitmentID);
                        row.setSelected(true);
                    });
                });
            })
        );
    }

    setDefaultFilter() {
       

        this.commitmentsGrid.api.onFilterChanged();
        this.commitmentsGrid.api.redrawRows();
    }    

    commitmentSelected(event: RowSelectedEvent) {
        if (event.node.isSelected() && !this.selectedCommitments.includes(event.data)) {
            event.data.$IsSelected = true;
            this.selectedCommitments.push(event.data);
        } else if (!event.node.isSelected() && this.selectedCommitments.includes(event.data)) {
            event.data.$IsSelected = false;
            this.selectedCommitments = this.selectedCommitments.filter((commitment) => commitment.CommitmentID !== event.data.CommitmentID);
        }
    }

    canEdit(): boolean {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.ProjectRights, RightsEnum.Update) && !this.editMode;
    }

    enableEditMode() {
        this.commitmentsGrid.api.showLoadingOverlay();

        // Despite calling showLoadingOverlay, the grid will still show the no rows overlay if the rowData$ observable is empty. Little hack so it doesn't display an incorrect message.
        this.noRowsTemplate = `<span>Loading...</span>`;

        // Have to do this otherwise it will use the values from the last time the observable was subscribed to. Probably indicates a code smell, but I have it working now so moving on.
        let selectedCommitments = this.selectedCommitments;
        let commitmentsGrid = this.commitmentsGrid;

        this.rowData$ = this.commitments$.pipe(
            tap(() => {
                setTimeout(() => {
                    this.setDefaultFilter();
                    this.editMode = true;
                    this.toggleColumnSelection(true);
                    let rowNodes = commitmentsGrid.api.getRenderedNodes();

                    rowNodes.forEach((rowNode) => {
                        rowNode.setSelected(false);
                    });

                    selectedCommitments.forEach((commitment) => {
                        let rowNode = commitmentsGrid.api.getRowNode(commitment.CommitmentID);
                        if (rowNode) {
                            rowNode.setSelected(true);
                        }
                    });

                    this.commitmentsGrid.api.hideOverlay();
                    this.commitmentsGrid.api.redrawRows();
                });
            })
        );
    }

    cancelEditMode(suppresConfirm: Boolean = false, actionName: string = "exit") {
        if (!suppresConfirm && !this.canExit()) {

            this.confirmService.confirm({header: `Unsaved Changes`, text: `Are you sure you want to ${actionName} without saving?` }).subscribe((result) => {
                if(!result) return;
                
                this.selectedCommitments = [...this.originalallySelectedCommitments];
                if (this.selectedCommitments.length === 0) {
                    this.noRowsTemplate = `<span>No commitments selected. Click edit to start associating commitments.</span>`; //Just make sure the no rows message is correct.
                    this.commitmentsGrid.api.showNoRowsOverlay();
                }

                this.refreshRowDataNonEditMode();
                this.cdr.markForCheck(); 
            });
        }
        else
        {
            this.selectedCommitments = [...this.originalallySelectedCommitments];
            if (this.selectedCommitments.length === 0) {
                this.noRowsTemplate = `<span>No commitments selected. Click edit to start associating commitments.</span>`; //Just make sure the no rows message is correct.
                this.commitmentsGrid.api.showNoRowsOverlay();
            }
    
            this.refreshRowDataNonEditMode();
            this.cdr.markForCheck(); 
        }
        
    }

    toggleColumnSelection(allowSelection: boolean) {

        const newColumnDefs = this.columnDefs.map((colDef) => {
            if (colDef.colId === "SelectionColumn") {
                return { ...colDef, checkboxSelection: allowSelection, headerCheckboxSelection: allowSelection };
            }
            return colDef;
        });

        this.columnDefs = newColumnDefs;
        this.commitmentsGrid.api.setColumnDefs(newColumnDefs);

        this.commitmentsGrid.api.refreshHeader(); //Forces the header to refresh, adding or removing the check all checkbox
        this.commitmentsGrid.api.refreshCells({ force: true }); //Forces the ID cell to refresh, adding or removing the checkbox
        this.commitmentsGrid.api.showLoadingOverlay();
    }

    onCellValueChanged(event) {
        
        if(event.oldValue == event.newValue) return;
        var existingIndex = this.editedRationales.findIndex(x => x.CommitmentID == event.data.CommitmentID);
        if(existingIndex != -1){
            this.editedRationales[existingIndex].Rationale = event.newValue;
        } else {
            this.editedRationales.push(this.createComponentCommitmentRationaleDto(event.data.CommitmentID, event.newValue));
        }

        
    }

    save() {
        let componentUpsertDto = this.currentComponentService.createComponentDto(this.component);
        componentUpsertDto.CommitmentIDs = this.selectedCommitments.map((commitment) => commitment.CommitmentID);

        this.componentService.componentsComponentIDPut(this.component.ComponentID, componentUpsertDto).subscribe((result) => {
            this.alertService.pushAlert(new Alert("The component was successfully updated.", AlertContext.Success), 5000);
            this.component = result;
            this.currentComponentService.setCurrentComponent(this.component);
            let selectedCommitmentIDs = this.component.CommitmentsRoutingData.map((commitment) => commitment.CommitmentID);
            this.selectedCommitments = this.possibleCommitments.filter((commitment) => selectedCommitmentIDs.includes(commitment.CommitmentID));
            this.originalallySelectedCommitments = [...this.selectedCommitments];
            this.editMode = false;

            if (this.component.CommitmentsRoutingData.length === 0) {
                this.noRowsTemplate = `<span>No commitments selected. Click edit to start associating commitments.</span>`; //Just make sure the no rows message is correct.
                this.commitmentsGrid.api.showNoRowsOverlay();
            }

            this.refreshRowDataNonEditMode();
            this.cdr.markForCheck(); 
        });
        
        if(this.editedRationales.length > 0){
            this.componentCommitmentRationalePutSubscription = this.componentCommitmentRationaleService.componentsComponentIDComponentCommitmentRationalesPut(this.component.ComponentID, this.editedRationales).subscribe((result) => {
                this.editedRationales = [];
            });
        }

    }

    refreshRowDataNonEditMode() {
        let selectedCommitments = this.selectedCommitments;
        let commitmentsGrid = this.commitmentsGrid;

        this.rowData$ = this.commitments$.pipe(
            map((commitments: any[]) => {
                return commitments
                    .map((commitment) => {
                        let isSelected = this.selectedCommitments
                            .map((selectedCommitment) => selectedCommitment.CommitmentID)
                            .includes(commitment.CommitmentID);
                        commitment.$IsSelected = isSelected;
                        return commitment;
                    });                 
            }),
            tap(() => {
                this.editMode = false;
                this.toggleColumnSelection(false);
                commitmentsGrid.api.redrawRows();
                commitmentsGrid.api.hideOverlay();

                setTimeout(() => {
                    this.setDefaultFilter();
                    let rowNodes = commitmentsGrid.api.getRenderedNodes();

                    rowNodes.forEach((rowNode) => {
                        rowNode.setSelected(false);
                    });

                    selectedCommitments.forEach((commitment) => {
                        let rowNode = commitmentsGrid.api.getRowNode(commitment.CommitmentID);
                        if (rowNode) {
                            rowNode.setSelected(true);
                        }
                    });
                });
            })
        );
    }

    canExit() {
        if (this.editMode) {
            return JSON.stringify(this.originalallySelectedCommitments) === JSON.stringify(this.selectedCommitments);
        } else {
            return true;
        }
    }


    getRowNodeId(rowIdParams: GetRowIdParams<any>) {
        return rowIdParams.data.CommitmentID;
    }

    createComponentCommitmentRationaleDto(commitmentID: string, rationale: string) {
        let componentCommitmentRationaleUpsertDto = new ComponentCommitmentRationaleUpsertDto({
            CommitmentID: commitmentID,
            Rationale: rationale,
        });

        return componentCommitmentRationaleUpsertDto;
    }
}
