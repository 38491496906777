<div class="observations-wrapper" *ngIf="observations">

    <h2 class="section-header">OBSERVATIONS <span>({{observations.length}} TOTAL)</span></h2>

    <mat-accordion #observationAccordion [multi]="true">
        <mat-expansion-panel (opened)="panelOpened(observation)" (closed)="panelClosed(observation)" #observationExpanionPanel [id]="observation['_record_id']" *ngFor="let observation of observations">
            <mat-expansion-panel-header style="align-items: center; justify-content: space-between">
                <div style="margin-right: 1rem; align-self: center;">
                    <mat-icon *ngIf="observation['follow_up_yes_no'] === 'no'" style="color: #04608d">visibility</mat-icon>
                    <mat-icon *ngIf="observation['follow_up_yes_no'] === 'yes' && observation['resolved'] === 'no'" style="color: red">radio_button_unchecked</mat-icon>
                    <mat-icon *ngIf="observation['follow_up_yes_no'] === 'yes' && observation['resolved'] === 'yes'" style="color: green">check_circle</mat-icon>
                </div>
                <div style="width: 80%">
                    <mat-card-title
                        *ngIf="observation['follow_up_yes_no'] === 'no'"
                        matTooltipShowDelay="500"
                        matTooltipPosition="above"
                        matTooltip="{{ observation['general_activity'] }} - {{ observation['location_of_observation'] }}">
                        {{ observation["general_activity"] }} - {{ observation["location_of_observation"] }}
                    </mat-card-title>
                    <mat-card-title
                        *ngIf="observation['follow_up_yes_no'] === 'yes'"
                        matTooltipShowDelay="500"
                        matTooltipPosition="above"
                        matTooltip="{{ observation['category'] }} - {{ observation['location_of_observation'] }} - {{ observation['initial_date_of_trackable'] | date: 'longDate':'UTC' }}">
                        <mat-icon *ngIf="observation['condition'] === 'Concern'" style="font-size: 16px; height: 16px; color: #ffab00">warning</mat-icon>
                        {{ observation["category"] }} - {{ observation["location_of_observation"] }} - {{ observation["initial_date_of_trackable"] | date: "longDate":"UTC" }}
                    </mat-card-title>
                    <mat-card-subtitle style="margin-bottom: 8px">
                        <span *ngIf="observation['follow_up_yes_no'] === 'yes'">Trackable ID: {{ observation["trackable_id"] }}</span>
                        <span *ngIf="observation['follow_up_yes_no'] === 'no'">{{ observation["_title"] }}</span>
                    </mat-card-subtitle>
                </div>
            </mat-expansion-panel-header>
            
            <ng-template matExpansionPanelContent>
                <daily-monitoring-report-observation-details [observation]="observation" [reportDate]="dailyMonitoringReport['date_general']"></daily-monitoring-report-observation-details>
            </ng-template>
        </mat-expansion-panel>
    </mat-accordion>
</div>
