import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CurrentCommitmentService } from "src/app/services/current-commitment/current-commitment.service";
import { CommitmentVersionService } from "src/app/shared/generated/api/commitment-version.service";
import { CommitmentDto } from "src/app/shared/generated/model/models";
import { BackToTopComponent } from "../../shared/components/back-to-top/back-to-top.component";
import { RevisionHistoryDetailsCardComponent } from "./revision-history-details-card/revision-history-details-card.component";
import { NgIf, NgFor, AsyncPipe } from "@angular/common";

@Component({
    selector: "commitment-revision-history",
    templateUrl: "./commitment-revision-history.component.html",
    styleUrls: ["./commitment-revision-history.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        RevisionHistoryDetailsCardComponent,
        BackToTopComponent,
        AsyncPipe,
    ],
})
export class CommitmentRevisionHistoryComponent implements OnInit {
    public commitment$: Observable<any>;
    public commitment: CommitmentDto;
    public commitmentVersions$: Observable<any>;

    constructor(
        private currentCommitmentService: CurrentCommitmentService,
        private commitmentVersionService: CommitmentVersionService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.commitment$ = this.currentCommitmentService.getCurrentCommitment().pipe(
            map((data) => {
                this.commitment = data;
                if (this.commitment) {
                    this.getData();
                }
                return data;
            })
        );
    }

    getData() {
        this.commitmentVersions$ = this.commitmentVersionService.commitmentVersionsCommitmentVersionChainIDGet(this.commitment.CommitmentVersionChainID);
        this.cdr.markForCheck();
    }
}
