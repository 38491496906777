import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { FileService } from 'src/app/services/file/file.service';

@Component({
  selector: 'simple-file-display',
  standalone: true,
  imports: [MatIcon, MatButton, MatIconButton],
  templateUrl: './simple-file-display.component.html',
  styleUrl: './simple-file-display.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleFileDisplayComponent {

  @Input() downloadUrl: string;
  @Input() fileName: string;
  @Input() isNotYetUploaded: boolean = false; 
  @Input() displayDelete: boolean = false; 
  public isDownloading: boolean = false;

  @Output() deleteFile = new EventEmitter<void>();
  constructor(private fileService: FileService, private cdr: ChangeDetectorRef) { }

  clickedClose() {
    this.deleteFile.emit();
  }

  clickedDownload() {
    if(this.downloadUrl && !this.isDownloading) {
      this.isDownloading = true;
      this.fileService.downloadFile(this.downloadUrl).subscribe({
        next: (response) => {
          if(response) {
            this.fileService.handleResponse(response, this.fileName);
          }
        },
        error: (error) => this.isDownloading = false,
        complete: () => {
          this.isDownloading = false
          this.cdr.markForCheck();
        }
      });
    }
  }
}
