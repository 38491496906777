import { Component, Input } from '@angular/core';
import { ChecklistItemStatusEnum } from '../../generated/model/checklist-item-status-enum';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { ChecklistItemStatusDto } from '../../generated/model/checklist-item-status-dto';

@Component({
  selector: 'checklist-item-status',
  standalone: true,
  imports: [MatIcon, MatTooltip],
  templateUrl: './checklist-item-status.component.html',
  styleUrl: './checklist-item-status.component.scss'
})
export class ChecklistItemStatusComponent {
  @Input() checklistItemStatus: ChecklistItemStatusDto;
  public ChecklistItemStatusEnum = ChecklistItemStatusEnum;
}
