import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "src/app/services/authentication.service";
import { MatIcon } from "@angular/material/icon";
import { MatButton } from "@angular/material/button";

@Component({
    selector: "unauthenticated",
    templateUrl: "./unauthenticated.component.html",
    styleUrls: ["./unauthenticated.component.scss"],
    standalone: true,
    imports: [MatButton, MatIcon],
})
export class UnauthenticatedComponent implements OnInit {
    constructor(private localAuthService: AuthenticationService) {}

    ngOnInit() {}

    login() {
        this.localAuthService.loginRedirct();
    }
}
