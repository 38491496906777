<div class="checklist-item-status-display-and-upsert">
    <div class="checklist-item-status-display-and-upsert__header">
        <h3>Status</h3>
        @if(isEditMode) {
            <button mat-icon-button color="primary" class="small-icon-button" (click)="toggleEditMode(false)" matTooltip="Cancel Editing"><mat-icon>close</mat-icon></button>
        } @else {
            <button mat-flat-button color="primary" (click)="toggleEditMode(true)" matTooltip="Edit Checklist Item Status"><mat-icon>edit</mat-icon>Edit Status</button>
        }
    </div>
    @if(checklistItem$ | async ; as checklistItem) {
        @if(isEditMode) {
            <form [formGroup]="formGroup" (ngSubmit)="save()">
                <kv-pair key="Status" [value]="statusField">
                    <ng-template #statusField>
                        <mat-form-field>
                            <mat-select formControlName="ChecklistItemStatusID" placeholder="Status">
                                <mat-option *ngFor="let status of checklistItemStatuses$ | async" [value]="status.ChecklistItemStatusID">{{status.Name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-template>              
                </kv-pair>
                <div class="form-footer">
                    <button mat-button color="primary" tyle="button" [disabled]="formGroup.invalid || isLoading" (click)="toggleEditMode(false)"><mat-icon>close</mat-icon> Cancel</button>
                    <button mat-flat-button color="primary" [disabled]="formGroup.invalid || isLoading"><mat-icon>save</mat-icon> Save</button>
                </div>
                @if(isLoading) {
                    <beacon-loading-overlay>
                        <loading-spinner color="accent" [diameter]="50" />
                    </beacon-loading-overlay>
                }
            </form>
        } @else {
            <kv-pair key="Status" [value]="checklistItem.Status.Name"></kv-pair>
        }

    }
</div>
