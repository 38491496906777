import { Component, NgZone } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { AgRendererComponent } from "ag-grid-angular";
import { DropdownToggleDirective } from "../../../directives/dropdown-toggle.directive";
import { NgIf, NgFor } from "@angular/common";

@Component({
    selector: "manitobaefp-context-menu-renderer",
    templateUrl: "./context-menu-renderer.component.html",
    styleUrls: ["./context-menu-renderer.component.scss"],
    standalone: true,
    imports: [
        NgIf,
        DropdownToggleDirective,
        NgFor,
        RouterLink,
    ],
})
export class ContextMenuRendererComponent implements AgRendererComponent {
    params: any;

    constructor(private ngZone: NgZone, private router: Router) {}

    agInit(params: any): void {
        if (params.value === null) {
            this.params.value = [{ ActionName: "", ActionLink: "" }];
        } else {
            this.params = params;
        }

        this.params.title = params.title || "Actions";
    }

    refresh(params: any): boolean {
        return false;
    }
}
