import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogClose } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { UserService } from 'src/app/shared/generated/api/user.service';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { CommonModule, AsyncPipe } from '@angular/common';
import { MatOption } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ChecklistTaskUpsertDto } from 'src/app/shared/generated/model/checklist-task-upsert-dto';
import {provideNativeDateAdapter} from '@angular/material/core';
import { CustomFormLabelComponent } from "../../custom-form-label/custom-form-label.component";
import { EsaLabelComponent, EsaMaterialFormFieldComponent } from 'esa-material-form-field';
import { FormGroupOf } from 'src/app/shared/forms/form-group-of';
import { MatTooltip } from '@angular/material/tooltip';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'checklist-task-dialog',
  standalone: true,
  templateUrl: './checklist-task-dialog-component.html',
  styleUrls: ['./checklist-task-dialog-component.scss'],
  imports: [
    MatOption,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDialogClose,
    MatDatepickerModule,
    MatSelectModule,
    FormsModule,
    AsyncPipe,
    CommonModule,
    ReactiveFormsModule,
    CustomFormLabelComponent,
    EsaMaterialFormFieldComponent,
    EsaLabelComponent,
    MatTooltip
  ],
  providers: [provideNativeDateAdapter()],
})
export class ChecklistTaskDialogComponent {
  public validators = Validators;
  currentUser$: Observable<UserDto>;
  users$: Observable<UserDto[]>;
  checklistTaskFormGroup: FormGroup<FormGroupOf<ChecklistTaskUpsertDto>> = this.fb.group<FormGroupOf<ChecklistTaskUpsertDto>>({
    ChecklistTaskID: this.fb.control(this.data.checklistTask.ChecklistTaskID),
    ChecklistItemID: this.fb.control(this.data.checklistTask.ChecklistItemID, [Validators.required]),
    TaskName: this.fb.control(this.data.checklistTask.TaskName ?? null, [Validators.required]),
    Description: this.fb.control(this.data.checklistTask.Description ?? null),
    AssignedTo: this.fb.control(this.data.checklistTask.AssignedTo ?? null),
    OwnerUserID: this.fb.control(this.data.checklistTask.OwnerUserID ?? null, [Validators.required]),
    DueDate: this.fb.control(this.data.checklistTask.DueDate ? new Date(this.data.checklistTask.DueDate).toISOString() : null, [Validators.required]),
  });

  constructor(
    public dialogRef: MatDialogRef<ChecklistTaskDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { checklistTask: ChecklistTaskUpsertDto },
    private userService: UserService,
    private fb: FormBuilder,
    private authenticationService: AuthenticationService
  ) {    
    this.users$ = this.userService.usersGet().pipe(map((data: any) => data.filter((user: any) => !user.IsClientUser)));
    this.currentUser$ = this.authenticationService.getCurrentUser().pipe(tap(x => {
      if(this.checklistTaskFormGroup.controls.OwnerUserID.value == null) {
        this.checklistTaskFormGroup.controls.OwnerUserID.setValue(x.UserID);
      }
    }));
  }
}