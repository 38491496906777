<div class="detail-view">
    <div class="page-header">
        <h1 class="page-title">New Compliance Requirement</h1>
    </div>

    <ng-container *ngIf="commitment$ | async as commitment">
        <ng-container *ngIf="allComplianceRequirementTags$ | async as tags">
            <compliance-requirement-form
                #createNewComplianceRequirementForm
                [commitment]="commitment"
                [editMode]="true"
                [allComplianceRequirementTags]="tags"
                (formSubmitted)="save($event)"
                (cancelEditModeChange)="cancelEdit()"></compliance-requirement-form>
        </ng-container>
    </ng-container>
</div>
