@if(checklistItemStatus){
    @switch (checklistItemStatus.ChecklistItemStatusID) {
        @case (ChecklistItemStatusEnum.NUMBER_1) {
            <mat-icon [matTooltip]="checklistItemStatus.Name" class="not-started">schedule</mat-icon>
        }
        @case (ChecklistItemStatusEnum.NUMBER_2) {
            <mat-icon [matTooltip]="checklistItemStatus.Name" color="accent" class="in-progress">published_with_changes</mat-icon>
        }
        @case (ChecklistItemStatusEnum.NUMBER_3) {
            <mat-icon [matTooltip]="checklistItemStatus.Name" class="done">check_circle_outline</mat-icon>
        }
    }
}
