import { Directive, Input, ElementRef, HostListener, HostBinding, Renderer2 } from "@angular/core";

@Directive({
    selector: "[dropdownToggle]",
    standalone: true,
})
export class DropdownToggleDirective {
    private classString: string = "active";
    @Input() dropdownToggle: any;

    @HostBinding("class.active") showMenu: boolean = false;

    @HostListener("click", ["$event"]) onClick(event) {
        this.showMenu = !this.showMenu;
        this.toggleMenu();
    }

    constructor(private el: ElementRef, private renderer: Renderer2) {
        this.renderer.listen("window", "click", (e: Event) => {
            if (
                e.target !== this.el.nativeElement &&
                !this.el.nativeElement.contains(e.target) &&
                e.target !== this.dropdownToggle &&
                !this.dropdownToggle.contains(e.target)
            ) {
                this.showMenu = false;
                this.toggleMenu();
            }
        });
    }

    toggleMenu() {
        if (this.showMenu) {
            this.renderer.addClass(this.dropdownToggle, this.classString);
        } else {
            this.renderer.removeClass(this.dropdownToggle, this.classString);
        }
    }
}
