<div class="simple-file-display">
    <div class="simple-file-display__icon">
        @if(isNotYetUploaded) {
            <mat-icon color="primary">cloud_upload</mat-icon>
        } @else {
            <mat-icon>description</mat-icon>
        }
    </div>
    <div class="simple-file-display__name" [title]="fileName">
        {{fileName}}
    </div>
    <div class="simple-file-display__buttons">
        @if(!isNotYetUploaded) {
            <button mat-icon-button type="button" color="primary" class="small-icon-button" (click)="clickedDownload()" [disabled]="isDownloading"><mat-icon>download</mat-icon></button>
        }
        @if(displayDelete){
            <button mat-icon-button type="button" color="warn" class="small-icon-button" (click)="clickedClose()"><mat-icon>close</mat-icon></button>
        }
    </div>
</div>
