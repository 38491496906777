import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialog, ConfirmDialogOptions } from "../shared/components/confirm-dialog/confirm-dialog.component";
import { map, Observable } from "rxjs";

/**
 * A confirmation service, allowing to open a confirmation modal from anywhere and get back a promise.
 */
@Injectable({
    providedIn: "root",
})
export class ConfirmService {
     constructor(public dialog: MatDialog) {}
    /**
     * Opens a confirmation modal
     * @param options the options for the modal (title and message)
     * @returns {Observable<boolean>} an observable that is fulfilled when the user chooses to confirm
     * or closes the modal
     */
     confirm(options: ConfirmDialogOptions): Observable<boolean> {
        const dialogRef = this.dialog.open(ConfirmDialog, {
            data: {
                header: options.header ?? "Are you sure?",
                text: options.text ?? "You have unsaved changes! If you leave, your changes will be lost.",
                icon: options.icon ?? "",
                color: options.color ?? "primary"
            },
            maxWidth: "450px",
        });
        return dialogRef.afterClosed().pipe(map((result) => result === true));
     }
}


