/**
 * Beacon.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export class CommitmentUpsertDto { 
    ClientCommitmentID: string;
    Title: string;
    CommitmentTypeID: string;
    SourceID: string;
    FullCommitmentText: string;
    ResourceCategoryID?: string;
    TechnicalLeadIDs?: Array<number>;
    Seasonality?: string;
    WorkActivities?: string;
    LocationDescription?: string;
    GeneralGuidance?: string;
    OriginatorOfChange?: string;
    SummaryOfChange?: string;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}
