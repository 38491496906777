<div class="site">
    <div class="header-wrapper">
        <ng-container *ngIf="component">
            <div class="component-nav" matTooltip="Save or cancel edits to access options." [matTooltipDisabled]="!editMode">
                <button mat-raised-button #profileMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" color="accent" [disabled]="editMode">
                    <mat-icon>expand_more</mat-icon>&nbsp;Options
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="delete()" *ngIf="canDelete()">
                        <mat-icon>delete</mat-icon>
                        <span>Delete</span>
                    </button>
                </mat-menu>
            </div>

            <div class="component-title">
                <h3 class="component-title-item">COMPONENT</h3>
                <h1 class="component-title-item">{{ component?.Name }}</h1>
                <h4 class="component-title-item">
                    {{ component.ProjectRoutingData.Name }}
                </h4>
                <p class="component-title-item">STATUS: {{ component.Status.Name | uppercase }}</p>
            </div>
        </ng-container>
    </div>

    <sidebar-nav [sideNavItems]="componentSideNav"></sidebar-nav>
</div>
