import { NgIf, UpperCasePipe } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { MatButton, MatIconButton } from "@angular/material/button";
import { MatMenu, MatMenuItem, MatMenuTrigger } from "@angular/material/menu";
import { MatIcon } from "@angular/material/icon";
import { MatTooltip } from "@angular/material/tooltip";
import { Router, ActivatedRoute } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";
import { CurrentComponentService } from "src/app/services/current-component/current-component.service";
import { EditViewEventService } from "src/app/services/edit-view-event.service";
import { ISidebarNavItem, SidebarNavComponent } from "src/app/shared/components/sidebar-nav/sidebar-nav.component";
import { ComponentService } from "src/app/shared/generated/api/component.service";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { ComponentDto } from "src/app/shared/generated/model/models";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { ConfirmService } from "src/app/services/confirm.service";

@Component({
    selector: "component-details",
    templateUrl: "./component-details.component.html",
    styleUrls: ["./component-details.component.scss"],
    standalone: true,
    imports: [SidebarNavComponent, MatButton, MatIcon, MatIconButton, NgIf, MatTooltip, MatMenu, MatMenuTrigger, MatMenuItem, UpperCasePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class ComponentDetailsComponent implements OnInit, OnDestroy {
    public component$: Observable<ComponentDto>;
    public component?: ComponentDto | undefined;
    public currentUser: UserDto;
    public editMode: boolean;
    componentSideNav: ISidebarNavItem[];

    editViewButtonClicked: Subscription;
    user: Subscription;
    deleteSubscription: Subscription;

    constructor(
        private componentService: ComponentService,
        private currentComponentService: CurrentComponentService,
        private authenticationService: AuthenticationService,
        private editViewEventService: EditViewEventService,
        private alertService: AlertService,
        private router: Router,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        private confirmService: ConfirmService
    ) {
        this.componentSideNav = [
            {
                Name: "Summary Information",
                Link: "summary-information",
            },
            {
                Name: "Associated Commitments",
                Link: "commitments",
            },
            {
                Name: "Checklists",
                Link: "checklists",
            }
        ];
    }

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            let componentID = params.id;
            this.component = null;
            this.currentComponentService.setCurrentComponent(null);

            this.componentService.componentsComponentIDGet(componentID).subscribe((result) => {
                this.component = result;
                this.currentComponentService.setCurrentComponent(this.component);
                this.cdr.markForCheck();
            });
        });

        this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.cdr.markForCheck();
        });

        this.editViewButtonClicked = this.editViewEventService.listenForEditButtonClicked().subscribe((result) => {
            this.editMode = result;
            this.cdr.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.user?.unsubscribe();
        this.editViewButtonClicked?.unsubscribe();
        this.deleteSubscription?.unsubscribe();
    }

    canDelete() {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.ProjectRights, RightsEnum.Delete);
    }

    delete() {
        this.confirmService.confirm({color:'warn', header: `Delete Component`, text: `Are you sure you want to delete this component? This action cannot be undone.` }).subscribe((result) => {
            if(!result) return;
            this.deleteSubscription = this.componentService.componentsComponentIDDelete(this.component.ComponentID).subscribe((result) => {
                this.alertService.pushAlert(new Alert("The component was successfully deleted.", AlertContext.Success), 5000);
                this.router.navigate(["/components"]);
            });
        });
    }
}
