import { Component, Inject } from "@angular/core";
import { MatButton } from "@angular/material/button";
import { MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from "@angular/material/dialog";
import { MatIcon } from "@angular/material/icon";


@Component({
    selector: "confirm-dialog",
    templateUrl: "confirm-dialog.component.html",
    styleUrls: ["./confirm-dialog.component.scss"],
    standalone: true,
    imports: [
        MatDialogTitle,
        MatDialogContent,
        MatDialogActions,
        MatDialogClose,
        MatButton,
        MatIcon
    ],
})
export class ConfirmDialog {
    constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogOptions) {
    }
}

export interface ConfirmDialogOptions {
    header: string;
    text: string;
    icon?: string;
    color?: string;
}