<div class="main">
    <div class="page-header">
        <div class="actions-bar flex-between">
            <h1 class="page-title">Daily Monitoring Reports</h1>
        </div>
        <div class="g-col-6">
            <p>View all daily monitoring reports.</p>
        </div>
    </div>
    <div class="table-responsive">
        <beacon-grid-actions [grid]="dailyMonitoringReportsGrid" downloadFileName="daily-monitoring-reports"></beacon-grid-actions>
        <ag-grid-angular
            #dailyMonitoringReportsGrid
            style="width: 100%; height: 100%"
            class="ag-theme-material"
            [rowData]="rowData"
            [columnDefs]="columnDefs"
            
            [suppressMenuHide]="true"
            (gridReady)="onDailyMonitoringReportsGridReady($event)">
        </ag-grid-angular>
    </div>
</div>
