export const environment = {
    production: false,
    staging: true,
    dev: false,
    mainAppApiUrl: "https://beacon-api-v1.esa-qa.sitkatech.com",
    createAccountRedirectUrl: "https://beacon.esa-qa.sitkatech.com/create-user-callback",
    platformLongName: "Beacon",
    appInsightsInstrumentationKey: "219edc22-6faa-46f4-816c-512a62c5199d",
    datadogClientToken: "pub106a54cb7e4ce61c7b662565830f2cf6"
};
