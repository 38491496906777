import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ProjectDto, ProjectUpsertDto } from "src/app/shared/generated/model/models";

@Injectable({
    providedIn: "root",
})
export class CurrentProjectService {
    currentProject$ = new BehaviorSubject<ProjectDto>(null);

    constructor() {}

    setCurrentProject(commitment: ProjectDto): void {
        this.currentProject$.next(commitment);
    }

    getCurrentProject(): Observable<ProjectDto> {
        return this.currentProject$.asObservable();
    }

    createProjectUpsertDto(project: ProjectDto) {
        const projectDto = new ProjectUpsertDto({
            Name: project?.Name,
            StartDate: project?.StartDate ? new Date(project.StartDate) : null,
            EndDate: project?.EndDate ? new Date(project.EndDate) : null,
            Description: project?.Description,
            Location: project?.Location,
        });

        return projectDto;
    }
}
