<page-header icon="checklist" [templateRight]="templateRight">
    <ng-template #templateRight>
        <button mat-flat-button (click)="openUpsertModal()" color="primary"><mat-icon>add</mat-icon> Add Checklist </button>
    </ng-template>
</page-header>

@if(componentData$ | async) {
    <section class="page-body" cdkDropListGroup>
        
        <!-- FILTERS -->
        @if(filterOptions$ | async; as filterOptions){
            <div class="filter-wrapper">
                <h2>Filter(s)</h2>
                <div class="filters" [formGroup]="filterFormGroup">
                    <mat-form-field subscriptSizing="dynamic">
                        <mat-label>Phase</mat-label>
                        <mat-select formControlName="phase" [multiple]="true">
                            <mat-option *ngFor="let phase of filterOptions.phases" [value]="phase.PhaseID">{{phase.Name}}</mat-option>
                        </mat-select>
                        @if (filterFormGroup.get('phase').value.length > 0) {
                            <button matSuffix mat-icon-button aria-label="Clear" (click)="filterFormGroup.get('phase').reset()">
                              <mat-icon>close</mat-icon>
                            </button>
                        }
                    </mat-form-field>
                    <mat-form-field subscriptSizing="dynamic">
                        <mat-label>Scope</mat-label>
                        <mat-select formControlName="scope" [multiple]="true">
                            <mat-option *ngFor="let scope of filterOptions.scopes" [value]="scope.ScopeID">{{scope.Name}}</mat-option>
                        </mat-select>
                        @if (filterFormGroup.get('scope').value.length > 0) {
                            <button matSuffix mat-icon-button aria-label="Clear" (click)="filterFormGroup.get('scope').reset()">
                              <mat-icon>close</mat-icon>
                            </button>
                        }
                    </mat-form-field>
                    <mat-form-field subscriptSizing="dynamic">
                        <mat-label>Frequency</mat-label>
                        <mat-select formControlName="frequency" [multiple]="true">
                            <mat-option *ngFor="let frequency of filterOptions.frequencies" [value]="frequency.FrequencyID">{{frequency.Name}}</mat-option>
                        </mat-select>
                        @if (filterFormGroup.get('frequency').value.length > 0) {
                            <button matSuffix mat-icon-button aria-label="Clear" (click)="filterFormGroup.get('frequency').reset()">
                              <mat-icon>close</mat-icon>
                            </button>
                        }
                    </mat-form-field>
                    <mat-form-field subscriptSizing="dynamic">
                        <mat-label>Compliance Requirement Type</mat-label>
                        <mat-select formControlName="complianceRequirementType" [multiple]="true">
                            <mat-option *ngFor="let complianceRequirementType of filterOptions.complianceRequirementTypes" [value]="complianceRequirementType.ComplianceRequirementTypeID">{{complianceRequirementType.Name}}</mat-option>
                        </mat-select>
                        @if (filterFormGroup.get('complianceRequirementType').value.length > 0) {
                            <button matSuffix mat-icon-button aria-label="Clear" (click)="filterFormGroup.get('complianceRequirementType').reset()">
                              <mat-icon>close</mat-icon>
                            </button>
                        }
                    </mat-form-field>
                    <mat-form-field subscriptSizing="dynamic">
                        <mat-label>Resource Category</mat-label>
                        <mat-select formControlName="resourceCategory" [multiple]="true">
                            <mat-option *ngFor="let resourceCategory of filterOptions.resourceCategories" [value]="resourceCategory.ResourceCategoryID">{{resourceCategory.Name}}</mat-option>
                        </mat-select>
                        @if (filterFormGroup.get('resourceCategory').value.length > 0) {
                            <button matSuffix mat-icon-button aria-label="Clear" (click)="filterFormGroup.get('resourceCategory').reset()">
                              <mat-icon>close</mat-icon>
                            </button>
                        }
                    </mat-form-field>
                    <mat-form-field subscriptSizing="dynamic">
                        <mat-label>Status</mat-label>
                        <mat-select formControlName="status" [multiple]="true">
                            <mat-option *ngFor="let status of filterOptions.statuses" [value]="status.ChecklistItemStatusID">{{status.Name}}</mat-option>
                        </mat-select>
                        @if (filterFormGroup.get('status').value.length > 0) {
                            <button matSuffix mat-icon-button aria-label="Clear" (click)="filterFormGroup.get('status').reset()">
                              <mat-icon>close</mat-icon>
                            </button>
                        }
                    </mat-form-field>
                    <mat-form-field subscriptSizing="dynamic">
                        <mat-label>Search</mat-label>
                        <input matInput formControlName="search" placeholder="Search...">
                        @if (filterFormGroup.get('search').value) {
                            <button matSuffix mat-icon-button aria-label="Clear" (click)="filterFormGroup.get('search').reset()">
                              <mat-icon>close</mat-icon>
                            </button>
                        }
                    </mat-form-field>
                    @if(filterActive) {
                        <button mat-button color="primary" (click)="filterFormGroup.reset()">Clear Filters</button>
                    }
                </div>
            </div>
        }
        
        <!-- CHECKLISTS -->
        <ng-container *ngIf="checklists.length > 0 else noChecklists">
            <div *ngFor="let checklist of checklists" class="checklist">
                <mat-expansion-panel expanded togglePosition="before">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            @if(isEditing){
                                <button mat-flat-button color="primary" (click)="selectAllChecklistItems($event, checklist.ChecklistItems)">
                                    Select all {{filterActive ? '(filtered)': ''}}
                                </button>
                            }
                            <mat-icon matTooltip="Checklist">checklist</mat-icon> 
                            <span>
                                {{checklist.Name}} (Viewing {{checklist.ChecklistItems | checklistItemVisibleCount: filterFormGroup.value}} of {{checklist.ChecklistItems.length}})
                            </span>
                        </mat-panel-title>
                        <mat-panel-description>
                            <div class="panel-description">
                                <span>{{checklist.Description}}</span>
                                <div class="panel-description-buttons">
                                    <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()"><mat-icon>settings</mat-icon></button>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item  (click)="openUpsertModal(checklist.ChecklistID)" color="primary"><mat-icon>edit</mat-icon> Edit</button>
                                        <button mat-menu-item  color="primary" (click)="deleteChecklist(checklist)"><mat-icon>delete</mat-icon> Delete </button>
                                    </mat-menu>
                                </div>
                            </div>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="drop-box" cdkDropList (cdkDropListDropped)="drop($event)" [cdkDropListData]="checklist.ChecklistItems">
                        @for(checklistItem of checklist.ChecklistItems; track checklistItem) { 
                            @if(checklistItem | checklistItemVisible: filterFormGroup.value) {
                                @if(isEditing) {
                                    <checklist-item cdkDrag 
                                        class="is-editing"
                                        [cdkDragData]="checklistItem"
                                        [checklistItemStatus]="checklistItem.Status"
                                        [complianceRequirementType]="checklistItem.ComplianceRequirement.ComplianceRequirementType?.Name" 
                                        [phase]="checklistItem.ComplianceRequirement.Phase?.Name" 
                                        [scope]="checklistItem.ComplianceRequirement.Scope?.Name" 
                                        [frequency]="checklistItem.ComplianceRequirement.Frequency?.Name" 
                                        [complianceRequirementName]="checklistItem.ComplianceRequirement.Name" 
                                        [resourceCategory]="checklistItem.ComplianceRequirement.Commitment?.ResourceCategory?.Name" 
                                        [linkToChecklistItem]="false"
                                        [clientCommitmentID]="checklistItem.ComplianceRequirement.Commitment.ClientCommitmentID">
                                        <div class="drop-placeholder" *cdkDragPlaceholder></div>
                                        <mat-checkbox (change)="changedChecklistItemSelection($event.checked, checklistItem)" [checked]="checklistItem | objectInArray: selectedChecklistItems"></mat-checkbox>
                                    </checklist-item>
                                } @else {
                                    <checklist-item 
                                        [checklistItemStatus]="checklistItem.Status"
                                        [checklistItemID]="checklistItem.ChecklistItemID"
                                        [complianceRequirementType]="checklistItem.ComplianceRequirement.ComplianceRequirementType?.Name" 
                                        [phase]="checklistItem.ComplianceRequirement.Phase?.Name" 
                                        [scope]="checklistItem.ComplianceRequirement.Scope?.Name" 
                                        [frequency]="checklistItem.ComplianceRequirement.Frequency?.Name" 
                                        [complianceRequirementName]="checklistItem.ComplianceRequirement.Name" 
                                        [resourceCategory]="checklistItem.ComplianceRequirement.Commitment?.ResourceCategory?.Name" 
                                        [linkToChecklistItem]="true"
                                        [clientCommitmentID]="checklistItem.ComplianceRequirement.Commitment.ClientCommitmentID">
                                    </checklist-item>
                                }
                            }
                        }
                        @if(checklist.ChecklistItems.length == 0) {
                            <div class="drop-box-overlay">
                                <span>
                                    @if(!isEditing) {
                                        Enable editing to add compliance requirements to this checklist.
                                    } @else {
                                        Drag and drop compliance requirements here.
                                    }
                                </span>
                            </div>
                        }
                    </div>
                </mat-expansion-panel>
            </div>
        </ng-container>
        <ng-template #noChecklists>
            <div class="drop-box" style="margin-bottom:2rem;">
                <div class="drop-box-overlay">
                    <span>There are no checklists to assign checklist items to. Please <button mat-button (click)="openUpsertModal()" color="primary">Create a Checklist</button> first.</span>
                </div>
            </div>
            
        </ng-template>

        <mat-expansion-panel expanded togglePosition="before">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    @if(isEditing){
                        <button mat-flat-button color="primary" (click)="selectAllChecklistItems($event, unassignedComplianceRequirements)">
                            Select all {{filterActive ? '(filtered)': ''}}
                        </button>
                    }
                    Unassigned Compliance Requirements (Viewing {{unassignedComplianceRequirements | checklistItemVisibleCount: filterFormGroup.value}} of {{unassignedComplianceRequirements.length}})
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="drop-box" cdkDropList (cdkDropListDropped)="drop($event)" [cdkDropListData]="unassignedComplianceRequirements">
                @if(unassignedComplianceRequirements.length > 0) { 
                    @for(unassignedComplianceRequirement of unassignedComplianceRequirements; track unassignedComplianceRequirement.ComplianceRequirementID) {
                        @if(unassignedComplianceRequirement | checklistItemVisible: filterFormGroup.value) {
                            
                            @if(isEditing) {
                                <checklist-item cdkDrag 
                                    class="is-editing"
                                    
                                    [cdkDragData]="unassignedComplianceRequirement"
                                    [checklistItemStatus]="unassignedComplianceRequirement.Status"
                                    [complianceRequirementType]="unassignedComplianceRequirement.ComplianceRequirement.ComplianceRequirementType?.Name" 
                                    [phase]="unassignedComplianceRequirement.ComplianceRequirement.Phase?.Name" 
                                    [scope]="unassignedComplianceRequirement.ComplianceRequirement.Scope?.Name" 
                                    [frequency]="unassignedComplianceRequirement.ComplianceRequirement.Frequency?.Name" 
                                    [complianceRequirementName]="unassignedComplianceRequirement.ComplianceRequirement.Name" 
                                    [resourceCategory]="unassignedComplianceRequirement.ComplianceRequirement.Commitment?.ResourceCategory?.Name" 
                                    [linkToChecklistItem]="false"
                                    [clientCommitmentID]="unassignedComplianceRequirement.ComplianceRequirement.Commitment.ClientCommitmentID">
                                    <div class="drop-placeholder" *cdkDragPlaceholder></div>
                                    <mat-checkbox (change)="changedChecklistItemSelection($event.checked, unassignedComplianceRequirement)" [checked]="unassignedComplianceRequirement | objectInArray: selectedChecklistItems"></mat-checkbox>
                                </checklist-item>
                            } @else {
                                <checklist-item  
                                    [checklistItemStatus]="unassignedComplianceRequirement.Status"
                                    [checklistItemID]="unassignedComplianceRequirement.ChecklistItemID"
                                    [complianceRequirementType]="unassignedComplianceRequirement.ComplianceRequirement.ComplianceRequirementType?.Name" 
                                    [phase]="unassignedComplianceRequirement.ComplianceRequirement.Phase?.Name" 
                                    [scope]="unassignedComplianceRequirement.ComplianceRequirement.Scope?.Name" 
                                    [frequency]="unassignedComplianceRequirement.ComplianceRequirement.Frequency?.Name" 
                                    [complianceRequirementName]="unassignedComplianceRequirement.ComplianceRequirement.Name" 
                                    [resourceCategory]="unassignedComplianceRequirement.ComplianceRequirement.Commitment?.ResourceCategory?.Name" 
                                    [linkToChecklistItem]="true"
                                    [clientCommitmentID]="unassignedComplianceRequirement.ComplianceRequirement.Commitment.ClientCommitmentID">
                                </checklist-item>
                            }
                        }
                    }
                }
                @else {
                    <div class="drop-box-overlay">
                        <span>No unassigned compliance requirements</span>
                    </div>
                }
            </div>
        </mat-expansion-panel>
        <div class="sticky-panel">
            <div class="sticky-panel-overlay">
                @if(isEditing){
                    <strong>Selected Items: {{selectedChecklistItems.length}}</strong>
                    <button mat-flat-button color="primary" (click)="selectAllChecklistItems($event)">
                        Select all {{filterActive ? '(filtered)': ''}}
                    </button>
                    <button mat-flat-button color="primary" [disabled]="selectedChecklistItems.length == 0" (click)="deselectAllChecklistItems()">
                        Clear Selection
                    </button>
                    <button mat-flat-button color="primary" [disabled]="selectedChecklistItems.length == 0"  (click)="openAssignModal()"><mat-icon>assignment</mat-icon> Assign Selected</button>
                }
                @if(!isEditing) {
                    <button mat-flat-button color="primary" (click)="enableEditing()"><mat-icon>edit</mat-icon>Enable Editing</button>
                } @else {
                    <button mat-flat-button color="primary" (click)="finishEditing()"><mat-icon>check</mat-icon>Finish Editing</button>
                }
            </div>
            
        </div>
    </section>
} @else {
    <loading-spinner />
}
