import { Component, EventEmitter, OnInit, ViewChild, Output, Input, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { NgForm, FormsModule } from "@angular/forms";
import { forkJoin } from "rxjs";
import { MenuItemService } from "src/app/shared/generated/api/menu-item.service";
import { RoleService } from "src/app/shared/generated/api/role.service";
import { RoleEnum } from "src/app/shared/models/enums/roles.enum";
import { CustomPageUpsertDto, MenuItemDto, RoleDto } from "src/app/shared/generated/model/models";
import { MatError } from "@angular/material/form-field";
import { NgIf } from "@angular/common";
import { EsaMaterialFormFieldComponent } from "esa-material-form-field";
import { MatIcon } from "@angular/material/icon";
import { MatButton } from "@angular/material/button";

@Component({
    selector: "custom-page-form",
    templateUrl: "./custom-page-form.component.html",
    styleUrls: ["./custom-page-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FormsModule,
        MatIcon,
        MatButton,
        EsaMaterialFormFieldComponent,
        NgIf,
        MatError,
    ],
})
export class CustomPageFormComponent implements OnInit {
    @ViewChild("customPageForm", { read: NgForm }) form: NgForm;

    @Output() formSubmitted = new EventEmitter<any>();
    @Output() cancelEditModeChange = new EventEmitter<boolean>();

    @Input() customPage: CustomPageUpsertDto;
    @Input() editMode: boolean;

  customPageUpsertDto: CustomPageUpsertDto;
    public menuItems: Array<MenuItemDto>;
    public roles: Array<RoleDto>;

    constructor(private menuItemService: MenuItemService, private roleService: RoleService, private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        forkJoin({
            menuItems: this.menuItemService.menuItemsGet(),
            roles: this.roleService.rolesGet(),
        }).subscribe(({ menuItems, roles }) => {
            // remove admin from role picker as admins default to viewable for all custom pages
            // and remove disabled users as well since they should not have viewable rights by default

            this.roles = roles.filter((role) => role.RoleID !== RoleEnum.Admin && role.RoleID !== RoleEnum.NoAccess);

            this.menuItems = menuItems;
            this.cdr.markForCheck();
        });

        if (this.customPage) {
            this.setForm();
        }
    }

    setForm() {
        this.customPageUpsertDto = new CustomPageUpsertDto({
            MenuItemID: this.customPage?.MenuItemID || [],
            CustomPageDisplayName: this.customPage?.CustomPageDisplayName,
            CustomPageVanityUrl: this.customPage?.CustomPageVanityUrl,
            ViewableRoleIDs: this.customPage?.ViewableRoleIDs || [],
            CustomPageContent: this.customPage?.CustomPageContent,
        });
    }

    saveForm(form: NgForm) {
        this.formSubmitted.emit(form);
    }

    cancelEditMode() {
        this.setForm();
        this.cancelEditModeChange.emit(true);
    }

    slugifyPageName(event: any): void {
        const urlSlug = event
            ?.toLowerCase()
            .replace(/ /g, "-")
            .replace(/[^\w-]+/g, "");
        this.customPageUpsertDto.CustomPageVanityUrl = urlSlug;
    }

    validPageName(pageName: string): boolean {
        const pattern = /^[_A-Za-z0-9\-\s]{1,100}$/;
        return pattern.test(pageName);
    }

    validVanityUrl(vanityUrl: string): boolean {
        const pattern = /^[_A-Za-z0-9\-]{1,100}$/;
        return pattern.test(vanityUrl);
    }
}
