<div class="detail-view" *ngIf="commitment">
    <div class="table-responsive" style="height: 700px">
        <beacon-grid-actions [grid]="commitmentRelationshipGrid" downloadFileName="commitment-relationships" [downloadColIDsToExclude]="['4']">
        <button mat-flat-button *ngIf="currentUser && canUpdateCommitment()" icon="add" color="primary" (click)="openCreateRelationshipDialog()">
            <mat-icon>add</mat-icon> Relationship
        </button>
        </beacon-grid-actions>
            <ag-grid-angular
                #commitmentRelationshipGrid
                style="width: 100%; height: 100%"
                class="ag-theme-material"
                [rowData]="rowData"
                [columnDefs]="columnDefs"
                
                [suppressMenuHide]="true"
                (gridReady)="onCommitmentRelationshipGridReady($event)"
                *ngIf="columnDefs">
            </ag-grid-angular>
        </div>
</div>
