<div class="main">
    <div class="page-header pb-3">
        <h1 class="page-title">New Commitment</h1>
        <div class="copy copy-2">
            <p>This page allows you to create a new Commitment. All fields are required to create the Commitment.</p>
        </div>
    </div>

    <commitment-form
        #createNewCommitmentForm
        [commitment]="commitment"
        [editMode]="editMode"
        (formSubmitted)="save($event)"
        [showFileUpload]="true"
        (cancelEditModeChange)="cancelEdit()"></commitment-form>
</div>
