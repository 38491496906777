import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "src/app/services/authentication.service";
import { CurrentCommitmentService } from "src/app/services/current-commitment/current-commitment.service";
import { CommitmentService } from "src/app/shared/generated/api/commitment.service";
import { CommitmentDto } from "src/app/shared/generated/model/commitment-dto";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { CommitmentVersionStatusEnum } from "src/app/shared/generated/enum/commitment-version-status-enum";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { FlagEnum } from "src/app/shared/generated/enum/flag-enum";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { CommitmentVersionWorkflowService } from "src/app/shared/generated/api/commitment-version-workflow.service";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { Observable, Subscription, forkJoin } from "rxjs";
import { EditViewEventService } from "src/app/services/edit-view-event.service";
import { MatDialog } from "@angular/material/dialog";
import { VersionDialogComponent } from "./version-dialog/version-dialog.component";
import { ComplianceRequirementService } from "src/app/shared/generated/api/compliance-requirement.service";
import { ISidebarNavItem, SidebarNavComponent } from "../../shared/components/sidebar-nav/sidebar-nav.component";
import { MatIcon } from "@angular/material/icon";
import { MatMenuTrigger, MatMenu, MatMenuItem } from "@angular/material/menu";
import { MatButton } from "@angular/material/button";
import { MatTooltip } from "@angular/material/tooltip";
import { NgIf, UpperCasePipe } from "@angular/common";
import { ConfirmService } from "src/app/services/confirm.service";

@Component({
    selector: "commitment-details",
    templateUrl: "./commitment-details.component.html",
    styleUrls: ["./commitment-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgIf,
        MatTooltip,
        MatButton,
        MatMenuTrigger,
        MatIcon,
        MatMenu,
        MatMenuItem,
        SidebarNavComponent,
        UpperCasePipe,
    ],
})
export class CommitmentDetailsComponent implements OnInit, OnDestroy {
    public complianceRequirements$: Observable<any>;
    public commitment$: Observable<any>;

    public commitment: CommitmentDto;
    public currentUser: UserDto;
    public editMode: boolean;
    public commitmentSideNav: ISidebarNavItem[] = [];

    user: Subscription;
    editViewButtonClicked: Subscription;

    constructor(
        private commitmentService: CommitmentService,
        private currentCommitmentService: CurrentCommitmentService,
        private authenticationService: AuthenticationService,
        private commitmentVersionWorkflowService: CommitmentVersionWorkflowService,
        private complianceRequirementService: ComplianceRequirementService,
        private alertService: AlertService,
        private editViewEventService: EditViewEventService,
        private dialog: MatDialog,
        private router: Router,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        private confirmService: ConfirmService
    ) {
        this.setSideNav();
    }

    public setSideNav(statistics: any = null) {
        this.commitmentSideNav = [
            {
                Name: "Summary Information",
                Link: "summary-information",
                ItemCount: null,
            },
            {
                Name: "Compliance Requirements",
                Link: "compliance-requirements",
                ItemCount: statistics?.ComplianceRequirementCount ?? 0,
            },
            {
                Name: "Compliance Triggers",
                Link: "compliance-triggers",
                ItemCount: statistics?.WorkActivityCount ?? 0,
            },
            {
                Name: "Related Commitments",
                Link: "commitment-relationships",
                ItemCount: statistics?.RelatedCommitmentCount ?? 0,
            },
            {
                Name: "Version Metadata",
                Link: "version-metadata",
                ItemCount: null,
            },
            {
                Name: "Revision History",
                Link: "revision-history",
                ItemCount: null,
            },
        ];
    }

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            let commitmentID = params.id;
            this.commitment = null;
            this.currentCommitmentService.setCurrentCommitment(null);

            let commitmentRequest = this.commitmentService.commitmentsCommitmentIDGet(commitmentID);
            let commitmentStatisticRequest = this.commitmentService.commitmentsCommitmentIDStatisticsGet(commitmentID);

            forkJoin([commitmentRequest, commitmentStatisticRequest]).subscribe((results) => {
                this.commitment = results[0];
                this.currentCommitmentService.setCurrentCommitment(this.commitment);
                this.setSideNav(results[1]);
                this.cdr.markForCheck();
            });
        });

        this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.cdr.markForCheck();
        });

        this.editViewButtonClicked = this.editViewEventService.listenForEditButtonClicked().subscribe((result) => {
            this.editMode = result;
            this.cdr.markForCheck();
        });

        this.currentCommitmentService.getCurrentCommitment().subscribe((result) => {
            this.commitment = result;
            if (this.commitment) {
                this.refreshStatistics();
            }
            this.cdr.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.user?.unsubscribe();
        this.editViewButtonClicked?.unsubscribe();
    }

    getData(id: any) {
        this.complianceRequirements$ = this.complianceRequirementService.commitmentsCommitmentIDComplianceRequirementsGet(id);
        this.cdr.markForCheck();
    }

    refreshStatistics() {
        this.commitmentService.commitmentsCommitmentIDStatisticsGet(this.commitment.CommitmentID).subscribe((result) => {
            this.setSideNav(result);
            this.cdr.markForCheck();
        });
    }

    refreshCommitment(result: any) {
        this.commitment = result;
        this.currentCommitmentService.setCurrentCommitment(this.commitment);
        if (this.commitment) {
            this.refreshStatistics();
        }
        this.cdr.detectChanges();
    }

    hasReadOnly() {
        const read = this.authenticationService.hasPermission(this?.currentUser, PermissionEnum.CommitmentRights, RightsEnum.Read);
        const update = this.authenticationService.hasPermission(this?.currentUser, PermissionEnum.CommitmentRights, RightsEnum.Update);
        const remove = this.authenticationService.hasPermission(this?.currentUser, PermissionEnum.CommitmentRights, RightsEnum.Delete);
        const create = this.authenticationService.hasPermission(this?.currentUser, PermissionEnum.CommitmentRights, RightsEnum.Create);
        if (read && !update && !remove && !create) {
            return true;
        } else {
            return false;
        }
    }

    canRevertToDraft() {
        return (
            this.commitment?.CommitmentVersionStatus?.CommitmentVersionStatusID == CommitmentVersionStatusEnum.Approved &&
            this.authenticationService.hasPermission(this?.currentUser, PermissionEnum.CommitmentRights, RightsEnum.Update)
        );
    }

    revertToDraft() {
        this.confirmService.confirm({ color:'warn', header: `Revert to draft`, text: `Are you sure you want to revert this commitment back to draft?` }).subscribe((result) => {
            if(!result) return;
            this.commitmentVersionWorkflowService.commitmentsCommitmentIDRevertToDraftPost(this.commitment.CommitmentID).subscribe((result) => {
                this.alertService.pushAlert(new Alert("The commitment was successfully reverted to draft.", AlertContext.Success), 5000);
                this.refreshCommitment(result);
            });
        });
        
    }

    canApprove() {
        return (
            this.commitment?.CommitmentVersionStatus?.CommitmentVersionStatusID == CommitmentVersionStatusEnum.Draft &&
            this.authenticationService.hasFlag(this?.currentUser, FlagEnum.CanFinalizeCommitments)
        );
    }

    approve() {
        this.confirmService.confirm({ header: `Approve commitment "${this.commitment.Title}"`, text: `Are you sure you want to approve this commitment? Only system administrators will be able to make corrections.` }).subscribe((result) => {
            if(!result) return;
            this.commitmentVersionWorkflowService.commitmentsCommitmentIDApprovePost(this.commitment.CommitmentID).subscribe((result) => {
                this.alertService.pushAlert(new Alert("The commitment was successfully approved.", AlertContext.Success), 5000);
                this.refreshCommitment(result);
            });
        });

        
    }


    canVersion() {
        let allowedStatuses = [CommitmentVersionStatusEnum.Approved];
        return (
            allowedStatuses.includes(this.commitment?.CommitmentVersionStatus?.CommitmentVersionStatusID) &&
            this.authenticationService.hasPermission(this?.currentUser, PermissionEnum.CommitmentRights, RightsEnum.Update) &&
            this.commitment?.IsLatestVersion
        );
    }

    version() {
        const dialogRef = this.dialog.open(VersionDialogComponent, {
            data: {
                CommitmentID: this.commitment.CommitmentID,
            },
            minWidth: "50vw",
            maxWidth: "50vw",
            disableClose: true,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result?.CommitmentID) {
                this.router.navigate(["commitments", result.CommitmentID, "summary-information"]);
                this.cdr.markForCheck();
            }
        });
    }

    canDelete() {
        let canDelete = this.currentCommitmentService.canDeleteCurrentCommitment(this.currentUser, this.commitment);
        return canDelete;
    }

    delete() {
        this.confirmService.confirm({ color:'warn', header: `Delete commitment "${this.commitment.Title}"`, text: `Are you sure you want to delete this commitment? This action cannot be undone.` }).subscribe((result) => {
            if(!result) return;
            this.commitmentService.commitmentsCommitmentIDDelete(this.commitment.CommitmentID).subscribe((result) => {
                this.alertService.pushAlert(new Alert("The commitment was successfully deleted.", AlertContext.Success), 5000);
                if (this.commitment.ParentCommitmentID != null) {
                    this.cdr.markForCheck();
                    this.router.navigate(["commitments", this.commitment.ParentCommitmentID, "summary-information"]);
                } else {
                    this.router.navigate(["/commitments"]);
                }
            });
        });
    }
}
