<h2 mat-dialog-title>
    @if(data.icon) {
        <mat-icon [color]="data.color">{{ data.icon }}</mat-icon>
    }
    {{ data.header }}
</h2>
<mat-dialog-content [innerHTML]="data.text">
</mat-dialog-content>
<mat-dialog-actions align="end">    
    <button
        mat-flat-button
        [mat-dialog-close]="true"
        [color]="data.color"
        type="button"
    ><mat-icon>done</mat-icon> Okay</button>
    <button
        mat-button
        mat-dialog-close
        type="button"
        color="primary"
        style="margin-right: 1rem"
    ><mat-icon>cancel</mat-icon> Cancel
    </button>
</mat-dialog-actions>