<div class="detail-view">
    <div class="actions-bar">
        <button mat-icon-button *ngIf="canEdit()" type="button" color="primary" (click)="enableEditMode()"><mat-icon>edit</mat-icon> </button>
    </div>

    <ng-container *ngIf="commitment$ | async as commitment">
        <commitment-form
            #updateCommitmentForm
            [commitment]="commitment"
            [editMode]="editMode"
            (formSubmitted)="save()"
            [showFileUpload]="true"
            (cancelEditModeChange)="cancelEdit()"></commitment-form>
    </ng-container>

    <beacon-back-to-top></beacon-back-to-top>
</div>
