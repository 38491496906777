import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectInArray',
  standalone: true
})
export class ObjectInArrayPipe implements PipeTransform {

  transform<T>(object: T, array: T[]): boolean {
    const objectInArray = array.includes(object);
    return objectInArray;
  }

}
