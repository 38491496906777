<div class="list-view" *ngIf="project$ | async">
    <div class="g-col-12">
        <div class="table-responsive" style="height: 800px">
            <beacon-grid-actions [grid]="commitmentsGrid" downloadFileName="project-related-commitments"></beacon-grid-actions>
            <ag-grid-angular
                #commitmentsGrid
                style="width: 100%; height: 100%"
                class="ag-theme-material"
                [rowData]="rowData"
                [columnDefs]="columnDefs"
                
                [suppressMenuHide]="true"
                (gridReady)="onCommitmentsGridReady($event)">
            </ag-grid-angular>
        </div>
    </div>
</div>
