import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { map, merge, Observable, shareReplay, Subject, switchMap, tap } from 'rxjs';
import { EvidenceOfComplianceUpsertComponent } from 'src/app/shared/components/evidence-of-compliance-upsert/evidence-of-compliance-upsert.component';
import { ChecklistItemStatusDisplayAndUpsertComponent } from 'src/app/shared/components/checklist-item-status-display-and-upsert/checklist-item-status-display-and-upsert.component';
import { ChecklistTaskGridComponent } from 'src/app/shared/components/checklist-task-grid/checklist-task-grid-component';

import { KvPairComponent } from 'src/app/shared/components/kv-pair/kv-pair.component';
import { PageBodyComponent } from 'src/app/shared/components/page-body/page-body.component';
import { PillComponent } from 'src/app/shared/components/pill/pill.component';
import { ChecklistService } from 'src/app/shared/generated/api/checklist.service';
import { ChecklistItemDto } from 'src/app/shared/generated/model/checklist-item-dto';
import { LoadingSpinnerComponent } from "../../shared/components/loading-spinner/loading-spinner.component";
import { ChecklistItemStatusComponent } from "../../shared/components/checklist-item-status/checklist-item-status.component";

@Component({
  selector: 'checklist-item-detail',
  standalone: true,
  imports: [
    CommonModule,
    PageBodyComponent,
    PillComponent,
    KvPairComponent,
    EvidenceOfComplianceUpsertComponent,
    ChecklistTaskGridComponent,
    ChecklistItemStatusDisplayAndUpsertComponent,
    LoadingSpinnerComponent,
    ChecklistItemStatusComponent,
    RouterModule
],
  templateUrl: './checklist-item-detail.component.html',
  styleUrl: './checklist-item-detail.component.scss'
})
export class ChecklistItemDetailComponent implements OnInit {
  checklistItem$: Observable<ChecklistItemDto>;
  checklistItemID: string;
  componentID: string;

  constructor(private checklistService: ChecklistService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {

    this.checklistItem$ = this.activatedRoute.paramMap.pipe(
      switchMap(paramMap => {
        this.componentID = paramMap.get('id');
        this.checklistItemID = paramMap.get('checklistItemID');

        return merge(
          this.checklistService.componentsComponentIDChecklistItemsChecklistItemIDGet(paramMap.get('id'), paramMap.get('checklistItemID')),
          this.statusUpdated$
        );
      }),
    );
  }

  private statusUpdatedSubject = new Subject<ChecklistItemDto>();
  private statusUpdated$ : Observable<ChecklistItemDto> = this.statusUpdatedSubject.asObservable();
  onStatusUpdated(event: ChecklistItemDto) {
    this.statusUpdatedSubject.next(event);
  }

}