import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgForm, FormsModule } from "@angular/forms";
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { AuthenticationService } from "src/app/services/authentication.service";
import { CurrentCommitmentService } from "src/app/services/current-commitment/current-commitment.service";
import { TinymceEditorComponent } from "src/app/shared/components/tinymce-editor/tinymce-editor.component";
import { CommitmentService } from "src/app/shared/generated/api/commitment.service";
import { UserService } from "src/app/shared/generated/api/user.service";
import { CommitmentDto } from "src/app/shared/generated/model/commitment-dto";
import { CommitmentUpsertDto } from "src/app/shared/generated/model/commitment-upsert-dto";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { BypassHtmlPipe } from "../../shared/pipes/bypassHtml.pipe";
import { BackToTopComponent } from "../../shared/components/back-to-top/back-to-top.component";
import { TinymceEditorComponent as TinymceEditorComponent_1 } from "../../shared/components/tinymce-editor/tinymce-editor.component";
import { CustomFormLabelComponent } from "../../shared/components/custom-form-label/custom-form-label.component";
import { EsaMaterialFormFieldComponent } from "esa-material-form-field";
import { NgIf, NgClass, AsyncPipe } from "@angular/common";
import { MatButton, MatIconButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { MatTooltip } from "@angular/material/tooltip";

@Component({
    selector: "commitment-version-metadata",
    templateUrl: "./commitment-version-metadata.component.html",
    styleUrls: ["./commitment-version-metadata.component.scss"],
    standalone: true,
    imports: [
        NgIf,
        FormsModule,
        EsaMaterialFormFieldComponent,
        CustomFormLabelComponent,
        NgClass,
        TinymceEditorComponent_1,
        BackToTopComponent,
        AsyncPipe,
        BypassHtmlPipe,
        MatButton,
        MatIcon,
        MatIconButton,
        MatTooltip
    ],
})
export class CommitmentVersionMetadataComponent implements OnInit, OnDestroy {
    @ViewChild("tinyMceEditor") tinyMceEditor: TinymceEditorComponent;
    @ViewChild("updateCommitmentForm", { read: NgForm }) form: NgForm;
    public commitment$: Observable<any>;
    public commitment: CommitmentDto;
    public currentUser: UserDto;

    public editMode: boolean = false;
    public commitmentUpsertDto: CommitmentUpsertDto;
    public orginalCommitment: CommitmentUpsertDto;

    public users$: Observable<any>;

    user: Subscription;

    constructor(
        private commitmentService: CommitmentService,
        private currentCommitmentService: CurrentCommitmentService,
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private alertService: AlertService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.cdr.markForCheck();
        });

        this.commitment$ = this.currentCommitmentService.getCurrentCommitment().pipe(
            map((data) => {
                this.commitment = data;
                if (this.commitment) {
                    this.setForm();
                }
                return data;
            })
        );

        this.users$ = this.userService.usersGet().pipe(
            map((results) => {
                const filteredUsers = results.filter((u) => {
                    return !u.IsClientUser;
                });
                return filteredUsers;
            })
        );
    }

    ngOnDestroy(): void {
        this.user?.unsubscribe();
    }

    setForm() {
        this.commitmentUpsertDto = this.currentCommitmentService.createCommitmentDto(this.commitment);
        this.cdr.markForCheck();
    }

    canEdit(): boolean {
        return this.currentCommitmentService.canEditCurrentCommitment(this.currentUser, this.commitment) && !this.editMode;
    }

    enableEditMode() {
        this.editMode = true;
        this.orginalCommitment = this.currentCommitmentService.createCommitmentDto(this.commitment);
        this.setForm();
    }

    cancelEditMode() {
        this.editMode = false;
        this.setForm();
    }

    save() {
        this.commitment$ = this.commitmentService.commitmentsCommitmentIDPut(this.commitment.CommitmentID, this.commitmentUpsertDto).pipe(
            map((result) => {
                this.alertService.pushAlert(new Alert("The commitment was successfully updated.", AlertContext.Success), 5000);
                this.refreshCommitment(result);
                this.editMode = false;
                return result;
            })
        );
    }

    refreshCommitment(result: any) {
        this.commitment = result;
        this.currentCommitmentService.setCurrentCommitment(this.commitment);
        this.cdr.markForCheck();
    }

    canExit() {
        if (this.editMode) {
            return JSON.stringify(this.orginalCommitment) === JSON.stringify(this.commitmentUpsertDto);
        } else {
            return true;
        }
    }
}
